import * as React from 'react';
import { __, __r } from 'react-i18n';
import { Link, withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import Button from '../_helpers/forms/Button/Button';
import ReactDOM from 'react-dom';
import {
  FlexRowCenterVertical,
  HeaderLogo as HeaderSmallLogo,
  TextP,
  FlexRow,
  FlexColCenter,
  FlexCol,
  IconImg,
  GolfcentrumSearchForm as SearchForm,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { Navigation } from './Navigation';
import { DesktopWrapper } from '../_helpers/Default/ResponsiveWrappers';
import { getCurrencyFromLang } from '../../utilities';
import { HeaderSwitch } from './HeaderSwtich';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  changeDimmerVisible: (newVal: boolean) => void;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  brands: any;
  user: any;
  cartData: ThenArg<typeof API.getCart>;
  onCurrencyChange: () => void;
}

/*****************/

const StyledDesktopWrapper = styled(DesktopWrapper)`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

const HeaderWrapper = styled(FlexCol)`
  background: ${({ theme }) => `${theme.color.backgroundDarker}`};
  border-bottom: ${({ theme }) => theme.borders.primary};

  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  align-items: center;

  @media print {
    display: none;
  }

  ${({ theme }) => theme.mediab.l925`
      margin: 0;
  `}
`;

const UpperHeader = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ theme }) => theme.borders.primary};
  width: 100%;
`;

const UpperHeaderWrapper = styled(FlexRow)`
  padding: ${rem(24)};
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};

  margin: 0 auto;
`;

const BottomHeader = styled(FlexRow)`
  padding: 0 ${rem(24)};
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  height: ${rem(72)};
`;

const ImportOrderButton = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(14)};
  padding: ${rem(19)};
  line-height: ${rem(22)};
  text-decoration: none;

  max-width: ${rem(237)};
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(62)};
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

const ImportOrderTitle = styled.span`
  margin-left: ${rem(16)};
  display: flex;
  align-items: flex-end;
`;

const MiddleWrapper = styled(FlexColCenter)`
  width: 100%;
  max-width: ${rem(560)};
  margin: 0 ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
    max-width: ${rem(460)};
  `}
`;

const RightWrapper = styled(FlexRowCenterVertical)``;

const NeedHelp = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(16)};
  justify-content: flex-start;
  width: 100%;
`;

const SmallTextP = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
`;

const SmallTextLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0;
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};

  &:hover {
    text-decoration: underline;
  }
`;

const DotDelimeter = styled.span`
  margin: 0 ${rem(12)};
  height: ${rem(2)};
  width: ${rem(2)};
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border-radius: 50%;
  display: inline-block;

  ${({ theme }) => theme.mediab.m580`
    margin: 0 ${rem(8)};
  `}
`;

function DesktopHeader({
  user,
  contact: { phone, email },
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  changeDimmerVisible,
  items,
  partialSearchProducts,
  partialSearchProductsIsFetching,
  partialSearchCategories,
  partialSearchCategoriesIsFetching,
  lang,
  cartData,
  dispatch,
  brands,
  onCurrencyChange,
}: Props) {
  let logoSrc = '/images/golfcentrum/logo-extended.svg';

  switch (lang) {
    case 'cz':
      logoSrc = '/images/golfcentrum/logo-extended-cz.svg';
      break;
    case 'en':
      logoSrc = '/images/golfcentrum/logo-extended-online.svg';
      break;
    default:
      logoSrc = '/images/golfcentrum/logo-extended.svg';
  }

  const { items: cartItems, total_price, currency } = cartData || {};

  return (
    <HeaderWrapper>
      <StyledDesktopWrapper>
        <UpperHeader>
          <UpperHeaderWrapper>
            <HeaderSmallLogo logo={logoSrc} />
            <MiddleWrapper>
              <SearchForm
                searchResults={{
                  productsResult: partialSearchProducts,
                  categoriesResult: partialSearchCategories,
                }}
                redirectToSearchResultPage={redirectToSearchResultsPage}
                searchTerm={searchTerm}
                handleSearchTermChange={handleSearchTermChange}
                handleFocus={handleSearchFocus}
                hideResultViewer={hideSearchResultsViewer}
                resultsDropdownVisible={resultsDropdownVisible}
                partialSearchProductsIsFetching={
                  partialSearchProductsIsFetching
                }
                partialSearchCategoriesIsFetching={
                  partialSearchCategoriesIsFetching
                }
              />
            </MiddleWrapper>
            <RightWrapper>
              {/* <HeaderSwitch
                onChange={onCurrencyChange}
                currentCurrency={getCurrencyFromLang(lang)}
              /> */}
              <HeaderProfile />
              <HeaderCart
                totalPrice={total_price}
                currency={currency || getCurrencyFromLang(lang)}
                cartData={cartData}
                dispatch={dispatch}
              />
            </RightWrapper>
          </UpperHeaderWrapper>
        </UpperHeader>
        <BottomHeader>
          <Navigation
            user={user}
            changeDimmerVisible={changeDimmerVisible}
            items={items}
            brands={brands}
          />
        </BottomHeader>
      </StyledDesktopWrapper>
    </HeaderWrapper>
  );
}

export default DesktopHeader;
