import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

export const H1Wrapper = styled.h1`
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(32)};
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  ${({ theme }) => theme.media(0, 'md')} {
    font-size: ${rem(24)};
  }
`;

export interface HeadingIO {
  className?: string;
}

export class H1 extends React.Component<HeadingIO> {
  public render() {
    const { children, className } = this.props;

    return <H1Wrapper className={className}>{children}</H1Wrapper>;
  }
}

export const H2Wrapper = styled.h2`
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(20)};
  line-height: 1.15;
  margin: ${rem(40)} 0 ${rem(30)};
  text-transform: uppercase;
`;

export class H2 extends React.Component<HeadingIO> {
  public render() {
    const { children, className } = this.props;

    return <H2Wrapper className={className}>{children}</H2Wrapper>;
  }
}

export const HomeTitle = styled(H2)`
  ${({ theme }) => theme.media(0, 'md')} {
    text-align: center;
  }
`;

export const TitleLabel = styled.span`
  display: inline-block;
  border-radius: ${rem(4)};
  font-size: ${rem(16)};
  padding: ${rem(6)} ${rem(10)};
  margin: ${rem(-2)} 0 ${rem(-5)} ${rem(10)};
  &.brand {
    background: ${({ theme }) => theme.color.brand};
    color: ${({ theme }) => theme.color.white};
  }
`;
