import { ErrorTypeAPI, getObjectProperty } from './../../utilities/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import APIClass, { ThenArg } from '../../services/API';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import { userSelector } from '../App/selectors';
import { AppThunk } from '../../rootReducer';

interface MyAccountState {
  userInfo: {
    isFetching: boolean;
    data: ThenArg<typeof APIClass.getCustomerDetails> | null;
    addresses: ThenArg<typeof APIClass.getBillingAddresses> | null;
    error?: ErrorTypeAPI;
    deliveryAddresses: {
      delivery_addresses: ThenArg<typeof APIClass.getDeliveryAddresses>;
      isFetching: boolean;
      error?: ErrorTypeAPI;
    };
    childUsers: {
      isFetching: boolean;
      child_users: ThenArg<typeof APIClass.loadChildUsers>['customers'] | null;
      error?: ErrorTypeAPI;
    };
    orders: {
      isFetching: boolean;
      orders_by_id: {};
      limit?: number | null;
      offset?: number | null;
      total?: number | null;
      error?: ErrorTypeAPI;
      orders_ids: string[];
    };
    finishedOrder: {
      isFetching: boolean;
      data: ThenArg<typeof APIClass.getOrder> | null;
      error?: ErrorTypeAPI;
    };
    requestDetail: {
      isFetching: boolean;
      data: ThenArg<typeof APIClass.getOrder> | null;
      error?: ErrorTypeAPI;
    };
    requests: {
      isFetching: boolean;
      requests_by_id: {};
      limit?: number | null;
      offset?: number | null;
      total?: number | null;
      error?: ErrorTypeAPI;
      requests_ids: string[];
    };
    orderReturn: {
      step: number;
      isFetching: boolean;
    };
  };
}

const initialState: MyAccountState = {
  userInfo: {
    isFetching: false,
    data: null,
    addresses: [],
    deliveryAddresses: {
      delivery_addresses: [],
      isFetching: false,
    },
    childUsers: {
      isFetching: false,
      child_users: null,
    },
    orders: {
      isFetching: false,
      orders_by_id: {},
      orders_ids: [],
    },
    finishedOrder: {
      isFetching: false,
      data: null,
    },
    requestDetail: {
      isFetching: false,
      data: null,
    },
    requests: {
      isFetching: false,
      requests_by_id: {},
      requests_ids: [],
    },
    orderReturn: {
      step: 1,
      isFetching: false,
    },
  },
};

function startLoading(state, path) {
  const currentState = getObjectProperty(state, path);
  currentState.isFetching = true;
}

function stopLoading(state, path) {
  const currentState = getObjectProperty(state, path);
  currentState.isFetching = false;
}

const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    startLoadingBillingAddresses(state) {
      startLoading(state, 'userInfo');
    },
    fetchBillingAddressesSuccess(
      state,
      action: PayloadAction<{
        addresses: ThenArg<typeof APIClass.getBillingAddresses>;
      }>,
    ) {
      state.userInfo.addresses = action.payload.addresses;
      stopLoading(state, 'userInfo');
    },
    fetchBillingAddressesError(state, action: PayloadAction<ErrorTypeAPI>) {
      state.userInfo.addresses = null;
      state.userInfo.error = action.payload;
      stopLoading(state, 'userInfo');
    },

    startLoadingCustomerInfo(state) {
      startLoading(state, 'userInfo');
    },
    fetchCustomerInfoSuccess(
      state,
      action: PayloadAction<{
        customer: ThenArg<typeof APIClass.getCustomerDetails>;
      }>,
    ) {
      state.userInfo.data = action.payload.customer;
      stopLoading(state, 'userInfo');
    },
    fetchCustomerInfoError(state, action: PayloadAction<ErrorTypeAPI>) {
      state.userInfo.data = null;
      state.userInfo.error = action.payload;
      stopLoading(state, 'userInfo');
    },

    startLoadingDeliveryAddresses(state) {
      startLoading(state, 'userInfo.deliveryAddresses');
    },
    fetchDeliveryAddressesSuccess(
      state,
      action: PayloadAction<{
        addresses: ThenArg<typeof APIClass.getDeliveryAddresses>;
      }>,
    ) {
      state.userInfo.deliveryAddresses.delivery_addresses =
        action.payload.addresses;
      stopLoading(state, 'userInfo.deliveryAddresses');
    },
    fetchDeliveryAddressesError(state, action: PayloadAction<ErrorTypeAPI>) {
      state.userInfo.deliveryAddresses.delivery_addresses = [];
      state.userInfo.deliveryAddresses.error = action.payload;
      stopLoading(state, 'userInfo.deliveryAddresses');
    },

    startLoadingChildUsers(state) {
      startLoading(state, 'userInfo.childUsers');
    },
    fetchChildUsersSuccess(
      state,
      action: PayloadAction<{
        customers: ThenArg<typeof APIClass.loadChildUsers>['customers'];
      }>,
    ) {
      state.userInfo.childUsers.child_users = action.payload.customers;
      stopLoading(state, 'userInfo.childUsers');
    },
    fetchChildUsersError(state, action: PayloadAction<ErrorTypeAPI>) {
      state.userInfo.childUsers.child_users = null;
      state.userInfo.childUsers.error = action.payload;
      stopLoading(state, 'userInfo.childUsers');
    },

    startLoadingOrders(state) {
      startLoading(state, 'userInfo.orders');
      const ordersState = state.userInfo.orders;
      ordersState.limit = null;
      ordersState.offset = null;
      ordersState.total = null;
    },
    fetchOrdersSuccess(state, action: PayloadAction<any>) {
      const { orders, limit, offset, total } = action.payload;
      const ordersState = state.userInfo.orders;

      ordersState.limit = limit;
      ordersState.offset = offset;
      ordersState.total = total;
      ordersState.error = null;
      ordersState.orders_by_id[orders[0].club_user_id] = orders;

      stopLoading(state, 'userInfo.orders');
    },
    fetchOrdersError(state, action: PayloadAction<ErrorTypeAPI>) {
      const ordersState = state.userInfo.orders;
      ordersState.orders_ids = initialState.userInfo.orders.orders_ids;
      ordersState.error = action.payload;
      stopLoading(state, 'userInfo.orders');
    },

    startLoadingFinishedOrder(state) {
      startLoading(state, 'userInfo.finishedOrder');
      const ordersState = state.userInfo.finishedOrder;
      ordersState.data = null;
    },
    fetchFinishedOrderSuccess(
      state,
      action: PayloadAction<{ order: ThenArg<typeof APIClass.getOrder> }>,
    ) {
      const { order } = action.payload;
      const ordersState = state.userInfo.finishedOrder;

      ordersState.error = null;
      ordersState.data = order;

      stopLoading(state, 'userInfo.finishedOrder');
    },
    fetchFinishedOrdersError(state, action: PayloadAction<ErrorTypeAPI>) {
      const ordersState = state.userInfo.finishedOrder;
      ordersState.error = action.payload;
      stopLoading(state, 'userInfo.finishedOrder');
    },

    startLoadingRequestDetail(state) {
      startLoading(state, 'userInfo.requestDetail');
      const ordersState = state.userInfo.finishedOrder;
      ordersState.data = null;
    },
    fetchRequestDetailSuccess(
      state,
      action: PayloadAction<{ order: ThenArg<typeof APIClass.getOrder> }>,
    ) {
      const { order } = action.payload;
      const ordersState = state.userInfo.requestDetail;

      ordersState.error = null;
      ordersState.data = order;

      stopLoading(state, 'userInfo.requestDetail');
    },
    fetchRequestDetailError(state, action: PayloadAction<ErrorTypeAPI>) {
      const ordersState = state.userInfo.requestDetail;
      ordersState.error = action.payload;
      stopLoading(state, 'userInfo.requestDetail');
    },

    startLoadingRequests(state) {
      startLoading(state, 'userInfo.requests');
      const requestsState = state.userInfo.requests;
      requestsState.limit = null;
      requestsState.offset = null;
      requestsState.total = null;
    },
    fetchRequestsSuccess(state, action: PayloadAction<any>) {
      const { orders, limit, offset, total } = action.payload;
      const requestsState = state.userInfo.requests;

      requestsState.limit = limit;
      requestsState.offset = offset;
      requestsState.total = total;
      requestsState.error = null;
      requestsState.requests_by_id[orders[0].club_user_id] = orders;

      stopLoading(state, 'userInfo.requests');
    },
    fetchRequestsError(state, action: PayloadAction<ErrorTypeAPI>) {
      const requestsState = state.userInfo.requests;
      requestsState.requests_ids = initialState.userInfo.requests.requests_ids;
      requestsState.error = action.payload;
      stopLoading(state, 'userInfo.requests');
    },
    setOrderReturnStep(state, action: PayloadAction<number>) {
      state.userInfo.orderReturn.step = action.payload;
    },
    setOrderReturnIsFetching(state, action: PayloadAction<boolean>) {
      state.userInfo.orderReturn.isFetching = action.payload;
    },
  },
});

export const {
  startLoadingBillingAddresses,
  fetchBillingAddressesSuccess,
  fetchBillingAddressesError,
  startLoadingCustomerInfo,
  fetchCustomerInfoSuccess,
  fetchCustomerInfoError,
  startLoadingDeliveryAddresses,
  fetchDeliveryAddressesSuccess,
  fetchDeliveryAddressesError,
  startLoadingChildUsers,
  fetchChildUsersSuccess,
  fetchChildUsersError,
  startLoadingOrders,
  fetchOrdersSuccess,
  fetchOrdersError,
  startLoadingFinishedOrder,
  fetchFinishedOrderSuccess,
  fetchFinishedOrdersError,
  startLoadingRequestDetail,
  fetchRequestDetailSuccess,
  fetchRequestDetailError,
  startLoadingRequests,
  fetchRequestsSuccess,
  fetchRequestsError,
  setOrderReturnIsFetching,
  setOrderReturnStep,
} = myAccountSlice.actions;

export default myAccountSlice.reducer;

const myAccountDomainSelector = state => state.myAccount;

export const customerInfoSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.data', null),
);

export const deliveryAddressesSelector = createSelector(
  myAccountDomainSelector,
  substate =>
    prop(substate, 'userInfo.deliveryAddresses.delivery_addresses', []),
);

export const deliveryAddressesIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.deliveryAddresses.isFetching', false),
);

export const childUsersSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.childUsers.child_users', []),
);

export const childUsersIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.childUsers.isFetching', false),
);

export const ordersDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orders', {}),
);

export const ordersByIdSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orders.orders_by_id', {}),
);

export const ordersIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orders.isFetching', false),
);

export const requestsDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requests', {}),
);

export const requestsByIdSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requests.requests_by_id', {}),
);

export const requestsIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requests.isFetching', false),
);

export const finishedOrderDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.finishedOrder.data', {}),
);

export const finishedOrderIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.finishedOrder.isFetching', false),
);

export const requestDetailDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requestDetail.data', {}),
);

export const requestDetailIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requestDetail.isFetching', false),
);
export const requestStep = createSelector(myAccountDomainSelector, substate =>
  prop(substate, 'userInfo.orderReturn.step'),
);
export const requestOrderReturnIsFetching = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orderReturn.isFetching'),
);

export const setStep = (step): any => async dispatch => {
  dispatch(setOrderReturnStep(step));
};

export const fetchBillingAddresses = (): any => async (
  dispatch,
  getState,
  API,
) => {
  if (!getState().myAccount.data) {
    try {
      dispatch(startLoadingBillingAddresses());
      const userId = userSelector(getState()).id;
      const billingAddresses = await API.getBillingAddresses(userId, {});
      dispatch(fetchBillingAddressesSuccess({ addresses: [] }));
    } catch (err) {
      dispatch(fetchBillingAddressesError(err));
    }
  }
};

export const fetchCustomerDetails = (): any => async (
  dispatch,
  getState,
  API,
) => {
  if (!getState().myAccount.data) {
    try {
      dispatch(startLoadingCustomerInfo());
      const userId = userSelector(getState()).id;
      const customerInfo = await API.getCustomerDetails(userId, {});
      dispatch(fetchCustomerInfoSuccess({ customer: customerInfo }));
    } catch (err) {
      dispatch(fetchCustomerInfoError(err));
    }
  }
};

export const fetchDeliveryAddresses = (
  forceRefresh: boolean = false,
): any => async (dispatch, getState, API) => {
  if (
    forceRefresh ||
    !getState().myAccount.userInfo.deliveryAddresses.delivery_addresses ||
    getState().myAccount.userInfo.deliveryAddresses.delivery_addresses
      .length === 0
  ) {
    try {
      dispatch(startLoadingDeliveryAddresses());
      const userId = userSelector(getState()).id;
      const deliveryAddresses = await API.getDeliveryAddresses(userId, {});
      dispatch(fetchDeliveryAddressesSuccess({ addresses: deliveryAddresses }));
    } catch (err) {
      dispatch(fetchDeliveryAddressesError(err));
    }
  }
};

export const fetchChildUsers = (forceRefresh: boolean = false): any => async (
  dispatch,
  getState,
  API,
) => {
  if (
    forceRefresh ||
    !getState().myAccount.userInfo.childUsers.child_users ||
    getState().myAccount.userInfo.childUsers.child_users.length === 0
  ) {
    try {
      dispatch(startLoadingChildUsers());
      const userId = userSelector(getState()).id;
      const childUsers = await API.loadChildUsers(userId, {});
      dispatch(fetchChildUsersSuccess({ customers: childUsers.customers }));
    } catch (err) {
      dispatch(fetchChildUsersError(err));
    }
  }
};

export const fetchOrders = (
  userId: number | string | null = null,
  limit: number = 5,
  offset: number = 0,
): any => async (dispatch, getState, API) => {
  if (
    !getState().myAccount.userInfo.orders.orders_ids ||
    getState().myAccount.userInfo.orders.orders_ids.length === 0
  ) {
    try {
      dispatch(startLoadingOrders());
      const currentUserId = userId ? userId : userSelector(getState()).id;
      const orders = await API.getOrders(currentUserId, { limit, offset });
      dispatch(fetchOrdersSuccess(orders));
    } catch (err) {
      dispatch(fetchOrdersError(err));
    }
  }
};

export const fetchFinishedOrder = (orderId: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(startLoadingFinishedOrder());
    const order = await API.getOrder(orderId);
    dispatch(fetchFinishedOrderSuccess({ order }));
  } catch (err) {
    dispatch(fetchFinishedOrdersError(err));
  }
};

export const fetchRequests = (
  userId: number | string | null = null,
  limit: number = 5,
  offset: number = 0,
): any => async (dispatch, getState, API) => {
  if (
    !getState().myAccount.userInfo.requests.requests_ids ||
    getState().myAccount.userInfo.requests.requests_ids.length === 0
  ) {
    try {
      dispatch(startLoadingRequests());
      const currentUserId = userId ? userId : userSelector(getState()).id;
      const requests = await API.getOrders(currentUserId, {
        limit,
        offset,
        filterType: 'DEMAND',
      });
      dispatch(fetchRequestsSuccess(requests));
    } catch (err) {
      dispatch(fetchRequestsError(err));
    }
  }
};

export const fetchRequestDetail = (orderId: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(startLoadingRequestDetail());
    const order = await API.getOrder(orderId);
    dispatch(fetchRequestDetailSuccess({ order }));
  } catch (err) {
    dispatch(fetchRequestDetailError(err));
  }
};

export const setOrderToReturn = (
  order_id,
  good = {
    good_id: undefined,
    quantity: undefined,
    isReturn: undefined,
    is_sale: false,
  },
  contactInfo = null,
  step = 1,
  delivery?,
): AppThunk => async (dispatch, getState, API) => {
  try {
    if (step === 3) {
      dispatch(setOrderReturnIsFetching(true));
    }
    const address = {
      person: {
        name: prop(contactInfo, 'name', ''),
        surname: prop(contactInfo, 'surname', ''),
        phone: prop(contactInfo, 'phone', ''),
        email: prop(contactInfo, 'email', ''),
      },
      street: prop(contactInfo, 'street', ''),
      street_number: prop(contactInfo, 'street_number', ''),
      zip: prop(contactInfo, 'zip', ''),
      city: prop(contactInfo, 'city', ''),
      country: prop(contactInfo, 'country', ''),
      iban: prop(contactInfo, 'iban', ''),
      note: prop(contactInfo, 'note', ''),
      country_code: 'sk',
      country_id: 'sk',
    };

    const order = await API.returnOrder(
      order_id,
      {
        goodId: good.good_id,
        quantity: good.quantity,
        isReturn: good.isReturn,
        isSale: good.is_sale,
        step,
        delivery: delivery ? delivery : undefined,
      },
      { ...address },
    );
    dispatch(setOrderReturnIsFetching(false));
    dispatch(fetchFinishedOrderSuccess({ order }));
  } catch (err) {
    dispatch(fetchRequestDetailError(err));
  }
};
