import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from '../../theme/styled-components';
import { withTheme } from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  MetaTags,
  GolfcentrumTitle as Title,
  ContactPersonBlock,
  BlockWithTitle,
  ContactInfoBlock,
  InvoiceAddressBlock,
  CompanyInfo,
  ZemplinButton as Button,
  InfoText,
  AddressesDeliveryList,
  AddressChangeForm,
  ZemplinLoaderWrapper,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { useInput } from '../../utilities/hooks';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  IconType,
  SvgIcon,
} from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';

interface Props {
  userId: number;
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  refreshAddresses: () => void;
  isFetching: boolean;
  theme: any;
  lang: string;
}

function reducer(state, { field, value }) {
  return { ...state, [field]: value };
}

function MyDeliveryAddresses({
  userId,
  deliveryAddresses,
  refreshAddresses,
  isFetching,
  theme,
  lang,
}: Props) {
  const initialState = {
    country: 'Slovensko',
    country_id: 'sk',
    streetNumber: '',
    city: '',
    zip: '',
    street: '',
    name: '',
    surname: '',
    delivery_phone: '',
    isDefault: false,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState('');
  const [error, setError] = React.useState(null);
  const [currentId, setCurrentId] = React.useState(null);

  const onChange = e => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const { country_id, streetNumber, street, city, zip, isDefault } = state;

  const handleCurrentAddressChange = id => {
    setCurrentId(id);

    const currentAddress = deliveryAddresses.find(a => a.id === id);
    if (currentAddress) {
      const {
        delivery_street,
        delivery_number,
        delivery_zip,
        delivery_city,
        country,
        isDefault,
      } = currentAddress;
      dispatch({ field: 'country_id', value: country });
      dispatch({ field: 'streetNumber', value: delivery_number });
      dispatch({ field: 'city', value: delivery_city });
      dispatch({ field: 'zip', value: delivery_zip });
      dispatch({ field: 'street', value: delivery_street });
      dispatch({ field: 'isDefault', value: isDefault });
    } else {
      dispatch({ field: 'country_id', value: 'sk' });
      dispatch({ field: 'streetNumber', value: '' });
      dispatch({ field: 'city', value: '' });
      dispatch({ field: 'zip', value: '' });
      dispatch({ field: 'street', value: '' });
      dispatch({ field: 'isDefault', value: false });
    }
  };

  const deleteAddress = async () => {
    setIsSaving(true);
    try {
      if (currentId) {
        await API.deleteDeliveryAddress(userId, currentId || -1, {});
      }
      refreshAddresses();
    } catch (e) {
      setError(e);
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    setCurrentId(null);
    handleCurrentAddressChange(null);
    setDataUpdated(__('Adresa bola úspešne zmazaná'));
  };

  const updateDetails = async () => {
    setIsSaving(true);
    try {
      if (currentId) {
        await API.updateDeliveryAddress(
          userId,
          currentId || -1,
          {},
          {
            ...state,
            delivery_street: street,
            delivery_number: streetNumber,
            delivery_zip: zip,
            delivery_city: city,
            country: country_id,
            isDefault,
          },
        );
        handleCurrentAddressChange(null);
      } else {
        await API.createNewDeliveryAddress(
          userId,
          {},
          {
            ...state,
            delivery_street: street,
            delivery_number: streetNumber,
            delivery_zip: zip,
            delivery_city: city,
            country: country_id === 'sk' ? 'Slovensko' : country_id,
            country_id: country_id,
            isDefault,
          },
        );
        setCurrentId(null);
        handleCurrentAddressChange(null);
      }
      refreshAddresses();
    } catch (e) {
      setError(e);
      setDataUpdated('');
      setIsSaving(false);
      return;
    }
    setError(null);
    setIsSaving(false);
    setDataUpdated(__('Údaje boli úspešne zmenené'));
  };

  return (
    <Wrapper>
      <Title marginTop={0}>{__('Moje dodacie adresy')}</Title>
      {isFetching ? (
        <ZemplinLoaderWrapper height={500} />
      ) : (
        <>
          <DimWrapper dimmed={isSaving}>
            <AddressesDeliveryList
              onAddressClick={handleCurrentAddressChange}
              addresses={deliveryAddresses}
              currentId={currentId}
            />

            <AddressChangeForm
              onChange={onChange}
              street={street}
              streetNumber={streetNumber}
              city={city}
              zip={zip}
              isPreffered={isDefault}
              country_id={country_id}
              isNew={currentId === null}
              lang={lang}
            />
          </DimWrapper>
          {!error && dataUpdated && (
            <InfoText type={InfoType.SUCCESS} info={dataUpdated} />
          )}
          {error && (
            <InfoText
              type={InfoType.ERROR}
              info={__('Pri ukladaní sa vyskytla chyba')}
            />
          )}
          <ButtonWrapper>
            <SaveButton onClick={updateDetails}>
              {isSaving ? __('Ukladám údaje ...') : __('Uložiť')}
            </SaveButton>
            {currentId && (
              <DeleteButton onClick={deleteAddress}>
                <SvgIcon
                  width={16}
                  height={16}
                  marginRight={16}
                  icon={IconType.trash}
                  viewBox="0 0 16 16"
                  alt="Odstrániť adresu"
                  fill={theme.colors.textPrimary}
                  cursor={'pointer'}
                />
                {isSaving ? __('Odstráňujem...') : __('Odstrániť adresu')}
              </DeleteButton>
            )}
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const DimWrapper = styled(FlexCol)<{ dimmed: boolean }>`
  opacity: ${({ dimmed }) => (dimmed ? 0.4 : 1)};
`;

const ButtonWrapper = styled(FlexRowCenterVertical)`
  flex-flow: row wrap;
`;

const SaveButton = styled(Button)`
  height: ${rem(48)};
  padding: ${rem(8)} ${rem(15)};
  width: 100%;
  max-width: ${rem(100)};
  background-color: ${({ theme }) => theme.color.green};
  color: ${({ theme }) => theme.color.white};
  font-size: ${rem(14)};
  margin: 0 0 ${rem(24)};
  font-family: ${({ theme }) => theme.font.primary};

  &:disabled {
    background-color: #dcd6d6;
  }

  ${({ theme }) => theme.mediab.m580`
    max-width: initial;
  `}
`;

const DeleteButton = styled(SaveButton)`
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-left: ${rem(24)};
  border: 1px solid #ccc;
  max-width: ${rem(220)};
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: ${({ theme }) => theme.colors.textPrimary};

  ${({ theme }) => theme.mediab.m580`
     margin-left: 0;
  `}
`;

export default withTheme(MyDeliveryAddresses);
