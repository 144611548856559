import * as actionTypes from './constants';

const initialState = {
  productDetail: {},
  isFetching: false,
  connections: {
    alternatives: {
      isFetching: false,
    },
    accessories: {
      isFetching: false,
    },
    colors: {
      isFetching: false,
    },
  },
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_PRODUCT_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        productDetail: action.payload.productDetail,
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    case actionTypes.REQUEST_PRODUCT_RELATED_DETAIL: {
      return {
        ...state,
        connections: {
          ...state.connections,
          alternatives: {
            isFetching: true,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          alternatives: {
            isFetching: false,
            products: action.payload.connections,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_RELATED_ERROR:
      return {
        ...state,
        connections: {
          ...state.connections,
          alternatives: {
            isFetching: false,
            error: action.payload.error,
          },
        },
      };

    case actionTypes.REQUEST_PRODUCT_ACCESSORY_DETAIL: {
      return {
        ...state,
        connections: {
          ...state.connections,
          accessories: {
            isFetching: true,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_ACCESSORY_DETAIL_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          accessories: {
            isFetching: false,
            products: action.payload.connections,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_ACCESSORY_ERROR:
      return {
        ...state,
        connections: {
          ...state.connections,
          accessories: {
            isFetching: false,
            error: action.payload.error,
          },
        },
      };

    case actionTypes.REQUEST_PRODUCT_DETAIL_COLORS_VARIANTS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          isFetching: {
            isFetching: true,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_COLORS_VARIANTS_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          colors: {
            isFetching: false,
            products: action.payload.connections,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_COLORS_VARIANTS_ERROR:
      return {
        ...state,
        connections: {
          ...state.connections,
          colors: {
            isFetching: false,
            error: action.payload.error,
          },
        },
      };

    default:
      return state;
  }
};
