import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinTitle as Title,
  InfoText,
  Invoices,
  ZemplinSelect as Select,
  Orders,
  PaginationSimple,
  Requests,
  ZemplinCheckbox as CheckBox,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';

interface Props {
  requests: ThenArg<typeof API.getOrders>;
  requestsData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetching: boolean;
}

function MyRequests({
  currentId,
  requests,
  requestsData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetching,
}: Props) {
  const { limit, offset, total } = requestsData;

  const handleCheckboxChange = e => {
    console.log({ v: e.target.value });
  };

  return (
    <Wrapper>
      <Title marginTop={0} marginBottom={32}>
        {__('Moje dopyty')}
      </Title>
      {options && options.length > 1 && (
        <FilterWrapper>
          <Select
            value={currentId}
            options={options}
            onChange={handleSelectChange}
          />
          <CheckBox
            label="Spracovaný"
            // selected={true}
            onChange={handleCheckboxChange}
          />
          <CheckBox
            label="Spracováva sa"
            // selected={false}
            onChange={handleCheckboxChange}
          />
          <CheckBox
            label="Čaká na spracovanie"
            // selected={true}
            onChange={handleCheckboxChange}
          />
        </FilterWrapper>
      )}
      <Requests isFetching={isFetching} requests={requests} />
      <PaginationSimple
        totalItems={total}
        limit={limit}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const FilterWrapper = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    align-items: flex-start;
  `}
`;

export default MyRequests;
