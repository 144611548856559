import {
  ErrorFinishedOrder,
  FinishedOrder,
  FlexCol,
  InfoText,
  MetaTags,
} from 'eshop-defaults';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import * as React from 'react';
import { __, __r } from 'react-i18n';
import LazyLoad from 'react-lazyload';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import API, { ThenArg } from '../../services/API';
import styled from '../../theme/styled-components';
import { getPaymentUrl, isError, shouldRedirect } from '../../utilities/order';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
} from '../MyAccount/myAccountSlice';
import { LoaderWrapper } from '../../components/_helpers/Loader/Loader';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { round } from 'eshop-defaults/lib/utilities';
import { prop } from '../../utilities';
import { withRouter, WithRouterProps } from 'react-router';
import {
  fbPurchase,
  ga4Purchase,
  heurekaPurchase,
} from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
}
interface State {
  hasRedirected: boolean;
}

class FinishedOrderContainer extends React.Component<
  Props & WithRouterProps,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      hasRedirected: false,
    };
  }

  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { orderPublicId },
    } = props;

    dispatch(
      setBreadCrumbPath(BreadCrumbType.CART, {
        url: `${__r(
          'routes:dokoncena-objednavka',
          '/dokoncena-objednavka',
        )}/${orderPublicId}`,
      }),
    );
    try {
      await dispatch(fetchFinishedOrder(orderPublicId));
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public async componentDidMount() {
    await this.updateOrderAnalytics();
  }

  public async componentDidUpdate(prevProps): Promise<void> {
    if (
      prop(prevProps, 'orderData.order_id') !==
      prop(this.props, 'orderData.order_id')
    ) {
      await this.updateOrderAnalytics();
    }
  }

  public updateOrderAnalytics = async (): Promise<void> => {
    const { orderData: order }: { orderData: any } = this.props;
    if (order && !isSSR() && order.analytics_sended === 0 && window) {
      const total = order.order_tprice;
      const totalWithoutVat = order.order_tprice_without_vat;
      const vatSum = total - totalWithoutVat;
      const shipping = order.payment.delivery_price;

      const items: any[] = [];
      order.items.forEach(item => {
        const itemId = item.product_id;
        const itemName = item.product_name;

        const itemPrice = item.unitprice;
        const itemPriceWithoutVat = round(
          itemPrice / (1 + item.vat_rate / 100),
          2,
        );

        const itemQuantity = item.quantity;
        if (itemId && itemName && itemPriceWithoutVat && itemQuantity) {
          items.push({
            name: itemName,
            sku: item.ordernr,
            price: itemPriceWithoutVat,
            quantity: itemQuantity,
          });
        }
      });

      window.dataLayer.push({
        event: 'trackTrans',
        transactionId: order.order_nr,
        transactionAffiliation: 'Golfcentrum.sk eshop',
        transactionTotal: round(total, 2),
        transactionTax: round(vatSum, 2),
        transactionShipping: shipping,
        transactionProducts: items,
      });

      fbPurchase(order);
      //heurekaPurchase(order);
      if (window.gtag) {
        /// @ts-ignore
        let gData = order.items
          .map(p => {
            return {
              ...p,
              unitprice: prop(
                p,
                'good_details_json.unitprice.price',
                p.unitprice,
              ),
            };
          })
          .filter(
            d =>
              (!d.isFromSale || d.product_id) &&
              d.product_name !== 'Doprava a platba',
          );
        const tax = order.order_tprice - order.order_tprice_without_vat;
        const currency = prop(order, 'order_tpricecurrency', 'EUR');
        ga4Purchase(
          gData,
          order.order_nr,
          shipping,
          round(total, 2),
          round(vatSum, 2),
          getGoogleAdsId(),
          currency,
        );
      }

      if (window._hrq) {
        heurekaPurchase(order);
      }

      try {
        await API.updateOrderAnalytics(order.public_id);
      } catch (e) {
        console.error('Update order analytics failed!');
      }
    }
  };

  public render() {
    const { orderData, isFetching, location } = this.props;
    const { hasRedirected } = this.state;

    if (isFetching) {
      return <LoaderWrapper />;
    }
    if (!orderData) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Takáto objednávka neexistuje')}
        />
      );
    } else if (shouldRedirect(orderData)) {
      const redirectUrl = getPaymentUrl(
        orderData.payment.payment_type,
        orderData.public_id,
      );
      if (
        redirectUrl &&
        window &&
        window.location &&
        window.location.href &&
        !hasRedirected
      ) {
        window.location.href = redirectUrl;
        this.setState({ hasRedirected: true });
      }
      return null;
    } else if (isError()) {
      return (
        <>
          <Wrapper className="container container--wide">
            <MetaTags tags={{ title: __('Chyba') }} />
            <ErrorFinishedOrder
              order={orderData}
              isFetching={isFetching}
              redirectUrl={getPaymentUrl(
                orderData.payment.payment_type,
                orderData.public_id,
              )}
              error={prop(location, 'query.error')}
            />
          </Wrapper>
        </>
      );
    }

    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags
            tags={{ title: __('Ďakujeme za váš nákup! | GOLF CENTRUM.sk') }}
          />
          <FinishedOrder
            order={orderData}
            isFetching={isFetching}
            location={location}
          />
        </Wrapper>
      </>
    );
  }
}

const Wrapper = styled(FlexCol)`
  margin: 0 auto;
  width: 100%;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    orderData: finishedOrderDataSelector(state),
    isFetching: finishedOrderIsFetchingSelector(state),
  };
};

const WithRouterFinished = withRouter(FinishedOrderContainer);
export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FinishedOrderContainer' })(WithRouterFinished),
);
