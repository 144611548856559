import { prop } from '.';

enum THEMES {
  DEFAULT = '',
  HEAT = 'heatCategory',
  WOOD = 'woodCategory',
  REST = 'restCategory',
}

enum CategoryNames {
  SPOJ_MATERIAL = 'SPOJ_MATERIAL',
  CENTRUM_VYKUROVANIA = 'CENTRUM_VYKUROV',
  PRE_DREVAROV = 'PRE_DREVAROV',
  OSTATNE = 'OSTATNE',
}

export const resolveCurrentThemeFromCategory = (categoryIds: string[]) => {
  if (categoryIds.includes(CategoryNames.CENTRUM_VYKUROVANIA)) {
    return THEMES.HEAT;
  }

  if (categoryIds.includes(CategoryNames.PRE_DREVAROV)) {
    return THEMES.WOOD;
  }

  if (categoryIds.includes(CategoryNames.OSTATNE)) {
    return THEMES.REST;
  }

  return THEMES.DEFAULT;
};

export function getAttribNameFromAttribObj(attrib: any, lang?: string) {
  if (lang && attrib && attrib.translations) {
    const langTranslation = attrib.translations.find(t => t.lang_id === lang);
    if (prop(langTranslation, 'attrib_name')) {
      return prop(langTranslation, 'attrib_name');
    }
  }
  return prop(attrib, 'attrib_name');
}

export function getAttribValueFromAttribObj(value: any, lang?: string) {
  if (lang && value && value.translations) {
    const langTranslation = value.translations.find(t => t.lang_id === lang);
    if (prop(langTranslation, 'attrib_value')) {
      return prop(langTranslation, 'attrib_value');
    }
  }
  return prop(value, 'attrib_value');
}

export function resolveCategoryName(data: any) {
  return (
    prop(data, 'publish.0.category_name') || prop(data, 'category_name', '')
  );
}

export function resolveCategoryUrlString(data: any) {
  const lang = prop(data, 'publish.0.lang_id', 'sk');
  if (lang === 'sk') {
    return (
      prop(data, 'weburl') ||
      prop(data, 'url') ||
      prop(data, 'publish.0.redirect_url') ||
      prop(data, 'publish.0.url')
    );
  } else {
    return (
      prop(data, 'publish.0.redirect_url') ||
      prop(data, 'publish.0.url') ||
      prop(data, 'weburl') ||
      prop(data, 'url')
    );
  }
}
