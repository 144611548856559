import { cookiesExpiresDate } from './../../utilities/index';
import * as cookie from 'react-cookies';

const CART_COOKIE_ID = 'cart_id';

export function saveCartCookie(cartId: string) {
  cookie.save(CART_COOKIE_ID, cartId, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
}

export function loadCartCookie(): string | undefined {
  return cookie.load(CART_COOKIE_ID);
}

export function removeCartCookie(): void {
  cookie.remove(CART_COOKIE_ID, { path: '/' });
}
