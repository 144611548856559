import {
  GolftCentrumWasAddedToCartModal as WasAddedToCartModal,
  FlexCol,
  FlexRow,
  ZemplinLoaderWrapper,
  TitleH2,
  FreeDeliveryInfo,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import {
  wasAddedToCartModalSelector,
  addItemToCartFromModal,
  wasAddedToCartModalProductSelector,
  setWasAddedToCartModalVisibility,
  wasAddedToCartModalCountSelector,
  loadProductInCartRecommended,
  wasAddedToCartModalIsFetchingSelector,
  wasAddedToCartModalBoughtTooSelector,
  setAddToCartModalVisibility,
  freeDeliveryRemainingAmountSelector,
} from '../Cart/cartSlice';
import SliderComponent from '../../components/_helpers/Slider/Slider';
import { __ } from 'react-i18n';
import { cartIndexRoute } from '../../utilities/cart';
import { WithRouterProps, withRouter } from 'react-router';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';

interface Props {
  wasAddedToCartModal: boolean;
  wasAddedToCartModalProduct: any;
  wasAddedToCartModalCount: number;
  wasAddedToCartModalIsFetching: boolean;
  wasAddedToCartModalBoughtToo: ThenArg<typeof API.loadProductConnections>;
  dispatch: any;
  user: any;
  freeDeliveryRemainingAmount: number | null;
  data;
}

function AddedToCartModal({
  wasAddedToCartModal,
  wasAddedToCartModalProduct,
  wasAddedToCartModalCount,
  wasAddedToCartModalIsFetching,
  wasAddedToCartModalBoughtToo,
  dispatch,
  router,
  user,
  freeDeliveryRemainingAmount,
  data,
}: Props & WithRouterProps) {
  React.useEffect(() => {
    if (wasAddedToCartModalProduct) {
      dispatch(
        loadProductInCartRecommended(wasAddedToCartModalProduct.product_id),
      );
    }
  }, [wasAddedToCartModalProduct, dispatch]);

  const handleToCartButtonClick = () => {
    dispatch(setWasAddedToCartModalVisibility());
    router.push(cartIndexRoute() as string);
  };

  const saleInfo = data.data && data.data.saleInfo ? data.data.saleInfo : null;

  const addToCart = (product: any) => {
    dispatch(setAddToCartModalVisibility(false, product));
  };

  const addToRequest = (product: any) => {
    dispatch(setAddToCartModalVisibility(true, product));
  };

  const currency =
    prop(wasAddedToCartModalProduct, 'main_good.currency_id') ||
    prop(wasAddedToCartModalProduct, 'goods.0.currency_id', 'EUR');

  let sale;
  let cartSale;
  if (wasAddedToCartModalProduct) {
    const { sales } = wasAddedToCartModalProduct;
    if (sales && sales.length && saleInfo.length) {
      sale = saleInfo.filter(
        sale => sale.id === sales[0].id && sale.sale_type === 'products',
      );
    }
    if (saleInfo.length) {
      cartSale = saleInfo.filter(sale => sale.sale_type === 'cart');
    }
  }

  return (
    <>
      {wasAddedToCartModal && wasAddedToCartModalProduct && (
        <WasAddedToCartModal
          product={wasAddedToCartModalProduct}
          handleClose={() => dispatch(setWasAddedToCartModalVisibility())}
          handleToCartButtonClick={handleToCartButtonClick}
          count={wasAddedToCartModalCount}
          wasAddedToCartModalIsFetching={wasAddedToCartModalIsFetching}
          wasAddedToCartModalBoughtToo={wasAddedToCartModalBoughtToo}
        >
          {wasAddedToCartModalIsFetching ? (
            <ZemplinLoaderWrapper height={20} />
          ) : (
            wasAddedToCartModalBoughtToo &&
            wasAddedToCartModalBoughtToo.products.length > 0 && (
              <AdditionalProductsWrapper>
                <StyledTitleH2>{__('Mohlo by vás zaujímať')}</StyledTitleH2>
                <SliderComponent
                  type="add-to-cart-popup"
                  slides={wasAddedToCartModalBoughtToo.products}
                />
              </AdditionalProductsWrapper>
            )
          )}
          <FreeDeliveryInfo
            withBorder={false}
            padding={`${rem(24)} ${rem(24)} 0`}
            amount={freeDeliveryRemainingAmount}
            currency={currency}
          />
          {sale && sale.length
            ? sale.map(sale => (
                <SaleWrapper>
                  {sale.buy_count + sale.sale_count - sale.total_products === 1
                    ? `${__(`Vyberte si ešte`)} ${sale.text} ${__(
                        `produkt z kategórie`,
                      )} ${sale.category_name.toLowerCase()} ${__(
                        `a jeden (najlacnejší) máte zadarmo. Zľavy a akcie sa nedajú kombinovať.`,
                      )}`
                    : `${__(`Vyberte si ešte`)} ${sale.text} ${__(
                        `produkty z kategórie`,
                      )} ${sale.category_name.toLowerCase()} ${__(
                        `a jeden (najlacnejší) máte zadarmo. Zľavy a akcie sa nedajú kombinovať.`,
                      )}`}
                </SaleWrapper>
              ))
            : null}
          {cartSale && cartSale.length
            ? cartSale.map(cartSale => (
                <SaleWrapper key={cartSale.id}>
                  {`${__(`Nakúpte ešte za`)} ${cartSale.text} ${__(
                    `€ označené produkty z kategórie`,
                  )} ${cartSale.category_name.toLowerCase()} ${__(
                    `a k objednávke dostanete darček.`,
                  )}`}
                </SaleWrapper>
              ))
            : null}
        </WasAddedToCartModal>
      )}
    </>
  );
}

const StyledTitleH2 = styled(TitleH2)`
  margin-bottom: 0;
`;

const AdditionalProductsWrapper = styled(FlexCol)`
  padding: 0 ${rem(24)} 0 ${rem(24)};
  border-bottom: ${({ theme }) => theme.borders.primary};
  ${({ theme }) => theme.mediab.m580`
   padding: 0 ${rem(24)} ${rem(10)}; ${rem(24)};
  `}
  @media (max-height:  680px) {
    display: none;
  }
`;

const Title = styled.h4`
  font-size: ${rem(20)};
  font-weight: 400;
  line-height: ${rem(38)};
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0;
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `}
`;

const SaleWrapper = styled(FlexRow)`
  margin: 0 auto;
  margin: ${rem(10)} ${rem(10)} 0 ${rem(10)};
  border-radius: ${rem(2)};
  padding: ${rem(12)} ${rem(16)};
  justify-content: center;
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${rem(15)};
  text-align: center;
`;

const TextHighlight = styled.span`
  color: ${({ theme }) => theme.color.green};
`;

const mapStateToProps = state => {
  return {
    wasAddedToCartModal: wasAddedToCartModalSelector(state),
    wasAddedToCartModalProduct: wasAddedToCartModalProductSelector(state),
    wasAddedToCartModalCount: wasAddedToCartModalCountSelector(state),
    wasAddedToCartModalIsFetching: wasAddedToCartModalIsFetchingSelector(state),
    wasAddedToCartModalBoughtToo: wasAddedToCartModalBoughtTooSelector(state),
    freeDeliveryRemainingAmount: freeDeliveryRemainingAmountSelector(state),
    user: state.auth.user,
    data: state.cart,
  };
};

export default connect(mapStateToProps)(withRouter(AddedToCartModal));
