import {
  REQUEST_HOME_ARTICLES,
  RECEIVE_HOME_ARTICLES_SUCCESS,
  RECEIVE_HOME_ARTICLES_ERROR,
  REQUEST_HOME_MOST_READ,
  RECEIVE_HOME_MOST_READ_SUCCESS,
  RECEIVE_HOME_MOST_READ_ERROR,
  REQUEST_HOME_RECOMMENDED,
  RECEIVE_HOME_RECOMMENDED_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_ERROR,
  REQUEST_HOME_FAST_NEWS,
  RECEIVE_HOME_FAST_NEWS_SUCCESS,
  RECEIVE_HOME_FAST_NEWS_ERROR,
  REQUEST_HOME_TOP_ARTICLE,
  RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  RECEIVE_HOME_TOP_ARTICLE_ERROR,
  REQUEST_HOME_EBOOKS,
  RECEIVE_HOME_EBOOKS_SUCCESS,
  RECEIVE_HOME_EBOOKS_ERROR,
  SET_HOME_RECOMMENDED_FILTER,
  SET_HOME_MOST_READ_FILTER,
  RECEIVE_HOME_BANNER_SUCCESS,
  SET_HOME_ARTICLES_FILTERS,
  RECEIVE_MORE_HOME_ARTICLES_SUCCESS,
  REQUEST_HOME_RECOMMENDED_OPTIONS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  REQUEST_HOME_NEW_PRODUCTS,
  RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  REQUEST_HOME_SALE_PRODUCTS,
  RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  RECEIVE_LANDING_PAGE_PRODUCTS_ERROR,
  RECEIVE_LANDING_PAGE_PRODUCTS_SUCCESS,
  RECEIVE_NEW_ARTICLES_SUCCESS,
} from './constants';

const INITIAL_STATE: any = {
  newProducts: {
    isFetching: false,
    productsData: null,
  },
  saleProducts: {
    isFetching: false,
    productsData: null,
  },
  topArticles: {
    articles: null,
    total: 0,
  },
  newArticles: {
    articles: null,
    total: 0,
  },
  adBanner: null,
};

export const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_HOME_NEW_PRODUCTS: {
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_NEW_PRODUCTS_SUCCESS: {
      const productsData = {
        ...state.newProducts.productsData,
        [action.payload.categoryId]: { ...action.payload.products },
      };
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: false,
          productsData,
        },
      };
    }

    case RECEIVE_HOME_NEW_PRODUCTS_ERROR: {
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_SALE_PRODUCTS: {
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_SALE_PRODUCTS_SUCCESS: {
      const productsData = {
        ...state.saleProducts.productsData,
        [action.payload.categoryId]: { ...action.payload.products },
      };
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: false,
          productsData,
        },
      };
    }

    case RECEIVE_HOME_SALE_PRODUCTS_ERROR: {
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case RECEIVE_HOME_BANNER_SUCCESS: {
      return {
        ...state,
        adBanner: action.payload.banner,
      };
    }

    case RECEIVE_HOME_TOP_ARTICLE_SUCCESS: {
      return {
        ...state,
        topArticles: action.payload.topArticles,
      };
    }

    case RECEIVE_NEW_ARTICLES_SUCCESS: {
      return {
        ...state,
        newArticles: action.payload.newArticles,
      };
    }

    case RECEIVE_LANDING_PAGE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        landingPageProducts: {
          ...action.payload.landingPageProducts,
          error: action.payload.error,
        },
      };
    }

    default: {
      return state;
    }
  }
};
