import {
  UserOrders,
  IrequestUserOrders,
  IreceiveUserOrdersSuccess,
  IreceiveUserOrdersError,
  IrequestUserData,
  UserData,
  IreceiveUserDataSuccess,
  IreceiveUserDataError,
  IeditUserData,
  IrequestUpdateUserData,
  IreceiveUpdatedUserDataSuccess,
  IreceiveUpdatedUserDataError,
  IrequestDeliveryAddresses,
  IrecieveDeliveryAddressesSuccess,
  UserDeliveryAddresses,
  IrecieveDeliveryAddressesError,
  IrequestPoints,
  IreceivePointsSuccess,
  UserPoints,
  IreceivePointsError,
  IrequestPasswordChange,
  IreceiveUserPasswordChangeSuccess,
  IreceiveUserPasswordChangeError,
  IpasswordChangeInputChange,
  IdeliveryAddressInputChange,
  IrequestUpdateDeliveryAddress,
  IreceiveUpdateDeliveryAddressSuccess,
  IreceiveUpdateDeliveryAddressError,
  IsetDeliveryAddressInitValues,
} from './types';
import { userSelector } from '../App/selectors';
import {
  userInfoSelector,
  userPasswordChangePropsSelector,
  updatedDeliveryAddressSelector,
} from './selectors';
import * as cookie from 'react-cookies';
import { USER_COOKIE, LOGOUT_USER } from 'react-auth';
import {
  REQUEST_DELETE_DELIVERY_ADDRESS,
  RECEIVE_DELETE_DELIVERY_ADDRESS_SUCCESS,
  RECEIVE_DELETE_DELIVERY_ADDRESS_ERROR,
  REQUEST_REQUEST_ACTIVE_ORDERS,
  RECEIVE_ACTIVE_ORDERS_ERROR,
  RECEIVE_ACTIVE_ORDERS_SUCCESS,
  RECEIVE_ORDER_DATA_SUCCESS,
  REQUEST_ORDER_DATA,
  RECEIVE_ORDER_DATA_ERROR,
} from './constants';

const requestUserOrders = (): IrequestUserOrders => ({
  type: 'User/REQUEST_USER_ORDERS',
});

const receiveUserOrdersSuccess = (
  userOrders: UserOrders,
): IreceiveUserOrdersSuccess => ({
  type: 'User/RECEIVE_USER_ORDERS_SUCCESS',
  payload: {
    userOrders,
  },
});

const receiveUserOrderError = (error): IreceiveUserOrdersError => ({
  type: 'User/RECEIVE_USER_ORDERS_ERROR',
  payload: {
    error,
  },
});

export const loadUserOrders = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestUserOrders());
      const userId = getState().auth.user.id;
      const userOrders: UserOrders = await API.getOrders(userId);
      dispatch(receiveUserOrdersSuccess(userOrders));
    } catch (e) {
      dispatch(receiveUserOrderError(e.details.description));
    }
  };
};

const requestUserActiveOrders = () => ({
  type: REQUEST_REQUEST_ACTIVE_ORDERS,
});

const receiveUserActiveOrdersSuccess = (userActiveOrders: UserOrders) => ({
  type: RECEIVE_ACTIVE_ORDERS_SUCCESS,
  payload: {
    userActiveOrders,
  },
});

const receiveUserActiveOrderError = error => ({
  type: RECEIVE_ACTIVE_ORDERS_ERROR,
  payload: {
    error,
  },
});

export const loadUserActiveOrders = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestUserActiveOrders());
      const userId = getState().auth.user.id;
      const userActiveOrders: UserOrders = await API.getOrders(userId, {
        onlyActive: 1,
      });
      dispatch(receiveUserActiveOrdersSuccess(userActiveOrders));
    } catch (e) {
      dispatch(receiveUserActiveOrderError(e.details.description));
    }
  };
};

const requestUserData = (): IrequestUserData => ({
  type: 'User/REQUEST_USER_DATA',
});

const receiveUserDataSuccess = (
  userData: UserData,
): IreceiveUserDataSuccess => ({
  type: 'User/RECEIVE_USER_DATA_SUCCESS',
  payload: {
    data: userData,
  },
});

const receiveUserDataError = (error: any): IreceiveUserDataError => ({
  type: 'User/RECEIVE_USER_DATA_ERROR',
  payload: {
    error,
  },
});

export const loadUserData = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestUserData());
      const userId = userSelector(getState()).id;
      const userData: UserData = await API.getCustomerDetails(userId);
      dispatch(receiveUserDataSuccess(userData));
    } catch (e) {
      dispatch(receiveUserDataError(e.details.description));
    }
  };
};

export const editUserData = (
  inputName: string,
  inputValue: string,
): IeditUserData => ({
  type: 'User/EDIT_USER_DATA',
  payload: {
    inputName,
    inputValue,
  },
});

const requestUpdateUserData = (): IrequestUpdateUserData => ({
  type: 'User/UPDATE_USER_DATA',
});

const updateUserDataSuccess = (
  updatedUserData: UserData,
): IreceiveUpdatedUserDataSuccess => ({
  type: 'User/UPDATE_USER_DATA_SUCCESS',
  payload: {
    data: updatedUserData,
  },
});

const updateUserDataError = (error): IreceiveUpdatedUserDataError => ({
  type: 'User/UPDATE_USER_DATA_ERROR',
  payload: {
    error,
  },
});

export const updateUserData = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestUpdateUserData());
      const userId: string = userSelector(getState()).id;
      const userData = userInfoSelector(getState());
      const {
        meno,
        priezvisko,
        email,
        ulica,
        telefon,
        mesto,
        country,
        cislo,
        psc,
        firma,
        ico,
        dic,
        icdph,
      } = userData;
      const updatedUserData: UserData = API.updateCustomerDetails(
        userId,
        {},
        {
          meno,
          priezvisko,
          email,
          ulica,
          telefon,
          mesto,
          country,
          cislo,
          psc,
          firma,
          ico,
          dic,
          icdph,
          id: userId,
        },
      );
      dispatch(updateUserDataSuccess(updatedUserData));
    } catch (e) {
      dispatch(updateUserDataError(e.details.description));
    }
  };
};

export const updateUserIsCompany = (isCompany: boolean) => ({
  type: 'User/IS_COMPANY',
  payload: {
    data: isCompany,
  },
});

const resetUserData = () => ({
  type: 'User/RESET_USER_DATA',
});

const reactAuthPackageUserLogoutAction = () => ({
  type: LOGOUT_USER,
});

export const logoutUser = () => {
  return async (dispatch, getState, API) => {
    try {
      cookie.remove(USER_COOKIE, { path: '/' });
      dispatch(resetUserData());
      dispatch(reactAuthPackageUserLogoutAction());
      await API.oauthLogout();
    } catch (e) {
      console.log(e);
    }
  };
};

const requestUserDeliveryAddresses = (): IrequestDeliveryAddresses => ({
  type: 'User/REQUEST_DELIVERY_ADDRESSES',
});

const receiveUserDeliveryAddresses = (
  deliveryAddresses: UserDeliveryAddresses,
): IrecieveDeliveryAddressesSuccess => ({
  type: 'User/RECEIVE_DELIVERY_ADDRESSES_SUCCESS',
  payload: {
    deliveryAddresses,
  },
});

const receieveUserDeliveryAddressesError = (
  error: any,
): IrecieveDeliveryAddressesError => ({
  type: 'User/RECEIVE_DELIVERY_ADDRESSES_ERROR',
  payload: {
    error,
  },
});

export const loadUserDeliveryAddresses = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestUserDeliveryAddresses());
      const userId = userSelector(getState()).id;
      const deliveryAddresses: UserDeliveryAddresses = await API.getDeliveryAddresses(
        userId,
      );
      dispatch(receiveUserDeliveryAddresses(deliveryAddresses));
    } catch (e) {
      dispatch(receieveUserDeliveryAddressesError(e));
    }
  };
};

const requestUserPoints = (): IrequestPoints => ({
  type: 'User/REQUEST_POINTS',
});

const receiveUserPointsSuccess = (
  userPoints: UserPoints,
): IreceivePointsSuccess => ({
  type: 'User/RECEIVE_POINTS_SUCCESS',
  payload: {
    userPoints,
  },
});

const receiveUserPointsError = (error): IreceivePointsError => ({
  type: 'User/RECEIVE_POINTS_ERROR',
  payload: {
    error,
  },
});

export const loadUserPoints = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestUserPoints());
      const userId = userSelector(getState()).id;
      const userPoints: UserPoints = await API.loadTransactions(userId);
      dispatch(receiveUserPointsSuccess(userPoints));
    } catch (e) {
      dispatch(receiveUserPointsError(e));
    }
  };
};

const requestUserPasswordChange = (): IrequestPasswordChange => ({
  type: 'User/REQUEST_PASSWORD_CHANGE',
});

const receiveUserPasswordChangeSuccess = (
  message: string,
): IreceiveUserPasswordChangeSuccess => ({
  type: 'User/RECEIVE_PASSWORD_CHANGE_SUCCESS',
  payload: {
    message,
  },
});

const receiveUserPasswordChangeError = (
  error,
): IreceiveUserPasswordChangeError => ({
  type: 'User/RECEIVE_PASSWORD_CHANGE_ERROR',
  payload: {
    error,
  },
});

export const changeUserPassword = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestUserPasswordChange());
      const userId = userSelector(getState()).id;
      const {
        passwordCheck,
        newPassword,
        currentPassword,
      } = userPasswordChangePropsSelector(getState());
      await API.changePassword(
        userId,
        {},
        {
          current_password: currentPassword,
          new_password: newPassword,
          password_check: passwordCheck,
        },
      );
      dispatch(receiveUserPasswordChangeSuccess('Heslo bolo úspešne zmenené'));
    } catch (e) {
      dispatch(receiveUserPasswordChangeError(e.details.description));
    }
  };
};

export const handlePasswordChangeInputChange = (
  inputName: string,
  inputValue: string,
): IpasswordChangeInputChange => ({
  type: 'User/CHANGE_PASSWORD_FORM',
  payload: {
    inputName,
    inputValue,
  },
});

export const handleDeliveryAddressInputChange = (
  inputName: string,
  inputValue: string,
  addressId: string | null = null,
): IdeliveryAddressInputChange => ({
  type: 'User/EDIT_DELIVERY_ADDRESS_FORM',
  payload: {
    inputName,
    inputValue,
    addressId,
  },
});

const requestUpdateDeliveryAddress = (): IrequestUpdateDeliveryAddress => ({
  type: 'User/REQUEST_UPDATE_DELIVERY_ADDRESS',
});

const receiveUpdateDeliveryAddressSuccess = (): IreceiveUpdateDeliveryAddressSuccess => ({
  type: 'User/RECEIVE_UPDATE_DELIVERY_ADDRESS_SUCCESS',
});

const receiveUpdateDeliveryAddressError = (
  error: any,
): IreceiveUpdateDeliveryAddressError => ({
  type: 'User/RECEIVE_UPDATE_DELIVERY_ADDRESS_ERROR',
  payload: {
    error,
  },
});

export const updateDeliveryAddress = (addressId: number | undefined) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestUpdateDeliveryAddress());
      const addressToUpdated = updatedDeliveryAddressSelector(getState());
      const userId = userSelector(getState()).id;

      if (!addressId) {
        await API.createNewDeliveryAddress(
          userId,
          {},
          { ...addressToUpdated, id_user: userId },
        );
      } else {
        await API.updateDeliveryAddress(
          userId,
          addressId,
          {},
          { ...addressToUpdated },
        );
      }
      dispatch(receiveUpdateDeliveryAddressSuccess());
    } catch (e) {
      dispatch(receiveUpdateDeliveryAddressError(e));
    }
  };
};

export const setDeliveryAddressInitValues = (
  addressId: number | null,
): IsetDeliveryAddressInitValues => ({
  type: 'User/SET_DELIVERY_ADDRESS_INIT_VALUES',
  payload: {
    addressId,
  },
});

const requestDeleteDeliveryAddrress = () => ({
  type: REQUEST_DELETE_DELIVERY_ADDRESS,
});

const receiveDeleteDeliveryAddressSuccess = addressId => ({
  type: RECEIVE_DELETE_DELIVERY_ADDRESS_SUCCESS,
  payload: {
    addressId,
  },
});

const receiveDeleteDeliveryAddressError = error => ({
  type: RECEIVE_DELETE_DELIVERY_ADDRESS_ERROR,
  payload: {
    error,
  },
});

export const deleteDeliveryAddress = (addressId: number) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestDeleteDeliveryAddrress());
      const userId = userSelector(getState()).id;
      await API.deleteDeliveryAddress(userId, addressId);
      dispatch(receiveDeleteDeliveryAddressSuccess(addressId));
    } catch (e) {
      dispatch(receiveDeleteDeliveryAddressError(e));
    }
  };
};

const requestOrderData = () => ({
  type: REQUEST_ORDER_DATA,
});

const receiveOrderDataSuccess = order => ({
  type: RECEIVE_ORDER_DATA_SUCCESS,
  payload: {
    order,
  },
});

const receiveOrderDataError = error => ({
  type: RECEIVE_ORDER_DATA_ERROR,
  payload: {
    error,
  },
});

export const loadOrderData = (orderId: number) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestOrderData());
      // const userId = userSelector(getState()).id;
      const order = await API.getOrder(orderId);
      dispatch(receiveOrderDataSuccess(order));
    } catch (e) {
      dispatch(receiveOrderDataError(e));
    }
  };
};
