import * as React from 'react';
import styled, { css } from 'styled-components';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { getImagePath } from '../../../utilities/product';
import { rem } from 'polished';
import {
  NoItemsWrapper,
  FlexColCenter,
  GolfcentrumProductTabDefault as ProductTabDefault,
  GolfCentrumProductTabSmall as ProductTabSmall,
  ArticleTabDefault,
} from 'eshop-defaults';
import { prop } from '../../../utilities';
import { Link } from 'react-router';
import { forwardRef } from 'react';

export interface Props {
  items: any;
  isLightboxOpen: boolean;
  onCloseRequest: () => void;
  itemIndex: number;
  setItemIndex: (number: number) => void;
}

function LightboxCustom({
  items = [],
  isLightboxOpen = false,
  onCloseRequest,
  itemIndex = 0,
  setItemIndex,
}: Props) {
  return (
    <>
      {isLightboxOpen && items && items.length > 0 && (
        <Lightbox
          mainSrc={items[itemIndex]}
          nextSrc={items[(itemIndex + 1) % items.length]}
          prevSrc={items[(itemIndex + items.length - 1) % items.length]}
          onCloseRequest={onCloseRequest}
          onMovePrevRequest={() =>
            setItemIndex((itemIndex + items.length - 1) % items.length)
          }
          onMoveNextRequest={() => setItemIndex((itemIndex + 1) % items.length)}
        />
      )}
    </>
  );
}

export default LightboxCustom;
