import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import API, { ThenArg } from '../../services/API';
import { __, __r } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import {
  FinishedOrderReturn,
  InfoText,
  LoaderWrapper,
  MetaTags,
} from 'eshop-defaults';
import { connectSsr } from 'ssr-service';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
  setStep,
  requestStep,
  requestOrderReturnIsFetching,
} from './myAccountSlice';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import FullOrder from '../../components/MyAccount/FullOrder';
import { setOrderToReturn } from '../../containers/MyAccount/myAccountSlice';
import { prop } from '../../utilities';
import { replace } from 'react-router-redux';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  padding: 0;
  background-color: white;
  margin: 0 auto;
  border-radius: ${rem(4)};
  ${({ theme }) => theme.mediab.l925`
   padding-top: ${rem(24)};
  `}
`;

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
  isFetching_order: boolean;
  orderReturnState: any;
  state_step: number;
}

const ORDER_ROUTES = {
  checkout: { step: 1, name: 'checkout' },
  summary: { step: 2, name: 'summary' },
  finished: { step: 3, name: 'finished' },
};

class OrderReturns extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { orderPublicId, step },
      orderData,
      state_step,
    } = props;

    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_ORDERS));
      await Promise.all([
        await dispatch(setStep(ORDER_ROUTES[step]['step'])),
        await dispatch(fetchFinishedOrder(orderPublicId)),
        dispatch(
          setBreadCrumbPath(BreadCrumbType.ORDER_RETURNS, null, {
            orderPublicId,
            step: ORDER_ROUTES[step]['name'],
          }),
        ),
      ]);

      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const {
      orderData,
      isFetching_order,
      isFetching,
      dispatch,
      state_step,
    } = this.props;

    const handleProductReturn = async (order_id, good, step) => {
      await dispatch(setOrderToReturn(order_id, good, undefined, step));
    };
    const handleOnChangeContact = async (order_id, contactInfo, step) => {
      await dispatch(
        setOrderToReturn(
          order_id,
          undefined,
          contactInfo,
          step,
          contactInfo.delivery,
        ),
      );
    };
    const handleDelivery = async (order_id, delivery) => {
      await dispatch(
        setOrderToReturn(order_id, undefined, undefined, undefined, delivery),
      );
    };

    const order_payload = prop(orderData, 'payload.order_to_return');
    let step = state_step;
    if (orderData) {
      if (!order_payload && state_step !== 1) {
        step = 1;
        dispatch(
          replace(
            `${__r(
              `routes:moj-ucet/vratenie-tovaru`,
              '/moj-ucet/vratenie-tovaru',
            )}/checkout/${orderData.public_id}`,
          ),
        );
      }
      if (order_payload && order_payload.step === 3) {
        step = 3;
      }
    }

    if (isFetching_order) {
      return <LoaderWrapper />;
    }
    if (!orderData) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Takáto objednávka neexistuje')}
        />
      );
    }
    return (
      <>
        <MetaTags
          tags={{ title: __('Moj účet - Vrátenie tovaru | GOLF CENTRUM.sk') }}
        />
        {step < 3 ? (
          <FullOrder
            order={orderData}
            onProductReturnClick={handleProductReturn}
            onChangeContact={handleOnChangeContact}
            step={step}
            isFetching={isFetching}
            setDelivery={handleDelivery}
          />
        ) : (
          <FinishedOrderReturn order={orderData} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderData: finishedOrderDataSelector(state),
    isFetching_order: finishedOrderIsFetchingSelector(state),
    state_step: requestStep(state),
    isFetching: requestOrderReturnIsFetching(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'OrderReturns' })(withRouter(OrderReturns)),
);
