import APIClass from '../../services/API';
import {
  REQUEST_HOME_ARTICLES,
  RECEIVE_HOME_ARTICLES_SUCCESS,
  RECEIVE_HOME_ARTICLES_ERROR,
  REQUEST_HOME_MOST_READ,
  RECEIVE_HOME_MOST_READ_SUCCESS,
  RECEIVE_HOME_MOST_READ_ERROR,
  REQUEST_HOME_RECOMMENDED,
  RECEIVE_HOME_RECOMMENDED_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_ERROR,
  REQUEST_HOME_FAST_NEWS,
  RECEIVE_HOME_FAST_NEWS_SUCCESS,
  RECEIVE_HOME_FAST_NEWS_ERROR,
  REQUEST_HOME_TOP_ARTICLE,
  RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  RECEIVE_HOME_TOP_ARTICLE_ERROR,
  REQUEST_HOME_EBOOKS,
  RECEIVE_HOME_EBOOKS_SUCCESS,
  RECEIVE_HOME_EBOOKS_ERROR,
  SET_HOME_RECOMMENDED_FILTER,
  SET_HOME_MOST_READ_FILTER,
  RECEIVE_HOME_BANNER_SUCCESS,
  REQUEST_HOME_BANNER,
  SET_HOME_ARTICLES_FILTERS,
  RECEIVE_MORE_HOME_ARTICLES_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  REQUEST_HOME_NEW_PRODUCTS,
  REQUEST_LANDING_PAGE_PRODUCTS,
  RECEIVE_LANDING_PAGE_PRODUCTS_SUCCESS,
  RECEIVE_LANDING_PAGE_PRODUCTS_ERROR,
  RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  REQUEST_HOME_SALE_PRODUCTS,
  RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  REQUEST_NEW_ARTICLES,
  RECEIVE_NEW_ARTICLES_SUCCESS,
  RECEIVE_NEW_ARTICLES_ERROR,
} from './constants';
import { prop } from '../../utilities';
import { currencySelector, langSelector } from '../App/selectors';
import {
  newProductsSelector,
  saleProductsSelector,
  landingPageProductsSelector,
} from './selectors';

const PRODUCTS = {
  products: [
    {
      istop: false,
      is_favorite: false,
      is_popular: false,
      is_recommended: false,
      idoklad_id: null,
      product_weight: 0,
      type_id: 1,
      product_id: 1,
      name: 'LK 100 kúpeľňový valec ',
      is_sale: 0,
      is_new: 0,
      plu: '501-T1435010039',
      ean: '',
      url: 'lk-100-kupelnovy-valec',
      description: '',
      rating: '0.00000',
      brand: {},
      picture: null,
      issaleout: 0,
      main_good: {
        good_id: 1,
        product_id: 1,
        group_prices: null,
        availability_date: null,
        buy_price: null,
        idoklad_id: null,
        helios_id: null,
        order_quantity_min: null,
        helios_units: null,
        currency_id: 'EUR',
        vat_rate: 20,
        availability_id: null,
        units: 'ks',
        order_number: null,
        ean: null,
        internal_code: '',
        on_stock_count: 0,
        is_on_stock: 0,
        point_good: 0,
        points_amount: 0,
        sale_percentage: null,
        oldprice: null,
        price_without_vat: 441.67,
        lang: 'sk',
        sale: false,
        order_quantity_limit: false,
        giftcard: null,
        weight: 0,
        web_attribs: null,
        web_order_attribs: [],
        price_without_vat_sale: 0,
        purchaseprice: 0,
        sale_valid: null,
        priceinfo: null,
        unitprice_sale: null,
        final_price: 530.004,
        imagePath: null,
        unitprice: 530.004,
        karat_id: 'undefined',
        valid_from: null,
        valid_to: null,
        customer_prices: null,
        group_price: {
          unitprice: 0,
          oldprice: 0,
          sale_percentage: null,
        },
        availability: {
          avail_id: null,
          avail_uniqid: null,
          order_available: null,
          translations: [],
        },
        currency: {
          id: 'EUR',
          name: 'EUR',
        },
        qtyDiscounts: [],
      },
      goods: [
        {
          good_id: 1,
          product_id: 1,
          group_prices: null,
          availability_date: null,
          buy_price: null,
          idoklad_id: null,
          helios_id: null,
          order_quantity_min: null,
          helios_units: null,
          currency_id: 'EUR',
          vat_rate: 20,
          availability_id: null,
          units: 'ks',
          order_number: null,
          ean: null,
          internal_code: '',
          on_stock_count: 0,
          is_on_stock: 0,
          point_good: 0,
          points_amount: 0,
          sale_percentage: null,
          oldprice: null,
          price_without_vat: 441.67,
          lang: 'sk',
          sale: false,
          order_quantity_limit: false,
          giftcard: null,
          weight: 0,
          web_attribs: null,
          web_order_attribs: [],
          price_without_vat_sale: 0,
          purchaseprice: 0,
          sale_valid: null,
          priceinfo: null,
          unitprice_sale: null,
          final_price: 530.004,
          imagePath: null,
          unitprice: 530.004,
          karat_id: 'undefined',
          valid_from: null,
          valid_to: null,
          customer_prices: null,
          group_price: {
            unitprice: 0,
            oldprice: 0,
            sale_percentage: null,
          },
          availability: {
            avail_id: null,
            avail_uniqid: null,
            order_available: null,
            translations: [],
          },
          currency: {
            id: 'EUR',
            name: 'EUR',
          },
          qtyDiscounts: [],
        },
      ],
      attribs: [
        {
          attrib_id: 25,
          type_id: 1,
          group_id: null,
          attrib_name: 'Rozmer',
          attrib_ismulti: 0,
          attrib_sort: null,
          attrib_pbl: 1,
          attrib_unit: null,
          attrib_list: 0,
          attrib_code: null,
          attrib_grid: 0,
          value_id: null,
          attrib_value: null,
          custom_value: null,
          group_name: null,
          attrib_uid: null,
          attrib_type: 'zakladny',
          product_id: null,
          min: -1,
          max: -1,
          translations: [],
          value_groups: [],
          values: [
            {
              value_id: 327,
              attrib_id: 25,
              attrib_value: '95 l',
              attrib_pict: null,
              group_id: null,
              searchfilter: 1,
              value_sort: null,
              amount: null,
              istop: false,
              translations: [],
            },
          ],
        },
      ],
      min_price: null,
      max_price: null,
      publish: [],
      valid_from: null,
      valid_to: null,
      products_in_set: [],
      street: null,
      city: null,
      zip: null,
    },
    {
      istop: false,
      is_favorite: false,
      is_popular: false,
      is_recommended: false,
      idoklad_id: null,
      product_weight: 0,
      type_id: 2,
      product_id: 2,
      name: 'Sporák 85 OKONOM  De Luxe  biely ľavý',
      is_sale: 0,
      is_new: 0,
      plu: '500-F9313712061',
      ean: '',
      url: 'sporak-85-okonom-de-luxe-biely-lavy',
      description: '',
      rating: '0.00000',
      brand: {},
      picture: null,
      issaleout: 0,
      main_good: {
        good_id: 2,
        product_id: 2,
        group_prices: null,
        availability_date: null,
        buy_price: null,
        idoklad_id: null,
        helios_id: null,
        order_quantity_min: null,
        helios_units: null,
        currency_id: 'EUR',
        vat_rate: 20,
        availability_id: null,
        units: 'ks',
        order_number: null,
        ean: null,
        internal_code: '',
        on_stock_count: 0,
        is_on_stock: 0,
        point_good: 0,
        points_amount: 0,
        sale_percentage: null,
        oldprice: null,
        price_without_vat: 558.33,
        lang: 'sk',
        sale: false,
        order_quantity_limit: false,
        giftcard: null,
        weight: 0,
        web_attribs: null,
        web_order_attribs: [],
        price_without_vat_sale: 0,
        purchaseprice: 0,
        sale_valid: null,
        priceinfo: null,
        unitprice_sale: null,
        final_price: 669.996,
        imagePath: null,
        unitprice: 669.996,
        karat_id: 'undefined',
        valid_from: null,
        valid_to: null,
        customer_prices: null,
        group_price: {
          unitprice: 0,
          oldprice: 0,
          sale_percentage: null,
        },
        availability: {
          avail_id: null,
          avail_uniqid: null,
          order_available: null,
          translations: [],
        },
        currency: {
          id: 'EUR',
          name: 'EUR',
        },
        qtyDiscounts: [],
      },
      goods: [
        {
          good_id: 2,
          product_id: 2,
          group_prices: null,
          availability_date: null,
          buy_price: null,
          idoklad_id: null,
          helios_id: null,
          order_quantity_min: null,
          helios_units: null,
          currency_id: 'EUR',
          vat_rate: 20,
          availability_id: null,
          units: 'ks',
          order_number: null,
          ean: null,
          internal_code: '',
          on_stock_count: 0,
          is_on_stock: 0,
          point_good: 0,
          points_amount: 0,
          sale_percentage: null,
          oldprice: null,
          price_without_vat: 558.33,
          lang: 'sk',
          sale: false,
          order_quantity_limit: false,
          giftcard: null,
          weight: 0,
          web_attribs: null,
          web_order_attribs: [],
          price_without_vat_sale: 0,
          purchaseprice: 0,
          sale_valid: null,
          priceinfo: null,
          unitprice_sale: null,
          final_price: 669.996,
          imagePath: null,
          unitprice: 669.996,
          karat_id: 'undefined',
          valid_from: null,
          valid_to: null,
          customer_prices: null,
          group_price: {
            unitprice: 0,
            oldprice: 0,
            sale_percentage: null,
          },
          availability: {
            avail_id: null,
            avail_uniqid: null,
            order_available: null,
            translations: [],
          },
          currency: {
            id: 'EUR',
            name: 'EUR',
          },
          qtyDiscounts: [],
        },
      ],
      attribs: [
        {
          attrib_id: 25,
          type_id: 1,
          group_id: null,
          attrib_name: 'Rozmer',
          attrib_ismulti: 0,
          attrib_sort: null,
          attrib_pbl: 1,
          attrib_unit: null,
          attrib_list: 0,
          attrib_code: null,
          attrib_grid: 0,
          value_id: null,
          attrib_value: null,
          custom_value: null,
          group_name: null,
          attrib_uid: null,
          attrib_type: 'zakladny',
          product_id: null,
          min: -1,
          max: -1,
          translations: [],
          value_groups: [],
          values: [
            {
              value_id: 328,
              attrib_id: 25,
              attrib_value: 'ľavý, biely',
              attrib_pict: null,
              group_id: null,
              searchfilter: 1,
              value_sort: null,
              amount: null,
              istop: false,
              translations: [],
            },
          ],
        },
      ],
      min_price: null,
      max_price: null,
      publish: [],
      valid_from: null,
      valid_to: null,
      products_in_set: [],
      street: null,
      city: null,
      zip: null,
    },
    {
      istop: false,
      is_favorite: false,
      is_popular: false,
      is_recommended: false,
      idoklad_id: null,
      product_weight: 0,
      type_id: 2,
      product_id: 3,
      name: 'Sporák 85 OKONOM De Luxe  biely pravý',
      is_sale: 0,
      is_new: 0,
      plu: '500-F9313712011',
      ean: '',
      url: 'sporak-85-okonom-de-luxe-biely-pravy',
      description: '',
      rating: '0.00000',
      brand: {},
      picture: null,
      issaleout: 0,
      main_good: {
        good_id: 3,
        product_id: 3,
        group_prices: null,
        availability_date: null,
        buy_price: null,
        idoklad_id: null,
        helios_id: null,
        order_quantity_min: null,
        helios_units: null,
        currency_id: 'EUR',
        vat_rate: 20,
        availability_id: null,
        units: 'ks',
        order_number: null,
        ean: null,
        internal_code: '',
        on_stock_count: 0,
        is_on_stock: 0,
        point_good: 0,
        points_amount: 0,
        sale_percentage: null,
        oldprice: null,
        price_without_vat: 558.33,
        lang: 'sk',
        sale: false,
        order_quantity_limit: false,
        giftcard: null,
        weight: 0,
        web_attribs: null,
        web_order_attribs: [],
        price_without_vat_sale: 0,
        purchaseprice: 0,
        sale_valid: null,
        priceinfo: null,
        unitprice_sale: null,
        final_price: 669.996,
        imagePath: null,
        unitprice: 669.996,
        karat_id: 'undefined',
        valid_from: null,
        valid_to: null,
        customer_prices: null,
        group_price: {
          unitprice: 0,
          oldprice: 0,
          sale_percentage: null,
        },
        availability: {
          avail_id: null,
          avail_uniqid: null,
          order_available: null,
          translations: [],
        },
        currency: {
          id: 'EUR',
          name: 'EUR',
        },
        qtyDiscounts: [],
      },
      goods: [
        {
          good_id: 3,
          product_id: 3,
          group_prices: null,
          availability_date: null,
          buy_price: null,
          idoklad_id: null,
          helios_id: null,
          order_quantity_min: null,
          helios_units: null,
          currency_id: 'EUR',
          vat_rate: 20,
          availability_id: null,
          units: 'ks',
          order_number: null,
          ean: null,
          internal_code: '',
          on_stock_count: 0,
          is_on_stock: 0,
          point_good: 0,
          points_amount: 0,
          sale_percentage: null,
          oldprice: null,
          price_without_vat: 558.33,
          lang: 'sk',
          sale: false,
          order_quantity_limit: false,
          giftcard: null,
          weight: 0,
          web_attribs: null,
          web_order_attribs: [],
          price_without_vat_sale: 0,
          purchaseprice: 0,
          sale_valid: null,
          priceinfo: null,
          unitprice_sale: null,
          final_price: 669.996,
          imagePath: null,
          unitprice: 669.996,
          karat_id: 'undefined',
          valid_from: null,
          valid_to: null,
          customer_prices: null,
          group_price: {
            unitprice: 0,
            oldprice: 0,
            sale_percentage: null,
          },
          availability: {
            avail_id: null,
            avail_uniqid: null,
            order_available: null,
            translations: [],
          },
          currency: {
            id: 'EUR',
            name: 'EUR',
          },
          qtyDiscounts: [],
        },
      ],
      attribs: [
        {
          attrib_id: 25,
          type_id: 1,
          group_id: null,
          attrib_name: 'Rozmer',
          attrib_ismulti: 0,
          attrib_sort: null,
          attrib_pbl: 1,
          attrib_unit: null,
          attrib_list: 0,
          attrib_code: null,
          attrib_grid: 0,
          value_id: null,
          attrib_value: null,
          custom_value: null,
          group_name: null,
          attrib_uid: null,
          attrib_type: 'zakladny',
          product_id: null,
          min: -1,
          max: -1,
          translations: [],
          value_groups: [],
          values: [
            {
              value_id: 329,
              attrib_id: 25,
              attrib_value: 'pravý, biely',
              attrib_pict: null,
              group_id: null,
              searchfilter: 1,
              value_sort: null,
              amount: null,
              istop: false,
              translations: [],
            },
          ],
        },
      ],
      min_price: null,
      max_price: null,
      publish: [],
      valid_from: null,
      valid_to: null,
      products_in_set: [],
      street: null,
      city: null,
      zip: null,
    },
  ],
  total: 212,
  price_min: 0,
  price_max: 100000,
  offset: 0,
  limit: 3,
};

const requestHomeNewProducts = () => ({
  type: REQUEST_HOME_NEW_PRODUCTS,
});

const receiveHomeNewProductsSuccess = (products, categoryId: string) => ({
  type: RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  payload: {
    products,
    categoryId,
  },
});

const receiveHomeNewProductsError = error => ({
  type: RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeNewProducts = (categoryId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const news = newProductsSelector(state);

      if (!news || !news.productsData || !news.productsData[categoryId]) {
        const lang = langSelector(state);
        const currency = currencySelector(state);
        dispatch(requestHomeNewProducts());
        const products = await APIClass.searchProducts(
          {
            limit: 3,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            isNew: '1',
            withPublish: '1',
          },
          { xAcceptLanguage: lang, xCurrency: currency },
        );
        dispatch(receiveHomeNewProductsSuccess(products, categoryId));
      }
    } catch (e) {
      console.log({ error: e });
      dispatch(receiveHomeNewProductsError(e));
    }
  };
};

const requestHomeSaleProducts = () => ({
  type: REQUEST_HOME_SALE_PRODUCTS,
});

const receiveHomeSaleProductsSuccess = (products, categoryId) => ({
  type: RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  payload: {
    products,
    categoryId,
  },
});

const receiveHomeSaleProductsError = error => ({
  type: RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeSaleProducts = (categoryId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const saleProducts = saleProductsSelector(state);

      if (
        !saleProducts ||
        !saleProducts.productsData ||
        !saleProducts.productsData[categoryId]
      ) {
        const lang = langSelector(state);

        dispatch(requestHomeSaleProducts());
        const products = await APIClass.searchProducts(
          {
            limit: 3,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            isSaleout: 1,
            categoryId,
            withPublish: '1',
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeSaleProductsSuccess(products, categoryId));
      }
    } catch (e) {
      dispatch(receiveHomeSaleProductsError(e));
    }
  };
};

export const setHomeArticlesFilters = filters => ({
  type: SET_HOME_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});

const requestHomeRecommended = () => ({
  type: REQUEST_HOME_RECOMMENDED,
});

const receiveHomeRecommendedSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_ERROR,
  payload: {
    error,
  },
});

const setHomeRecommendedFilter = filter => ({
  type: SET_HOME_RECOMMENDED_FILTER,
  payload: {
    filter,
  },
});

export const loadHomeRecommended = filter => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeRecommended());
      if (filter && filter !== getState().homepage.recommended.filter) {
        dispatch(setHomeRecommendedFilter(filter));
      }
      const lang = langSelector(getState());

      let tree =
        getState().recommended &&
        getState().recommended.recommendedArticlesOptions;
      if (!tree) {
        const subtree = await APIClass.loadSitemapSubtree(
          'ODPORUCANE',
          {},
          {
            xAcceptLanguage: lang,
          },
        );
        tree = prop(subtree, 'sitemap_tree', []);
        dispatch(loadHomeRecommendedOptions(tree));
      }
      const firstOptionId = tree && tree[0] && tree[0].id;

      const id = parseInt(filter, 10) || firstOptionId || 0;
      if (id) {
        const articles = await APIClass.loadArticles(
          {
            sitemapId: id,
            limit: 5,
            sfForm: 'Article',
            onlyActual: '1',
            isPublished: '1',
            tagExcludeId: 1518,
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeRecommendedSuccess(articles));
      } else {
        dispatch(receiveHomeRecommendedSuccess([]));
      }
    } catch (e) {
      dispatch(receiveHomeRecommendedError(e));
    }
  };
};

const receiveHomeRecommendedOptionsSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedOptionsError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeRecommendedOptions = options => {
  return async (dispatch, getState, API) => {
    try {
      await dispatch(receiveHomeRecommendedOptionsSuccess(options));
    } catch (e) {
      dispatch(receiveHomeRecommendedOptionsError(e));
    }
  };
};

const requestHomeFastNews = () => ({
  type: REQUEST_HOME_FAST_NEWS,
});

const receiveHomeFastNewsSuccess = fastNews => ({
  type: RECEIVE_HOME_FAST_NEWS_SUCCESS,
  payload: {
    fastNews,
  },
});

const receiveHomeFastNewsError = error => ({
  type: RECEIVE_HOME_FAST_NEWS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeFastNews = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeFastNews());
      const lang = langSelector(getState());
      const articles = await APIClass.loadArticles(
        {
          sitemapUniqueId: 'BLESKOVKY',
          limit: 3,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeFastNewsSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeFastNewsError(e));
    }
  };
};

const requestHomeTopArticle = () => ({
  type: REQUEST_HOME_TOP_ARTICLE,
});

const receiveHomeTopArticlesSuccess = topArticles => ({
  type: RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  payload: {
    topArticles,
  },
});

const receiveHomeTopArticleError = error => ({
  type: RECEIVE_HOME_TOP_ARTICLE_ERROR,
  payload: {
    error,
  },
});

export const loadHomeTopArticle = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeTopArticle());
      const lang = langSelector(getState());
      const topArticles = await API.loadArticles(
        {
          isTop: '1',
          sfForm: 'Article',
        },
        { xAcceptLanguage: lang },
      );

      dispatch(receiveHomeTopArticlesSuccess(topArticles));
    } catch (e) {
      dispatch(receiveHomeTopArticleError(e));
    }
  };
};

const requestNewArticles = () => ({
  type: REQUEST_NEW_ARTICLES,
});

const receiveNewArticlesSuccess = newArticles => ({
  type: RECEIVE_NEW_ARTICLES_SUCCESS,
  payload: {
    newArticles,
  },
});

const receiveNewArticlesError = error => ({
  type: RECEIVE_NEW_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadNewArticles = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestNewArticles());
      const lang = langSelector(getState());
      const newArticles = await API.loadArticles(
        {
          limit: 12,
          sfForm: 'Article',
          isPublished: '1',
          sort: 'created_date',
          sortDir: 'desc',
        },
        { xAcceptLanguage: lang },
      );

      dispatch(receiveNewArticlesSuccess(newArticles));
    } catch (e) {
      dispatch(receiveNewArticlesError(e));
    }
  };
};

const receiveHomeBannerSuccess = banner => ({
  type: RECEIVE_HOME_BANNER_SUCCESS,
  payload: {
    banner,
  },
});
//@todo banner isFetching -> loader
const requestHomeBanner = () => ({
  type: REQUEST_HOME_BANNER,
  payload: {
    isFetching: true,
  },
});

export const loadHomeBanner = () => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());

      dispatch(requestHomeBanner());
      const banners = await API.loadBanners(
        { position: 'HOMEPAGE' },
        { xAcceptLanguage: lang },
      );

      const banner = prop(banners, 'banners');
      if (banner && banner.length > 0) {
        dispatch(receiveHomeBannerSuccess(banner));
      } else {
        dispatch(receiveHomeBannerSuccess(null));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const requestHomeEbooks = () => ({
  type: REQUEST_HOME_EBOOKS,
});

const receiveHomeEbooksSuccess = ebooks => ({
  type: RECEIVE_HOME_EBOOKS_SUCCESS,
  payload: {
    ebooks,
  },
});

const receiveHomeEbooksError = error => ({
  type: RECEIVE_HOME_EBOOKS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeEbooks = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeEbooks());
      const lang = langSelector(getState());
      const ebooks = await APIClass.loadArticles(
        {
          limit: 3,
          sitemapUniqueId: 'EBOOKY',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeEbooksSuccess(ebooks));
    } catch (e) {
      dispatch(receiveHomeEbooksError(e));
    }
  };
};

const requestLandingPageProducts = () => ({
  type: REQUEST_LANDING_PAGE_PRODUCTS,
});

const receiveLandingPageroductsSuccess = products => ({
  type: RECEIVE_LANDING_PAGE_PRODUCTS_SUCCESS,
  payload: {
    landingPageProducts: {
      productsData: products,
      isFetching: false,
    },
  },
});

const receiveLandingPageProductsError = error => ({
  type: RECEIVE_LANDING_PAGE_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadProductList = (listId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const products = landingPageProductsSelector(state);
      const currency = currencySelector(state);

      if (!products || !products.productsData) {
        const lang = langSelector(state);

        dispatch(requestLandingPageProducts());
        const products = await API.loadProductList(
          listId,
          {
            limit: 16,
            offset: 0,
          },
          { xAcceptLanguage: lang, xCurrency: currency },
        );
        if (products && products.products) {
          dispatch(receiveLandingPageroductsSuccess(products));
        } else {
          dispatch(receiveLandingPageroductsSuccess(null));
        }
      }
    } catch (e) {
      console.log({ error: e });
      dispatch(receiveLandingPageProductsError(e));
    }
  };
};
