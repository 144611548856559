import React, { useEffect, useState } from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenter } from 'eshop-defaults';

const Wrapper = styled(FlexRowCenter)<{
  isNearBottom: boolean;
  fromBottom: number;
}>`
  position: fixed;
  z-index: 10;

  cursor: pointer;
  width: ${rem(56)};
  height: ${rem(56)};
  background-color: #509e2f;
  right: ${rem(150)};
  bottom: ${({ isNearBottom, fromBottom }) =>
    isNearBottom ? rem(fromBottom) : rem(25)};

  &:hover {
    opacity: 0.8;
  }

  ${({ theme }) => theme.mediab.l1620`
    right: ${rem(25)};
    bottom: ${({ isNearBottom, fromBottom }) =>
      isNearBottom ? rem(fromBottom) : rem(25)};
  `}

  ${({ theme }) => theme.mediab.l1050`
    right: ${rem(70)};
    bottom: ${({ isNearBottom, fromBottom }) =>
      isNearBottom ? rem(fromBottom) : rem(25)};
  `}

  ${({ theme }) => theme.mediab.m580`
     display: none;
  `}
`;

const Icon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const ToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isNearBottom, setIsNearBottom] = useState(false);
  const [fromBottom, setFromBottom] = useState(25);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    if (window.pageYOffset > 150) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    if (
      document &&
      document.body &&
      document.body.scrollHeight &&
      document.body.scrollHeight - 90 < window.pageYOffset + window.innerHeight
    ) {
      setIsNearBottom(true);
      setFromBottom(
        window.pageYOffset +
          window.innerHeight -
          (document.body.scrollHeight - 140) +
          15,
      );
    } else {
      setIsNearBottom(false);
    }
  };

  return (
    <>
      {isVisible && (
        <Wrapper
          fromBottom={fromBottom}
          isNearBottom={isNearBottom}
          onClick={scrollToTop}
        >
          <Icon alt="up-icon" src="/images/blog_assets/arrow_up.svg" />
        </Wrapper>
      )}
    </>
  );
};

export default ToTopButton;
