import {
  IrequestSubcategoriesTree,
  IreceiveSubcategoriesTreeSuccess,
  SubcategoriesTree,
  IrereceiveSubcategoriesTreeError,
  CMSCategoriesTree,
} from './types';
import { langSelector } from '../App/selectors';
import { arrayToObject } from '../../utilities';

export const namespace = 'CMS';

const requestCMSCategoriesTree = () => ({
  type: 'CMS/REQUEST_CMS_CATEGORIES',
});

const receiveCMSCategoriesTreeSuccess = (sitemap, sitemapObj) => ({
  type: 'CMS/RECEIVE_CMS_CATEGORIES_SUCCESS',
  payload: {
    sitemap,
    sitemapObj,
  },
});

const receiveCMSCategoriesTreeError = error => ({
  type: 'CMS/RECEIVE_CMS_CATEGORIES_ERROR',
  payload: {
    error,
  },
});

export const loadCMSCategoriesTree = () => {
  return async (dispatch, getState, API) => {
    try {
      if (!getState().cms.sitemap) {
        dispatch(requestCMSCategoriesTree());
        const { sitemap_tree } = await API.loadSitemapSubtree(
          'MAGAZIN',
          {},
          { xAcceptLanguage: getState().general.lang },
        );

        const sitemapObj = arrayToObject(sitemap_tree, 'id');
        dispatch(receiveCMSCategoriesTreeSuccess(sitemap_tree, sitemapObj));
      }
    } catch (e) {
      dispatch(receiveCMSCategoriesTreeError(e));
    }
  };
};

const requestCMSArticles = () => ({
  type: 'CMS/REQUEST_CMS_ARTICLES',
});

const receiveCMSArticlesSuccess = articles => ({
  type: 'CMS/RECEIVE_CMS_ARTICLES_SUCCESS',
  payload: {
    articles,
  },
});

const receiveCMSArticlesError = (error: any) => ({
  type: 'CMS/RECEIVE_CMS_ARTICLES_ERROR',
  payload: {
    error,
  },
});

export const loadCMSArticles = (
  sitemapId: number | null = null,
  limit: number = 6,
  offset: number = 0,
) => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      dispatch(requestCMSArticles());
      const paramsObject: any = { limit, offset };
      if (!sitemapId) {
        paramsObject.sitemapUniqueId = 'MAGAZIN';
      } else {
        paramsObject.sitemapId = sitemapId;
      }

      const articles = await API.loadArticles(
        {
          ...paramsObject,
          sfForm: 'Article',
          isPublished: 1,
          sort: 'created_date',
          sortDir: 'desc',
        },
        {
          xAcceptLanguage: lang,
        },
      );

      dispatch(receiveCMSArticlesSuccess(articles));
    } catch (e) {
      dispatch(receiveCMSArticlesError(e));
    }
  };
};

export const submitContactForm = (email, message, name) => {
  return async (dispatch, getState, API) => {
    try {
      (window as any).grecaptcha.ready(function() {
        (window as any).grecaptcha
          .execute(`${process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}`, {
            action: 'submit',
          })
          .then(async function(token) {
            try {
              if (email && message && name && token) {
                await API.contactForm(
                  {},
                  {
                    email: email,
                    message: message,
                    name: name,
                    token: token,
                  },
                  {
                    xAcceptLanguage: langSelector(getState()),
                  },
                );
              }
            } catch (e) {
              console.log('e: ', e);
            }
          });
      });
    } catch (e) {
      console.log(e);
    }
  };
};
