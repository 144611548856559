import React from 'react';
import {
  IconImg,
  FlexRowCenterVertical,
  FlexCol,
  TextP,
  SvgIcon,
  GolfcentrumSvgIcon,
  GolfcentrumIconType,
  MiniCart,
  FlexRowCenter,
} from 'eshop-defaults';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import { __, __r } from 'react-i18n';
import { Link } from 'react-router';
import { cartIndexRoute } from '../../utilities/cart';
import OutsideClick from '../_helpers/Default/OutsideClick';
import API, { ThenArg } from '../../services/API';
import { WithRouterProps, withRouter } from 'react-router';
import { setWasAddedToCartModalVisibility } from '../../containers/Cart/cartSlice';
import { prop } from '../../utilities';

interface Props {
  totalPrice: number;
  currency: string;
  dispatch: any;
  cartData: ThenArg<typeof API.getCart>;
}
function HeaderCart({
  totalPrice,
  currency,
  cartData,
  dispatch,
  router,
}: Props & WithRouterProps) {
  const [isCartVisible, setIsCartVisible] = React.useState(false);
  const toggleCart = () => {
    setIsCartVisible(!isCartVisible);
  };

  const handleToCartButtonClick = () => {
    dispatch(setWasAddedToCartModalVisibility());
    router.push(cartIndexRoute() as string);
  };

  const closeMiniCart = () => {
    setIsCartVisible(false);
  };
  return (
    <OutsideClick handleFunction={closeMiniCart}>
      <CartLink onClick={toggleCart}>
        <GolfcentrumSvgIcon
          icon={GolfcentrumIconType.cart}
          alt="Prejsť na košík"
          cursor={'pointer'}
          width={32}
          height={32}
        />
        <NumOfProducts>{prop(cartData, 'items', []).length}</NumOfProducts>
        <NameLinkWrapper>
          <TotalPrice>{`${
            totalPrice
              ? totalPrice.toFixed(currency && currency === 'CZK' ? 0 : 2)
              : 0
          } ${currency || 'EUR'}`}</TotalPrice>
        </NameLinkWrapper>
        {isCartVisible && (
          <MiniCartWrapper>
            <MiniCart
              cartData={cartData}
              handleToCartButtonClick={handleToCartButtonClick}
            />
          </MiniCartWrapper>
        )}
      </CartLink>
    </OutsideClick>
  );
}

const MiniCartWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  position: absolute;
  top: ${rem(72)};
  right: 0;
  z-index: 99;
`;

const CartLink = styled.div`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(20)};
  text-decoration: none;
  color: ${({ theme }) => theme.color.textPrimary};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${rem(48)};
  transition: color 0.2s ease;
  position: relative;
`;

const NumOfProducts = styled(FlexRowCenter)`
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.font.tertiary};
  margin-bottom: ${rem(16)};
  background-color: rgba(80, 158, 47, 1);
  color: white;
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: 50%;
`;

const NameLinkWrapper = styled(FlexCol)`
  margin-left: ${rem(12)};

  ${({ theme }) => theme.mediab.l1050`
    display: none;
  `}
`;

const TotalPrice = styled(TextP)`
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(20)};
  white-space: nowrap;
`;

export default withRouter(HeaderCart);
