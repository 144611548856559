import React, { useState } from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  FlexRow,
  FlexRowCenterVertical,
  GolfCentrumGeneralInput,
} from 'eshop-defaults';
import { Link } from 'react-router';
import { PrimaryButton } from '../_helpers/Default/Buttons';
import { PrimaryInput } from '../_helpers/Default/Inputs';
import { Radio as RadioInput } from '../_helpers/forms/FormStyles';
import API from '../../services/API';
import { Loader } from '../_helpers/Loader/Loader';
import { getOtherTextInfo } from 'eshop-defaults/lib/utilities/selectors';

interface Props {
  otherText: any;
}

const Wrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.gray08};
  color: ${({ theme }) => theme.color.textPrimary};
  justify-content: center;
  padding: ${rem(54)} ${rem(0)};

  @media only screen and (max-width: 1200px) {
    height: initial;
  }

  ${({ theme }) => theme.mediab.l925`
    padding: ${rem(32)} 0 0;
    flex-direction: column;
    text-align: center;
  `};
`;

const Left = styled(FlexCol)`
  margin-right: ${rem(32)};
  max-width: ${rem(416)};

  ${({ theme }) => theme.mediab.l925`
    margin-right: 0;
    max-width: 100%;
  `};
`;

const Right = styled(FlexCol)`
  margin-left: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    margin-right: 0;
    margin-left: 0;
    max-width: 100%;
    align-items: center;
  `};
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: ${rem(8)};
  font-family: ${({ theme }) => theme.font.secondary};
  color: ${({ theme }) => theme.color.textPrimary};
  font-size: ${rem(30)};
  width: 100%;
  font-weight: 500;
  line-height: ${rem(34)};

  ${({ theme }) => theme.mediab.l925`
    margin-bottom: ${rem(16)};
  `};
`;

const HelpText = styled.p`
  margin: 0;
  font-size: ${rem(12)};
  line-height: ${rem(18)};

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `};
`;

const HelpText2 = styled(HelpText)`
  display: none;
  ${({ theme }) => theme.mediab.l925`
    display: block;
  `};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.textPrimary};
`;

const SuccessfulWrapper = styled(FlexRowCenterVertical)``;

const SuccessfulText = styled.p`
  font-size: ${rem(12)};
  line-height: ${rem(18)};
`;

const SuccessfulImage = styled.img`
  margin-left: ${rem(8)};
`;

const FormWrapper = styled.form`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: ${rem(24)};
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    width: ${rem(324)};
    align-items: center;
 `};

  ${({ theme }) => theme.mediab.m580`
    width: 100%;
 `};
`;

const EmailInput = styled(PrimaryInput)`
  margin-right: ${rem(8)};
  width: ${rem(324)};

  ${({ theme }) => theme.mediab.l925`
    width: 100%;
  `};
`;

const SubscribeButton = styled(PrimaryButton)`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  height: ${rem(48)};
  font-size: ${rem(14)};
  padding-left: ${rem(32)};
  padding-right: ${rem(32)};
  font-weight: 400;

  ${({ theme }) => theme.mediab.l925`
   width: 100%;
`};
`;

const BottomText = styled.div`
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  width: 80%;
  color: ${({ theme }) => theme.blogColors.gray};
  line-height: 24px;

  ${({ theme }) => theme.mediab.l925`
    width: 100%;
  `};
`;

const BottomTextLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.blogColors.gray} !important;
  line-height: 24px;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledInput = styled(GolfCentrumGeneralInput)`
  padding: ${rem(16)} ${rem(16)} ${rem(16)} ${rem(20)};
  width: ${rem(324)};
  max-width: none;

  ${({ theme }) => theme.media('sm')} {
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  ${({ theme }) => theme.mediab.l925`
    width: 100%;
    margin-bottom: ${rem(8)};
  `};
`;

const StyledRow = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(24)};
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediab.l925`
    justify-content: center;
  `};
`;

const StyledRow2Col = styled(StyledRow)`
  ${({ theme }) => theme.mediab.l925`
      flex-direction: column;
  `};
`;

const StyledRadioInput = styled(RadioInput)`
  margin-right: ${rem(24)};
`;

const NewsletterForm = (props: Props) => {
  const { otherText } = props;
  const { NEWSLETTER_BLOCK: newsLetterBlock } = otherText;
  const {
    name: newsLetterBlockName,
    description: newsLetterBlockDescription,
  } = getOtherTextInfo(newsLetterBlock);

  const [email, setEmail] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const emailIsValid = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && emailRegex.test(email.toLowerCase());
  };

  const submitNewsletter = async e => {
    e.preventDefault();
    setIsFetching(true);

    const subscribe = await API.subscribeNewsletter(
      { engine: 'mailchimp' },
      {
        email,
        first_name: '',
        last_name: '',
        source: '',
        status: '',
      },
    );

    setIsFetching(false);
    if (subscribe && email) {
      setIsSubscribed(true);
    }
  };

  return (
    <Wrapper>
      <Left>
        {newsLetterBlockName && newsLetterBlockName.length && (
          <Title>{newsLetterBlockName}</Title>
        )}
        {newsLetterBlockDescription && newsLetterBlockDescription.length && (
          <HelpText
            dangerouslySetInnerHTML={{ __html: newsLetterBlockDescription }}
          ></HelpText>
        )}
      </Left>
      <Right>
        {isSubscribed ? (
          <SuccessfulWrapper>
            <SuccessfulText>
              {__('Prihlásenie na odber noviniek bolo úspešné.')}
            </SuccessfulText>
            <SuccessfulImage src="/images/done.svg" alt="" />
          </SuccessfulWrapper>
        ) : (
          <>
            <FormWrapper>
              <StyledRow2Col>
                <StyledInput
                  // tslint:disable-next-line:jsx-no-lambda
                  onChange={e => setEmail(e.target.value)}
                  type="email"
                  placeholder={__('Zadajte e-mailovú adresu')}
                />
                <SubscribeButton
                  disabled={!emailIsValid()}
                  type="submit"
                  onClick={submitNewsletter}
                >
                  {isFetching ? (
                    <Loader small={true} margin={'0'} />
                  ) : (
                    __('Odoberať')
                  )}
                </SubscribeButton>
              </StyledRow2Col>
              <StyledRow>
                <RadioInput
                  marginRight={rem(16)}
                  name={'gender'}
                  id={'gender1'}
                  label={__('Muž')}
                />
                <RadioInput name={'gender'} id={'gender2'} label={__('Žena')} />
              </StyledRow>
            </FormWrapper>
            <HelpText2>
              {__(
                'Vyplnením emailu a jeho odoslaním dávam súhlas spoločnosti Golf Centrum s.r.o. na zasielanie bezplatného newslettera na mnou zadaný e-mail. Prečítal(a) som si o svojich právach pri ',
              )}
              <StyledLink to={'/'}>
                {__('spracúvaní osobných údajov.')}
              </StyledLink>
            </HelpText2>
          </>
        )}
      </Right>
    </Wrapper>
  );
};

export { NewsletterForm };
