import { resolveBaseApiUrlByLang } from '../configureTrans';
import API, { ThenArg } from '../services/API';
import { getImagePath } from './product';

export const getAddressesFromOrder = order => {
  const addresses: any = [];

  // fakturacna adresa
  const { address, company, delivery } = order;
  const billingAddress = {
    isBillingAddress: true,
    company: company.name || address.company,
    name: `${address.person.name} ${address.person.surname}`,
    street: `${address.street} ${address.street_number}`,
    zip: address.zip,
    city: address.city,
    country:
      address.country.toLowerCase() === 'slovensko' ? '' : address.country, // ak je krajina Slovensko, tak ju nezobrazujeme
    phone: address.person.phone,
    email: address.person.email,
    ico: company.ico,
    dic: company.dic,
    icDph: company.ic_dph,
  };
  addresses.push(billingAddress);

  if (order.use_delivery_address) {
    // adresa dorucenia
    const deliveryAddress = {
      isBillingAddress: false,
      company: delivery.delivery_firm,
      name: `${delivery.person.name} ${delivery.person.surname}`,
      street: `${delivery.delivery_addr_street} ${delivery.delivery_addr_street_number}`,
      zip: delivery.delivery_addr_zip,
      city: delivery.delivery_addr_city,
      country:
        delivery.delivery_addr_country === 'slovensko'
          ? ''
          : delivery.delivery_addr_country, // ak je krajina Slovensko, tak ju nezobrazujeme
      phone: delivery.person.phone,
      email: delivery.person.email,
      ico: company.ico,
      dic: company.dic,
      icDph: company.ic_dph,
    };
    addresses.push(deliveryAddress);
  }

  return addresses;
};

export const getPaymentUrl = (
  paymentType: string,
  orderPublicId: string,
  lang = 'sk',
) => {
  const appApiBaseUrl = resolveBaseApiUrlByLang(lang);

  if (isBesteron(paymentType)) {
    return `${appApiBaseUrl}/payment/besteron/${orderPublicId}`;
  }

  switch (paymentType) {
    case 'CARDPAY':
      return `${appApiBaseUrl}/payment/cardpay-next/${orderPublicId}`;
    default:
      return null;
  }
};

export const getOrderItemImage = images => {
  const mainImg: any = Object.values(images).find((img: any) => img.primary);
  let url = '';
  if (mainImg) {
    url = getImagePath(mainImg.filename);
  }
  return url;
};

export const shouldRedirect = (
  order: ThenArg<typeof API.getOrder>,
): boolean => {
  if (!order.payment) {
    return false;
  }

  if (
    window &&
    window.location &&
    window.location.href &&
    (isPaid() || isError())
  ) {
    return false;
  }

  if (
    !isBesteron(order.payment.payment_type) &&
    order.payment.payment_type !== 'CARDPAY'
  ) {
    return false;
  }

  if (order.error_message || order.order_paid_datetime !== null) {
    return false;
  }

  return true;
};

export const isBesteron = (payment_type: string): boolean => {
  return /^BESTERON/.test(payment_type);
};

// export const addImages = (
//   delAndPayInfoById: Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>>,
// ): Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>> => {
//   if (!delAndPayInfoById) {
//     return delAndPayInfoById;
//   }

//   return Object.entries(delAndPayInfoById).map(item => {
//     item.payments.forEach(payment => {
//       console.log(payment.payment_id);
//       if (payment.payment_id === 'CARD_PAY') {
//       } else if (isBesteron(payment.payment_id)) {
//         payment['icons'] = [
//           '/images/cart/payments/besteron/postova.svg',
//           '/images/cart/payments/besteron/slovenska.svg',
//           '/images/cart/payments/besteron/tatra.svg',
//           '/images/cart/payments/besteron/unicredit.svg',
//           '/images/cart/payments/besteron/viamo.svg',
//           '/images/cart/payments/besteron/vub.svg',
//         ];
//       }
//     });
//   });
// };

export const isError = (): boolean => {
  if (!window || !window.location || !window.location.href) {
    return false;
  }
  const url = new URL(window.location.href);

  return url.searchParams.get('error') !== null;
};

export const isPaid = (): boolean => {
  if (!window || !window.location || !window.location.href) {
    return false;
  }
  const url = new URL(window.location.href);

  return url.searchParams.get('paid') !== null;
};
