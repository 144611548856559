/* eslint-disable */
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinLoaderWrapper,
  FlexRow,
  CategoryHeader,
  FlexRowCenterVertical,
  ViewSwitcher,
  ProductList,
  ProductTableList,
  GolfcentrumPagination as Pagination,
  CategoryLink,
  GolfcentrumFilterType as FilterType,
  ZemplinResetFilter as ResetFilter,
  GolfcentrumCategoryFilter as CategoryFilter,
  GolfcentrumFilterBackButton as FilterBackButton,
  ZemplinMobileCategoryFilterButtons as MobileCategoryFilterButtons,
  MobileNavMenu,
  ZemplinMobileFilterMenu as MobileFilterMenu,
  GolfcentrumLinksFilter as LinksFilter,
  ZemplinActiveFilters as ActiveFilters,
  ZemplinCategoryTopFilters as CategoryTopFilters,
  GolfcentrumCategoryFilterCheckboxes as CategoryFilterCheckboxes,
  ZemplinCategoryTableList as CategoryTableList,
  GolfcentrumCategoryProductList as CategoryProductList,
  NoItemsWrapper,
  GolfcentrumCategoryProductsOnPage as CategoryProductsOnPage,
  FlexColCenterHorizontal,
  GolfcentrumLoadMoreProductsBtn as LoadMoreProductsBtn,
  GolfcentrumSvgIcon,
  GolfcentrumIconType,
} from 'eshop-defaults';
import { push } from 'react-router-redux';
import { prop, arrayToObject } from '../../utilities';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { keyframes } from '../../theme/styled-components';
import { useLocalStorage } from '../../utilities/hooks';
import { getCategoryMetaTags } from '../../utilities/metatags';
import {
  addItemToCart,
  setAddToCartModalVisibility,
  setWasAddedToCartModalVisibility,
} from '../../containers/Cart/cartSlice';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { withRouter, WithRouterProps } from 'react-router';
import {
  urlAttribsToObject,
  attribsObjectToUrl,
  loadMoreCategoryProducts,
} from '../../containers/Category/categorySlice';
import { getImagePath, resolveAttribName } from '../../utilities/product';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';
import { IconType } from 'eshop-defaults/lib/components/Golfcentrum/General/SvgIcon';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { Helmet } from 'react-helmet';
import ToTopButton from '../_helpers/Default/ToTopButton';
import { resolveCurrencySignFromLang } from '../../configureTrans';
import { Button } from '../_helpers/Default/CartDefaults';
import { resolveCategoryUrlString } from '../../utilities/category';

export interface Props {
  dispatch: any;
  isFetching: boolean;
  isFetchingMore: boolean;
  // products: ThenArg<typeof API.loadElasticCategoryProducts>;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  catName: string;
  childrenCategories: any[];
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  saleData: any;
  lang: any;
}

function Category({
  isFetching,
  isFetchingMore,
  products,
  data,
  dispatch,
  user,
  catName,
  filterData,
  location,
  router,
  childrenCategories,
  saleData,
  lang,
}: Props & WithRouterProps) {
  const catUrl = prop(location, 'pathname');
  const query = prop(location, 'search');
  const urlAttribs = prop(location, 'query');
  const urlAttribsObj = urlAttribsToObject(urlAttribs);

  const currentCategory = prop(data, 'category_id', catName);

  const [currentView, setCurrentView] = useLocalStorage('product-view', 'card');
  const [isCategoryFilterVisible, setIsCategoryFilterVisible] = React.useState(
    false,
  );
  const [isFilterMenuVisible, setIsFilterMenuVisible] = React.useState(false);
  const parentCategories = prop(data, 'parent_categories', null);
  const previousCategory = parentCategories
    ? parentCategories[parentCategories.length - 1]
    : null;

  const seoshow = catUrl.includes('vypredaj') || prop(data, 'seoshow') || false;
  const currency = resolveCurrencySignFromLang(lang);
  const categoryLinks = childrenCategories
    // .filter(c => c.counts && c.counts > 0) // docasne zrusime, elastic vracia stale null
    .map(c => ({
      name: prop(c, 'publish.0.category_name') || prop(c, 'category_name'),
      category_name:
        prop(c, 'publish.0.category_name') || prop(c, 'category_name'),
      elastic_url: `/` + (prop(c, 'publish.0.url') || prop(c, 'url')),
      url: `/` + resolveCategoryUrlString(c),
      image: c.image,
      counts: c.counts,
      weburl: `/` + resolveCategoryUrlString(c),
    }));
  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.CATEGORY, data));
  }, [data, dispatch]);

  const toggleCategoryFilterMenu = () => {
    document.body.style.overflow = isCategoryFilterVisible
      ? 'visible'
      : 'hidden';
    setIsCategoryFilterVisible(!isCategoryFilterVisible);
  };

  const closeCategoryFilterMenu = () => {
    document.body.style.overflow = 'visible';
    setIsCategoryFilterVisible(false);
  };

  const toggleFilterMenu = () => {
    document.body.style.overflow = isFilterMenuVisible ? 'visible' : 'hidden';
    setIsFilterMenuVisible(!isFilterMenuVisible);
  };

  const [displayUrlAttribsObj, setDisplayUrlAttribsObj] = React.useState(
    urlAttribsObj,
  );
  const [tempUrlAttribsObj, setTempUrlAttribsObj] = React.useState(
    urlAttribsObj,
  );

  const handleFilterChange = (
    type: FilterType,
    newData:
      | { min: number; max: number; attribId?: string }
      | {
          attribId: string;
          valueId: string;
          newValue: boolean;
          sameIds?: string[];
        }
      | { name: string; value: string; checked: boolean },
  ) => {
    let tempUrlAttribsObjCopy = { ...tempUrlAttribsObj };
    let displayUrlAttribsObjCopy = { ...displayUrlAttribsObj };
    switch (type) {
      case FilterType.RANGE: {
        const attribId = prop(newData, 'attribId');
        if (attribId) {
          const currentRangeAttribFilters = prop(
            tempUrlAttribsObjCopy,
            'rangeAttribs',
          );
          const displayCurrentRangeAttribFilters = prop(
            displayUrlAttribsObjCopy,
            'rangeAttribs',
          );
          const min = parseInt(prop(newData, 'min').toFixed(0));
          const max = parseInt(prop(newData, 'max').toFixed(0));
          const { attribs } = filterData;
          const attribDataMin = prop(attribs[attribId], 'attrib_data.min');
          const attribDataMax = prop(attribs[attribId], 'attrib_data.max');

          let rangeAttribsObj = currentRangeAttribFilters
            ? currentRangeAttribFilters
            : {};
          let displayRangeAttribsObj = displayCurrentRangeAttribFilters
            ? displayCurrentRangeAttribFilters
            : {};

          if (
            Math.floor(attribDataMin) !== min ||
            Math.ceil(attribDataMax) !== max
          ) {
            rangeAttribsObj[attribId] = { min: min, max: max };
            displayRangeAttribsObj[attribId] = { min: min, max: max };
          } else {
            delete rangeAttribsObj[attribId];
            delete displayRangeAttribsObj[attribId];
          }

          tempUrlAttribsObjCopy.rangeAttribs = rangeAttribsObj;
          displayUrlAttribsObjCopy.rangeAttribs = displayRangeAttribsObj;
        } else {
          tempUrlAttribsObjCopy.min = parseInt(prop(newData, 'min').toFixed(0));
          tempUrlAttribsObjCopy.max = parseInt(prop(newData, 'max').toFixed(0));
          displayUrlAttribsObjCopy.min = parseInt(
            prop(newData, 'min').toFixed(0),
          );
          displayUrlAttribsObjCopy.max = parseInt(
            prop(newData, 'max').toFixed(0),
          );
        }
        setTempUrlAttribsObj(tempUrlAttribsObjCopy);
        setDisplayUrlAttribsObj(displayUrlAttribsObjCopy);
        break;
      }

      case FilterType.OPTIONS: {
        const attribId = prop(newData, 'attribId');
        const valueId = prop(newData, 'valueId');
        const newValue = prop(newData, 'newValue');
        const activeFilterComponenet = prop(
          newData,
          'activeFilterComponent',
          false,
        );

        let updatedAttribsObj = prop(tempUrlAttribsObjCopy, 'attribs');
        let displayUpdatedAttribsObj = prop(
          displayUrlAttribsObjCopy,
          'attribs',
        );

        if (!updatedAttribsObj) updatedAttribsObj = {};
        if (!displayUpdatedAttribsObj) displayUpdatedAttribsObj = {};

        if (newValue) {
          if (updatedAttribsObj[attribId]) {
            updatedAttribsObj[attribId].values.push(valueId);
            displayUpdatedAttribsObj[attribId].values.push(valueId);
          } else {
            updatedAttribsObj = {
              ...updatedAttribsObj,
              [attribId]: { values: [valueId] },
            };
            displayUpdatedAttribsObj = {
              ...displayUpdatedAttribsObj,
              [attribId]: { values: [valueId] },
            };
          }
        } else {
          updatedAttribsObj[attribId].values = updatedAttribsObj[
            attribId
          ].values.filter(id => id !== valueId);
          displayUpdatedAttribsObj[attribId].values = displayUpdatedAttribsObj[
            attribId
          ].values.filter(id => id !== valueId);

          if (
            updatedAttribsObj[attribId] &&
            updatedAttribsObj[attribId].values.length === 0
          ) {
            delete updatedAttribsObj[attribId];
          }
          if (
            displayUpdatedAttribsObj[attribId] &&
            displayUpdatedAttribsObj[attribId].values.length === 0
          ) {
            delete displayUpdatedAttribsObj[attribId];
          }
        }

        tempUrlAttribsObjCopy.attribs = updatedAttribsObj;
        displayUrlAttribsObjCopy.attribs = displayUpdatedAttribsObj;

        setTempUrlAttribsObj(tempUrlAttribsObjCopy);
        setDisplayUrlAttribsObj(displayUrlAttribsObjCopy);
        if (activeFilterComponenet) {
          applyFilters();
        }
        break;
      }
      case FilterType.SALE: {
        const { name, value, checked } = newData as {
          name: string;
          value: string;
          checked: boolean;
        };

        if (checked) {
          tempUrlAttribsObjCopy['saleFilter'] = value;
          displayUrlAttribsObjCopy['saleFilter'] = value;
        } else {
          delete tempUrlAttribsObjCopy['saleFilter'];
          delete displayUrlAttribsObjCopy['saleFilter'];
        }
        setTempUrlAttribsObj(tempUrlAttribsObjCopy);
        setDisplayUrlAttribsObj(displayUrlAttribsObjCopy);

        break;
      }
      default:
        break;
    }
  };

  const applyFilters = () => {
    router.push(attribsObjectToUrl(catUrl, { ...tempUrlAttribsObj }));
  };

  const activeFilter =
    prop(displayUrlAttribsObj, 'attribs') ||
    prop(displayUrlAttribsObj, 'rangeAttribs') ||
    prop(displayUrlAttribsObj, 'saleFilter') ||
    prop(displayUrlAttribsObj, 'otherAttribs') ||
    prop(displayUrlAttribsObj, 'min') ||
    prop(displayUrlAttribsObj, 'max');

  React.useEffect(() => {
    setTempUrlAttribsObj({});
    setDisplayUrlAttribsObj({});
  }, [catUrl]);

  const deleteFilters = () => {
    setTempUrlAttribsObj({});
    setDisplayUrlAttribsObj({});
    router.push(catUrl);
  };

  const handleSortChange = e => {
    const value = e.target.value;
    const [sort, sortDir] = value.split('_');
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        sort,
        sortDir: sortDir ? sortDir : null,
      }),
    );
  };

  const handleCheckboxChange = ({ name, value }) => {
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          [name]: value ? 1 : 0,
        },
      }),
    );
  };

  const handleProductsOnPage = e => {
    const value = e.target.value;
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          displayProducts: value,
        },
      }),
    );
  };

  const loadMoreProducts = () => {
    dispatch(loadMoreCategoryProducts(currentCategory, urlAttribsObj));
  };

  if (isFetching || !products) {
    return <ZemplinLoaderWrapper height={200} />;
  }

  const { limit, offset, total, products: productsArray } = products;
  const { minPrice, maxPrice, attribs } = filterData;

  let fullLoadedProducts = false;
  const diff = total - offset;
  if (diff <= limit) {
    fullLoadedProducts = true;
  } else {
    fullLoadedProducts = false;
  }

  // Ak mmáme viac ako jeden rovnaký filter, vypneme filtre. Je to kvôli skrytím kategoriam.
  const hasDuplicates = array => {
    const valuesSoFar = Object.create(null);
    for (let i = 0; i < array.length; ++i) {
      if (array[i] in valuesSoFar) return true;
      valuesSoFar[array[i]] = true;
    }
    return false;
  };
  const attribNames = Object.keys(attribs)
    .sort(
      (a, b) =>
        prop(attribs[a], 'attrib_data.attrib_sort') -
        prop(attribs[b], 'attrib_data.attrib_sort'),
    )
    .map(key => prop(attribs[key], 'attrib_data.attrib_name', false))
    .filter(Boolean);

  const duplicatedAttribs = hasDuplicates(attribNames);

  const renderCustomAttribs = () => {
    let keys = Object.keys(attribs);
    keys.sort(function(a, b) {
      return (
        prop(attribs[a], 'attrib_data.attrib_sort') -
        prop(attribs[b], 'attrib_data.attrib_sort')
      );
    });

    return (
      <>
        {attribs &&
          keys.map(key => {
            const attrib = attribs[key];

            if (!attrib.attrib_data) {
              return null;
            }

            const attribType = prop(attrib, 'attrib_data.attrib_type');
            const attribUnit = prop(attrib, 'attrib_data.attrib_unit');
            const attribName = prop(attrib, 'attrib_data.attrib_name');

            if (
              data.category_name.toLowerCase().includes('vozík') &&
              attribName.includes('Farba')
            ) {
              return null;
            }

            const valuesObj = attribs[key].values;
            let valueKeys = Object.keys(valuesObj);
            valueKeys.sort(function(a, b) {
              return (
                prop(valuesObj[a], 'info.value_sort') -
                prop(valuesObj[b], 'info.value_sort')
              );
            });
            const filteredAttribs = {};
            const attribKeys: any[] = [];
            valueKeys.map(key => {
              const value = prop(valuesObj[key], 'info.attrib_value');
              if (!value || value === '' || value === '-') {
              } else {
                filteredAttribs[key] = valuesObj[key];
              }

              const attribId = prop(valuesObj[key], 'info.attrib_id');
              if (!attribKeys.includes(attribId)) {
                attribKeys.push(attribId);
              }
            });

            const hasValues = Object.keys(filteredAttribs).length > 0;
            if (hasValues) {
              const showFilter = Object.keys(filteredAttribs).findIndex(
                valueKey => filteredAttribs[valueKey].doc_count == 0, //@todo docasne elastic vracia doc_count 0
              );

              const activeValues: any[] = [];
              attribKeys.map(attrId => {
                activeValues.push(
                  ...prop(
                    prop(displayUrlAttribsObj, 'attribs', {})[attrId],
                    'values',
                    [],
                  ),
                );
              });
              const rangeActiveValues = {};
              attribKeys.map(attrId => {
                const value = prop(displayUrlAttribsObj, 'rangeAttribs', {})[
                  attrId
                ];
                if (value) {
                  rangeActiveValues[attrId] = value;
                }
              });
              const rangeActive =
                rangeActiveValues[prop(attrib, 'attrib_data.attrib_id')];
              const hasActive = rangeActive || activeValues.length > 0;
              if (showFilter !== -1 || hasActive) {
                if (attribType === 'rozsah') {
                  return (
                    <CategoryFilter
                      key={key}
                      name={resolveAttribName(attrib.attrib_data)}
                      type={FilterType.RANGE}
                      filterData={{
                        activeMin: prop(
                          rangeActive,
                          'min',
                          prop(attrib, 'attrib_data.min', 0),
                        ),
                        activeMax: prop(
                          rangeActive,
                          'max',
                          prop(attrib, 'attrib_data.max', 0),
                        ),
                        min: +prop(attrib, 'attrib_data.min'),
                        max: +prop(attrib, 'attrib_data.max'),
                        attribId: prop(attrib, 'attrib_data.attrib_id'),
                      }}
                      handleFilterChange={handleFilterChange}
                      activeValues={rangeActiveValues}
                      initialIsOpen={rangeActive}
                      unit={attribUnit}
                    />
                  );
                }
                return (
                  <CategoryFilter
                    key={key}
                    name={resolveAttribName(attrib.attrib_data)}
                    type={FilterType.OPTIONS}
                    filterData={{
                      values: prop(attrib, 'values'),
                      attribId: key,
                    }}
                    handleFilterChange={handleFilterChange}
                    activeValues={activeValues}
                    initialIsOpen={hasActive}
                  />
                );
              }
            }
            return null;
          })}
      </>
    );
  };

  const renderLeftSide = () => {
    return (
      <LeftSideWrapper>
        {previousCategory && (
          <FilterBackButton
            name={
              prop(previousCategory, 'publish.0.category_name') ||
              prop(previousCategory, 'category_name')
            }
            url={
              prop(previousCategory, 'publish.0.redirect_url') ||
              prop(previousCategory, 'publish.0.url') ||
              prop(previousCategory, 'weburl') ||
              prop(previousCategory, 'url')
            }
          />
        )}
        {categoryLinks && categoryLinks.length > 0 && (
          <CategoryFilter
            name={__('Kategórie')}
            type={FilterType.LINKS}
            initialIsOpen={true}
            filterData={{ links: categoryLinks }}
            handleFilterChange={handleFilterChange}
          />
        )}
        <CategoryFilter
          name={__('Cena')}
          type={FilterType.RANGE}
          initialIsOpen={true}
          filterData={{
            min: minPrice,
            max: maxPrice,
            activeMin: prop(urlAttribs, 'min'),
            activeMax: prop(urlAttribs, 'max'),
          }}
          handleFilterChange={handleFilterChange}
          unit={currency}
        />
        <CategoryFilter
          name={__('Zľava')}
          type={FilterType.SALE}
          initialIsOpen={true}
          handleFilterChange={handleFilterChange}
          unit={currency}
          filterData={{
            values: [
              {
                name: __('Do 20%'),
                value: 'f1-t20',
              },
              {
                name: __('Nad 20%'),
                value: 'f21-t49',
              },
              {
                name: __('Nad 50%'),
                value: 'f49',
              },
            ],
            selected: prop(displayUrlAttribsObj, 'saleFilter'),
          }}
        />
        {!duplicatedAttribs && renderCustomAttribs()}
        {activeFilter && (
          <>
            <FilterNowButton onClick={applyFilters}>
              {__('Filtrovať')}
            </FilterNowButton>
            <ResetFilter onClick={deleteFilters} name={'Resetovať'} />
          </>
        )}
      </LeftSideWrapper>
    );
  };

  const renderCategoriesFilter = () => {
    return (
      <MobileCategoryFilter>
        {categoryLinks && categoryLinks.length > 0 ? (
          <LinksFilter
            onClick={closeCategoryFilterMenu}
            isOpen={true}
            isMobile={true}
            filterData={{ links: categoryLinks }}
          />
        ) : (
          <NoItemsWrapper
            height={100}
            text={__('Nenašli sa žiadne ďalšie podkategórie')}
          />
        )}
      </MobileCategoryFilter>
    );
  };

  const renderFilterCheckboxes = () => {
    return (
      <CategoryFilterCheckboxes
        filters={prop(urlAttribsObj, 'otherAttribs')}
        handleCheckboxChange={handleCheckboxChange}
        seoshow={seoshow}
      />
    );
  };

  const renderOtherFilters = () => {
    return (
      <MobileCategoryFilter>
        {renderFilterCheckboxes()}
        <CategoryFilter
          name={__('Cena')}
          type={FilterType.RANGE}
          initialIsOpen={false}
          filterData={{
            min: minPrice,
            max: maxPrice,
            activeMin: prop(urlAttribs, 'min')
              ? prop(urlAttribs, 'min')
              : minPrice,
            activeMax: prop(urlAttribs, 'max')
              ? prop(urlAttribs, 'max')
              : maxPrice,
          }}
          handleFilterChange={handleFilterChange}
          unit={currency}
        />
        <CategoryFilter
          name={__('Zľava')}
          type={FilterType.SALE}
          initialIsOpen={true}
          handleFilterChange={handleFilterChange}
          unit={currency}
          filterData={{
            values: [
              {
                name: __('Do 20%'),
                value: 'f1-t20',
              },
              {
                name: __('Nad 20%'),
                value: 'f21-t49',
              },
              {
                name: __('Nad 50%'),
                value: 'f49',
              },
            ],
            selected: prop(displayUrlAttribsObj, 'saleFilter'),
          }}
        />
        {!duplicatedAttribs && renderCustomAttribs()}
        {activeFilter && (
          <>
            <FilterNowButton
              onClick={() => {
                applyFilters();
                toggleFilterMenu();
              }}
              mobile={true}
            >
              {__('Filtrovať')}
            </FilterNowButton>
            <ResetFilter
              onClick={() => {
                deleteFilters();
                toggleFilterMenu();
              }}
              name={'Resetovať'}
            />
          </>
        )}
      </MobileCategoryFilter>
    );
  };

  const renderCategoryLinks = () => {
    if (categoryLinks.length === 0) {
      return null;
    }
    return (
      <SubCategoriesWrapper>
        {categoryLinks &&
          categoryLinks.map((category, i) => {
            if (
              (!category.counts || category.counts === 0) &&
              !category.weburl
            ) {
              return null;
            }
            return (
              <CategoryLink key={i} category={category} maxWidth={'30%'} />
            );
          })}
      </SubCategoriesWrapper>
    );
  };

  const renderRightSide = () => {
    const isCardView = currentView === 'card';
    const categoryName = prop(data, 'category_name', catName);
    const categoryDesc = prop(data, 'category_descr', '') || '';

    const sort = prop(urlAttribs, 'sort');
    const sortDir = prop(urlAttribs, 'sortDir');

    const sortValue = sort && sortDir ? `${sort}_${sortDir}` : 'none';

    const numberOfProducts = prop(urlAttribs, 'displayProducts');
    const numberOfProductsVal = numberOfProducts ? numberOfProducts : '18';

    const activeAttribs = JSON.parse(
      JSON.stringify(prop(urlAttribsObj, 'attribs', {})),
    );

    Object.keys(activeAttribs).map(key => {
      const activeValues = activeAttribs[key].values;
      if (attribs[key]) {
        const valuesObj = attribs[key].values;
        const newValues: any[] = [];
        activeValues.map(value => {
          newValues.push({
            valueId: value,
            name:
              prop(valuesObj, `${value}.info.translations.0.attrib_value`) ||
              prop(valuesObj, `${value}.info.attrib_value`, value),
            attribName:
              prop(attribs, `${key}.attrib_data.translations.0.attrib_name`) ||
              prop(attribs, `${key}.attrib_data.attrib_name`),
          });
        });
        activeAttribs[key].values = newValues;
      }
    });

    const linkData = prop(data, 'blog_link')
      ? { ...data, weburl: prop(data, 'blog_link') }
      : null;
    return (
      <RightSideWrapper>
        {saleData &&
          saleData.map(sale => (
            <PromotionWrapper>
              <GolfcentrumSvgIconStyled
                icon={GolfcentrumIconType.promotion}
                width={50}
                height={50}
                alt={'List icon'}
                viewBox="0 -14 512.00001 512"
                marginRight={16}
                fill={'#26B25E'}
              />
              <PromotionText>{sale.name}</PromotionText>
            </PromotionWrapper>
          ))}

        {data && <CategoryHeader category={data} categoryLink={linkData} />}
        {renderCategoryLinks()}
        {isFetching ? (
          <ZemplinLoaderWrapper height={500} />
        ) : !productsArray ? null : (
          <>
            <SortWrapper>
              <CategoryTopFilters
                handleChange={handleSortChange}
                sortValue={sortValue}
              >
                <DesktopCheckboxesWrapper>
                  {renderFilterCheckboxes()}
                </DesktopCheckboxesWrapper>
              </CategoryTopFilters>

              <CategoryProductsOnPage
                handleChange={handleProductsOnPage}
                displayNumber={numberOfProductsVal}
                text={__('Produktov na stránke:')}
              />

              {/*<ViewSwitcher changeView={changeView} currentView={currentView} />*/}
            </SortWrapper>
            <MobileFiltersWrapper>
              <MobileCategoryFilterButtons
                onClick={toggleCategoryFilterMenu}
                text={__('Kategórie')}
                icon={IconType.folder}
              />
              <MobileCategoryFilterButtons
                onClick={toggleFilterMenu}
                text={__('Filter')}
                icon={IconType.filter}
              />
            </MobileFiltersWrapper>
            {activeAttribs && Object.keys(activeAttribs).length > 0 && (
              <ActiveFiltersWrapper>
                <ActiveFilters
                  resetUrl={deleteFilters}
                  activeAttribs={activeAttribs}
                  onClickAttrib={handleFilterChange}
                />
              </ActiveFiltersWrapper>
            )}
            {renderProductsCardView()}
            <LoadMoreButtonWrapper>
              {!fullLoadedProducts ? (
                <LoadMoreProductsBtn
                  text={__('Načitať dalšie produkty')}
                  onClick={loadMoreProducts}
                  isFetching={isFetchingMore}
                />
              ) : null}
            </LoadMoreButtonWrapper>
            <Pagination
              query={`${catUrl}${`${query}` ? query : '?'}`}
              totalItems={total}
              limit={limit}
              offset={offset}
            />
          </>
        )}
      </RightSideWrapper>
    );
  };

  const renderProductsCardView = () => {
    return (
      <CategoryProductList
        isFetching={isFetching}
        products={productsArray}
        addToCart={addToCart}
        addToRequest={addToRequest}
        visible={true}
        user={user}
        currentCategory={currentCategory}
      />
    );
  };

  const renderProductsListView = (isCardView: boolean) => {
    return (
      <CategoryTableList
        isFetching={isFetching}
        products={productsArray}
        addToCart={addToCart}
        addToRequest={addToRequest}
        visible={!isCardView}
        user={user}
      />
    );
  };

  const changeView = () => {
    setCurrentView(currentView === 'card' ? 'list' : 'card');
  };

  const addToCart = (product: any) => {
    dispatch(addItemToCart(product, 1, false, true));
  };

  const addToRequest = (product: any) => {
    dispatch(setAddToCartModalVisibility(true, product));
  };

  const hasOffset =
    urlAttribs && (urlAttribs.offset || urlAttribs.offset === '0')
      ? true
      : false;

  return (
    <>
      {urlAttribs &&
      ((Object.keys(urlAttribs).length > 0 && !hasOffset) ||
        Object.keys(urlAttribs).length > 1) ? (
        <Helmet>
          <meta name="ROBOTS" content={'NOINDEX, NOFOLLOW'} />
        </Helmet>
      ) : null}
      <Wrapper className="container container--wide">
        <MetaTags tags={getCategoryMetaTags(data)} />
        {renderLeftSide()}
        {renderRightSide()}
      </Wrapper>
      {isCategoryFilterVisible && (
        <>
          <MobileNavMenu closeMenu={toggleCategoryFilterMenu}>
            <MobileFilterMenu
              title={__('Kategórie')}
              handleClose={toggleCategoryFilterMenu}
            >
              {renderCategoriesFilter()}
            </MobileFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </>
      )}
      {isFilterMenuVisible && (
        <>
          <MobileNavMenu closeMenu={toggleFilterMenu}>
            <MobileFilterMenu
              title={__('Filter')}
              handleClose={toggleFilterMenu}
            >
              {renderOtherFilters()}
            </MobileFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </>
      )}
      <ToTopButton />
      {/*  */}
    </>
  );
}
const PromotionWrapper = styled(FlexRow)`
  width: 100%;
  margin: 0 ${rem(20)} ${rem(56)} 0;
  padding: ${rem(10)};
  justify-content: center;
  background: ${({ theme }) => theme.color.gift.background};
  color: ${({ theme }) => theme.color.gift.icon};
  border-radius: ${rem(4)};
`;
const PromotionText = styled.span`
  justify-content: center;
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(25)};
  text-align: center;
  padding: ${rem(17)} 0;
`;

const GolfcentrumSvgIconStyled = styled(GolfcentrumSvgIcon)`
  height: 100%;
  margin: 0 ${rem(17)};

  ${({ theme }) => theme.mediab.m580`
     transform: scale(1.5);
  `}
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding-top: ${rem(56)};
  margin-bottom: ${rem(80)} !important;

  ${({ theme }) => theme.mediab.l1050`
      padding-top: ${rem(32)};
      margin-bottom: ${rem(32)} !important;
  `}
`;

const MobileCategoryFilter = styled(FlexCol)`
  width: 100%;
`;

const RightSideWrapper = styled(FlexCol)`
  /* padding: ${rem(24)}; */
  max-width: ${rem(1048)};
  width: 100%;
  overflow-anchor: none;
`;

const LeftSideWrapper = styled(FlexCol)`
  max-width: ${rem(304)};
  margin-right: ${rem(56)};
  width: 100%;

  ${({ theme }) => theme.mediab.l1050`
      display: none;
  `}
`;

const SortWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(16)};
  justify-content: space-between;

  ${({ theme }) => theme.mediab.l1050`
      margin-bottom: 0 !important;
  `}
  ${({ theme }) => theme.mediab.m580`
      display: block;
  `}
`;

const SubCategoriesWrapper = styled(FlexRowCenterVertical)`
  flex-flow: row wrap;
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
    display: none;
 `}
`;

const MobileFiltersWrapper = styled(FlexRowCenterVertical)`
  display: none;
  justify-content: space-between;
  max-width: ${rem(320)};
  margin: 0 -${rem(16)};

  ${({ theme }) => theme.mediab.l1050`
      display: flex;
  `}

  ${({ theme }) => theme.mediab.m760`
      max-width: initial;
      // box-shadow: ${({ theme }) => theme.boxShadow.default};
      border-top: ${({ theme }) => theme.borders.primary};
      border-bottom: ${({ theme }) => theme.borders.primary};
      padding: 0 ${rem(16)};
  `}
`;

const ActiveFiltersWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l1050`
      margin-top: ${rem(32)};
      margin-bottom: ${rem(32)};
  `}
`;

const DesktopCheckboxesWrapper = styled.div`
  ${({ theme }) => theme.mediab.m760`
   display: none;
  `}
`;

const LoadMoreButtonWrapper = styled(FlexColCenterHorizontal)`
  margin: ${rem(30)} auto;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FilterNowButton = styled(Button)`
  height: ${rem(48)};
  padding: ${rem(15)};
  width: 100%;
  background-color: ${({ theme }) => theme.color.green};
  color: ${({ theme }) => theme.color.white};
  font-size: ${rem(14)};
  margin: 0 0 ${rem(16)};
  font-family: ${({ theme }) => theme.font.primary};
  position: sticky;
  bottom: ${({ mobile }) => (mobile ? rem(0) : rem(16))};
  animation: ${slideIn} 0.3s ease-in-out;
  border-radius: 0;
`;
export default withRouter(Category);
