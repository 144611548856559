import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { FlexCol } from 'eshop-defaults';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { connectSsr } from 'ssr-service';
import { __ } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import LazyLoad from 'react-lazyload';
import CartLogic from './CartLogic';
import {
  cartDataSelector,
  cartIsFetchingSelector,
  cartErrorSelector,
  freeDeliveryRemainingAmountSelector,
  updateCart,
  fetchCart,
  loadFreeDeliveryRemainingAmount,
  cartShowProblemsSelector,
  errorModalSelector,
} from './cartSlice';
import API, { ThenArg } from '../../services/API';
import {
  deliveryAddressesIsFetchingSelector,
  deliveryAddressesSelector,
  fetchDeliveryAddresses,
} from '../MyAccount/myAccountSlice';
import { userSelector } from '../App/selectors';
import ToTopButton from '../../components/_helpers/Default/ToTopButton';
import { ga4BeginCheckout } from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';
import { prop } from '../../utilities';

interface Props {
  dispatch: (action: any) => void;
  children: any;
  user: any;
  userInfo: any;
  data: any;
  error: { details: { description: string } };
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  isFetching: boolean;
  deliveryAddressesIsFetching: boolean;
  freeDeliveryRemainingAmount: number | null;
  showProblems: boolean;
}

class Cart extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      if (props.user) {
        dispatch(fetchDeliveryAddresses());
      }
      dispatch(fetchCart(true));
      dispatch(loadFreeDeliveryRemainingAmount());
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    const data = this.props.data;

    if (pathname === '/kosik') {
      if (window.gtag) {
        if (data) {
          //  @ts-ignore
          let gData = data.items.filter(d => !d.isFromSale);
          const currency = prop(data, 'currency', 'EUR');
          ga4BeginCheckout(gData, getGoogleAdsId(), currency);
        }
      }
    }
  }

  public render() {
    const {
      children,
      data,
      error,
      deliveryAddresses,
      isFetching,
      deliveryAddressesIsFetching,
      dispatch,
      freeDeliveryRemainingAmount,
      showProblems,
      userInfo,
    } = this.props;

    return (
      <>
        <CartLogic
          key={1}
          handleAcceptTermsChange={this.handleAcceptTermsChange}
          handleAcceptHeurekaChange={this.handleAcceptHeurekaChange}
          handleAcceptNewsLetterChange={this.handleAcceptNewsLetterChange}
          createOrder={this.createOrder}
          dispatch={dispatch}
          data={data}
          isFetching={isFetching}
          error={error}
          deliveryAddresses={deliveryAddresses}
          deliveryAddressesIsFetching={deliveryAddressesIsFetching}
          freeDeliveryRemainingAmount={freeDeliveryRemainingAmount}
          showProblems={showProblems}
          userInfo={userInfo}
        >
          {children}
        </CartLogic>
        <ToTopButton />
      </>
    );
  }

  private handleAcceptTermsChange = e => {
    this.props.dispatch(
      updateCart({ ...this.props.data, terms_accept: e.target.value }),
    );
  };

  private handleAcceptHeurekaChange = e => {
    this.props.dispatch(
      updateCart({ ...this.props.data, heureka: e.target.value }),
    );
  };

  private handleAcceptNewsLetterChange = e => {
    this.props.dispatch(
      updateCart({ ...this.props.data, newsletter_accept: e.target.value }),
    );
  };

  private createOrder = async (): Promise<string> => {
    let orderPublicId = '';

    try {
      const res = await API.createOrder(
        this.props.data.id,
        {},
        { ...this.props.data, step: 3 },
      );
      orderPublicId = res.orderPublicId;
    } catch (err) {
      console.log({ err });
    }

    return orderPublicId;
  };
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    userInfo: state.myAccount.userInfo,
    data: cartDataSelector(state),
    freeDeliveryRemainingAmount: freeDeliveryRemainingAmountSelector(state),
    isFetching: cartIsFetchingSelector(state),
    error: cartErrorSelector(state),
    deliveryAddresses: deliveryAddressesSelector(state),
    deliveryAddressesIsFetching: deliveryAddressesIsFetchingSelector(state),
    showProblems: cartShowProblemsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Cart' })(withRouter(Cart)),
);
