export default function configureTagManager(hostname) {
  switch (hostname) {
    default:
      return 'GTM-ABC23';
  }
}

export function getGoogleAdsId() {
  return (
    typeof window !== 'undefined' &&
    window &&
    window.__ANALYTICS_CONFIG_DATA__[
      `Google_conversionId-${
        window &&
        window.__REDUX_STATE__ &&
        window.__REDUX_STATE__.general &&
        window.__REDUX_STATE__.general.lang
          ? window.__REDUX_STATE__.general.lang
          : '-'
      }-%REACT_APP_DOMAIN_ID%`
    ]
  );
}
