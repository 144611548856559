import {
  CatgoriesActionsTypes,
  SearchTermType,
  SubcategoriesTree,
  SubcatriesActionsTypes,
} from './types';
import { combineReducers } from 'redux';

const mapCategoriesTreeById = (data: SubcategoriesTree) => {
  return data.reduce((acc, category) => {
    return {
      ...acc,
      [category.category_id]: {
        ...category,
      },
    };
  }, {});
};

const mapCategoriesTreeByIdRecursive = (data: SubcategoriesTree) => {
  return data.reduce((acc, category) => {
    return {
      ...acc,
      [category.category_id]: {
        ...category,
      },
      ...mapCategoriesTreeByIdRecursive(category.children as SubcategoriesTree),
    };
  }, {});
};

const INITIAL_STATE = {
  isFetching: false,
  isFetchingArticles: false,
};

export const cmsReducer = (state: any = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CMS/REQUEST_CMS_CATEGORIES': {
      return {
        ...state,
        isFetching: true,
      };
    }

    case 'CMS/RECEIVE_CMS_CATEGORIES_SUCCESS': {
      return {
        ...state,
        isFetching: false,
        sitemap: action.payload.sitemap,
        byId: action.payload.sitemapObj,
        // byId: mapCategoriesTreeByIdRecursive(action.payload.categories),
        // mainCategories: action.payload.categories.map(
        //   category => category.category_id,
        // ),
      };
    }

    case 'CMS/RECEIVE_CMS_CATEGORIES_ERROR': {
      return {
        ...state,
        isFetching: false,
        // byId: {},
        // allIds: [],
        error: action.payload.error,
      };
    }

    case 'CMS/REQUEST_CMS_ARTICLES': {
      return {
        ...state,
        isFetchingArticles: true,
        // byId: {
        //   ...state.byId,
        //   [action.payload.parentCategoryId]: {
        //     ...state.byId[action.payload.parentCategoryId],
        //     subcategoriesId: [],
        //     isFetchingSubcategories: true,
        //   },
        // },
      };
    }

    case 'CMS/RECEIVE_CMS_ARTICLES_SUCCESS': {
      // const subcategoriesId: number[] = action.payload.subcatgories.map(
      //   subcategory => subcategory.category_id,
      // );
      return {
        ...state,
        isFetchingArticles: false,
        allArticles: action.payload.articles,
        // byId: {
        //   ...state.byId,
        //   [action.payload.parentCategoryId]: {
        //     ...state.byId[action.payload.parentCategoryId],
        //     subcategoriesId,
        //     isFetchingSubcategories: false,
        //   },
        //   ...mapCategoriesTreeById(action.payload.subcatgories),
        // },
      };
    }

    case 'CMS/RECEIVE_CMS_ARTICLES_ERROR': {
      return {
        ...state,
        isFetchingArticles: false,
        // byId: {},
        // allIds: [],
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

const searchFormReducer = (
  state = {
    searchTerm: '',
  },
  action: SearchTermType,
) => {
  switch (action.type) {
    case 'Header/REQUEST_SEARCH_TERM': {
      return {
        searchTerm: action.payload.searchTerm,
        isFetching: true,
      };
    }

    case 'Header/RECEIVE_SEARCH_TERM_SUCCESS': {
      if (action.payload.searchTerm !== state.searchTerm) {
        return state;
      }
      return {
        ...state,
        isFetching: false,
        results: {
          productsResult: action.payload.articles,
        },
      };
    }

    case 'Header/RECEIVE_SEARCH_TERM_ERROR': {
      return {
        isFetching: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};
