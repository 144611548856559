import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexRowCenter,
  FlexRow,
  FlexCol,
  GolfCentrumNavigationItem as NavigationItem,
  ItemType,
  IconImg,
  GolfCentrumNavigationDropdownContent as NavigationDropdownContent,
  GolfCentrumNavigationDropdownItem as NavigationDropdownItem,
  OutsideClick,
  GolfcentrumSvgIcon as SvgIcon,
  GolfcentrumIconType as IconType,
  ArticleTabDefault,
  CategoryTab,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getImagePath } from '../../utilities/product';
import { Loader } from '../_helpers/Loader/Loader';
import { Link } from 'react-router';
import { Flexbox, FlexColCenter } from 'eshop-defaults/lib';
import { __ } from 'react-i18n';
import { resolveShortCategoryUrl } from '../../containers/Category/selectors';
import { deepFind } from '../../utilities';
import {
  resolveCategoryName,
  resolveCategoryUrlString,
} from '../../utilities/category';

export interface Props {
  user?: any;
  brands?: any;
  items: ThenArg<typeof API.loadTree>;
  changeDimmerVisible: (newVal: boolean) => void;
}

function Navigation({ items, changeDimmerVisible, user, brands }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeFirstItem, setActiveFirstItem] = useState<number>(-1);
  const [activeSecondItem, setActiveSecondItem] = useState<number>(-1);
  const [activeThirdItem, setActiveThirdItem] = useState<number>(-1);
  const [activeFourthItem, setActiveFourthItem] = useState<number>(-1);
  const [secondLvlItems, setSecondLvlItems] = useState<any[] | null>(null);
  const [thirdLvlItems, setThirdLvlItems] = useState<any[] | null>(null);
  const [fourthLvlItems, setFourthLvlItems] = useState<any[] | null>(null);
  const [fifthLvlItems, setFifthLvlItems] = useState<any[] | null>(null);

  const [activeItem, setActiveItem] = useState<any | null>(null);
  const onMouseOverSecond = id => {
    if (id || id === 0) {
      if (
        items &&
        items[id] &&
        items[id].children &&
        items[id].children.length > 0
      ) {
        setSecondLvlItems(items[id].children);
      } else {
        setSecondLvlItems(null);
      }
      setActiveFirstItem(id);
    }
  };

  const onMouseOverThird = id => {
    if (id || id === 0) {
      if (
        secondLvlItems &&
        secondLvlItems[id] &&
        secondLvlItems[id].children &&
        secondLvlItems[id].children.length > 0
      ) {
        setThirdLvlItems(secondLvlItems[id].children);
      } else {
        setThirdLvlItems(null);
      }
      setFourthLvlItems(null);
      setFifthLvlItems(null);
      setActiveSecondItem(id);
    }
  };

  const onMouseOverFourth = id => {
    if (id || id === 0) {
      if (
        thirdLvlItems &&
        thirdLvlItems[id] &&
        thirdLvlItems[id].children &&
        thirdLvlItems[id].children.length > 0
      ) {
        setFourthLvlItems(thirdLvlItems[id].children);
      } else {
        setFourthLvlItems(null);
      }
      setFifthLvlItems(null);
      setActiveThirdItem(id);
    }
  };

  const onMouseOverFifth = id => {
    if (id || id === 0) {
      if (
        fourthLvlItems &&
        fourthLvlItems[id] &&
        fourthLvlItems[id].children &&
        fourthLvlItems[id].children.length > 0
      ) {
        setFifthLvlItems(fourthLvlItems[id].children);
      } else {
        setFifthLvlItems(null);
      }
      setActiveFourthItem(id);
    }
  };

  const closeNav = () => {
    setIsOpen(false);
    changeDimmerVisible(false);
  };

  const toggleNav = id => {
    if (!isOpen) {
      resetState();
    }

    if (activeItem && id === activeItem.category_id && isOpen) {
      setIsOpen(false);
      changeDimmerVisible(false);
    } else {
      setIsOpen(true);
      changeDimmerVisible(true);
    }

    setActiveItem(items.find(item => item.category_id === id));
  };

  const resetState = () => {
    setActiveFirstItem(-1);
    setActiveSecondItem(-1);
    setSecondLvlItems(null);
    setThirdLvlItems(null);
  };

  const listIconSrc = '/images/golfcentrum/caret-left.svg';
  const placeholderImgSrc = '/images/zemplin/placeholder.svg';
  const topCategory = deepFind(items, 'is_top', true)
    .slice(0, 1)
    .pop();

  const article = {
    url: '',
    picture: '',
    date: '',
    title: __('Nakupovať novinky'),
  };

  const activeItemsNewProductsArray =
    activeItem &&
    activeItem.children &&
    activeItem.children.filter(c =>
      c.category_name.toLowerCase().includes('novinky'),
    );
  const newProductsCategory = activeItemsNewProductsArray
    ? activeItemsNewProductsArray[0]
    : null;
  const activeItemsTopBrandsArray =
    activeItem &&
    activeItem.children &&
    activeItem.children.filter(c =>
      c.category_name.toLowerCase().includes('top značky'),
    );
  const topBrands = activeItemsTopBrandsArray;

  return (
    <OutsideClick handleFunction={closeNav}>
      <Wrapper>
        {items &&
          items.map((item, i) => {
            if ((!item.counts || item.counts === 0) && !item.weburl) {
              return null;
            }
            return (
              <NavigationItem
                onClick={
                  item.children && item.children.length > 0
                    ? () => toggleNav(item.category_id)
                    : null
                }
                key={item.category_id}
                type={ItemType.PRIMARY}
                url={
                  item.children && item.children.length
                    ? ''
                    : resolveShortCategoryUrl(
                        item.category_id,
                        resolveCategoryUrlString(item),
                      )
                }
                title={
                  <CategoryName>
                    <span>{resolveCategoryName(item)}</span>
                    {item.children && item.children.length > 0 && (
                      <SvgIcon
                        width={16}
                        height={16}
                        icon={IconType.caretDownBold}
                        alt={'cart down'}
                        viewBox="0 0 16 16"
                        marginLeft={12}
                        cursor="pointer"
                      />
                    )}
                  </CategoryName>
                }
              />
            );
          })}
        {isOpen && (
          <NavigationDropdownContent fromTop={168}>
            <ItemsSectionWrapper>
              {activeItem &&
                activeItem.children.map((item: any, i) => {
                  const filteredChildren = item.children
                    ? item.children.filter(a => a.weburl || a.counts)
                    : [];

                  if (
                    item.category_name.toLowerCase().includes('top značky') ||
                    item.category_name.toLowerCase().includes('novinky')
                  ) {
                    return null;
                  }
                  return (
                    <React.Fragment key={i}>
                      {item.weburl ? (
                        <TitleA
                          onClick={closeNav}
                          href={resolveShortCategoryUrl(
                            item.category_id,
                            resolveCategoryUrlString(item),
                          )}
                          key={item.category_id}
                        >
                          {resolveCategoryName(item)}
                        </TitleA>
                      ) : (
                        <Title
                          onClick={closeNav}
                          to={resolveShortCategoryUrl(
                            item.category_id,
                            resolveCategoryUrlString(item),
                          )}
                          key={item.category_id}
                        >
                          {resolveCategoryName(item)}
                        </Title>
                      )}
                      {filteredChildren.map((child, i) => (
                        <>
                          {(!child.counts || item.counts === 0) &&
                          !child.weburl ? null : (
                            <>
                              {child.weburl ? (
                                <SubTitle
                                  isEmpty={false}
                                  onClick={closeNav}
                                  to={`/${resolveCategoryUrlString(child)}`}
                                  key={child.category_id}
                                  isLast={i === filteredChildren.length - 1}
                                >
                                  {resolveCategoryName(child)}
                                </SubTitle>
                              ) : (
                                <SubTitle
                                  isEmpty={false}
                                  onClick={closeNav}
                                  to={resolveShortCategoryUrl(
                                    child.category_id,
                                    resolveCategoryUrlString(child),
                                  )}
                                  key={child.category_id}
                                  isLast={i === filteredChildren.length - 1}
                                >
                                  {resolveCategoryName(child)}
                                </SubTitle>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </React.Fragment>
                  );
                })}
            </ItemsSectionWrapper>
            <RestSectionWrapper>
              {topBrands ? (
                <>
                  {topBrands.map(topBrand => {
                    return (
                      <BrandsSectionWrapper>
                        <BrandsTitle
                          onClick={closeNav}
                          to={`/` + resolveCategoryUrlString(topBrand)}
                        >
                          {resolveCategoryName(topBrand)}
                        </BrandsTitle>
                        <BrandWrapper>
                          {topBrand.children &&
                            topBrand.children.map((item: any, i) => (
                              <BrandLink
                                onClick={closeNav}
                                to={`/` + resolveCategoryUrlString(item)}
                              >
                                {item.image ? (
                                  <BrandImage
                                    key={i}
                                    title={item.category_name}
                                    src={
                                      item.image
                                        ? getImagePath(item.image, {
                                            width: 220,
                                          })
                                        : placeholderImgSrc
                                    }
                                    alt={item.category_name}
                                  />
                                ) : (
                                  <BrandText key={i}>
                                    {resolveCategoryName(item)}
                                  </BrandText>
                                )}
                              </BrandLink>
                            ))}
                        </BrandWrapper>
                      </BrandsSectionWrapper>
                    );
                  })}
                </>
              ) : null}
              {newProductsCategory ? (
                <SectionWrapper>
                  <NewsWrapper>
                    {newProductsCategory && (
                      <CategoryTab
                        onClick={closeNav}
                        category={newProductsCategory}
                        linkText={__('Nakupovať novinky')}
                        maxWidth={272}
                      />
                    )}
                  </NewsWrapper>
                </SectionWrapper>
              ) : null}
            </RestSectionWrapper>
          </NavigationDropdownContent>
        )}
      </Wrapper>
    </OutsideClick>
  );
}

const Wrapper = styled(FlexRowCenterVertical)`
  height: ${rem(72)};
`;

const SecondaryItems = styled(FlexRowCenterVertical)`
  padding: 0 ${rem(16)};
  background-color: ${({ theme }) => theme.colors.navSecondary};
`;

const SectionWrapper = styled(Flexbox)`
  background-color: white;
  flex-flow: column wrap;
  align-content: flex-start;
  -webkit-flex-flow: column wrap;
  margin-right: ${rem(56)};
  max-height: ${rem(556)};
  align-content: flex-start;
  &:last-child {
    margin-right: ${rem(0)};
  }

  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(900)};
  `}
`;

const RestSectionWrapper = styled(FlexRow)`
  width: 100%;
`;

const ItemsSectionWrapper = styled(SectionWrapper)`
  margin-right: ${rem(16)};
  max-width: ${rem(500)};
  width: 100%;
`;

const BrandsSectionWrapper = styled(SectionWrapper)`
  flex-flow: column;
  max-height: initial;
  width: 100%;
`;

const CategoryWrapper = styled.div`
  align-self: flex-start;
`;

const BrandWrapper = styled(FlexCol)`
  width: 100%;
  align-content: flex-start;
  flex-flow: row wrap;
  max-height: ${rem(492)};
  overflow: auto;
`;

const BrandLink = styled(Link)``;

const NewsWrapper = styled.div`
  max-width: ${rem(487)};
  width: 100%;
`;

const BrandImage = styled.img`
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  padding: ${rem(16)};
  max-height: ${rem(128)};
  max-width: ${rem(157)};
  width: 100%;
  margin: 0 ${rem(8)} ${rem(8)} 0;

  &:nth-child(3n) {
    margin-right: 0;
  }

  ${({ theme }) => theme.mediab.l1150`
     &:nth-child(2n) {
       margin-right: 0;
     }
     
     &:nth-child(3n) {
      margin-right: inherit;
     }
  `}
`;

const BrandText = styled(FlexColCenter)`
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  color: ${({ theme }) => theme.color.textPrimary};
  font-size: ${rem(14)};
  padding: ${rem(16)};
  height: ${rem(88)};
  max-width: ${rem(157)};
  width: 100%;
  margin: 0 ${rem(8)} ${rem(8)} 0;
`;

const Title = styled(Link)`
  margin-bottom: ${rem(24)};
  margin-top: 0;
  padding-right: ${rem(24)};
  font-weight: 300;
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(20)};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-decoration: none;
  width: fit-content;
  min-width: ${rem(156)};
  display: flex;
  line-height: ${rem(24)};

  &:hover {
    text-decoration: underline;
  }

  &:first-child {
    margin-top: 0;
  }

  ${({ theme }) => theme.mediab.l1150`
     min-width: ${rem(144)};
  `}

  ${({ theme }) => theme.mediab.l1050`
     min-width: ${rem(128)};
  `}
`;

const TitleA = styled.a`
  margin-bottom: ${rem(24)};
  margin-top: 0;
  padding-right: ${rem(24)};
  font-weight: 300;
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(20)};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-decoration: none;
  width: fit-content;
  min-width: ${rem(200)};
  display: flex;
  line-height: ${rem(24)};

  &:hover {
    text-decoration: underline;
  }

  &:first-child {
    margin-top: 0;
  }
`;

const BrandsTitle = styled(Title)`
  margin-bottom: ${rem(8)};
`;

const SubTitle = styled(Title)<{ isEmpty?: string; isLast?: boolean }>`
  margin-top: 0;
  margin-bottom: ${({ isLast }) => (isLast ? rem(24) : rem(8))};
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme, isEmpty }) =>
    isEmpty ? 'coral' : theme.color.textPrimary};
  line-height: ${rem(17)};

  &:last-child {
    margin-bottom: ${rem(24)};
  }
`;

const FirstItemsWrapper = styled(FlexCol)`
  width: ${rem(300)};
  height: ${rem(630)};
  padding: ${rem(32)} 0;

  border-radius: 0 0 0 ${rem(2)};
  color: black;
`;

const OtherItemsWrapper = styled(FirstItemsWrapper)`
  box-shadow: initial;
  overflow-y: auto;
`;

const SecondHover = styled(CategoryWrapper)`
  background-color: #fbfafd;
  box-shadow: ${({ theme }) => theme.boxShadow.secondary};
`;

const ThirdHover = styled(FlexRow)`
  background-color: #fbfafd;
  overflow-y: auto;
`;

const FourthHover = styled(FlexRow)`
  background-color: #fbfafd;
  overflow-y: auto;
  ${({ theme }) => theme.mediab.l1400`
    display:none;
  `}
`;

const FifthHover = styled(FlexRow)`
  background-color: #fbfafd;
  overflow-y: auto;
  ${({ theme }) => theme.mediab.l1620`
    display:none;
  `}
`;

const CategoryName = styled.span`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${({ theme }) => theme.color.textPrimary};
`;

export { Navigation };
