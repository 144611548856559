import * as React from 'react';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { prop, getAuthorUrl, formatDate, stripHtmlTags } from '../../utilities';
import styled from 'styled-components';
import { ArticleMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { __ } from 'react-i18n';
import {
  resolveArticleContentDetails,
  getArticleProducts,
} from '../../utilities/article';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { getCmsMetaTags } from '../../utilities/metatags';
import { getImagePath } from '../../utilities/product';
import {
  Article,
  DownloadsList,
  SitemapList,
  InfoText,
  FlexCol,
  ContactForm,
} from 'eshop-defaults';
import { rem } from 'polished';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import ShareWrapper from '../../components/_helpers/Default/ShareWrapper';
import SliderComponent from '../../components/_helpers/Slider/Slider';
import SecondSlider from '../../components/_helpers/Slider/SecondSlider';
import { getArticleDetailInfo } from 'eshop-defaults/lib/utilities/selectors';
import { loadCMSCategoriesTree } from './actions';
import { withRouter, WithRouterProps } from 'react-router';
import { push } from 'react-router-redux';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';

import { FlexRow } from 'eshop-defaults';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  DesktopWrapper,
  MobileWrapper,
} from '../../components/_helpers/Default/ResponsiveWrappers';
import ToTopButton from '../../components/_helpers/Default/ToTopButton';
import { submitContactForm } from '../../containers/CMS/actions';
import { Helmet } from 'react-helmet';

const SubtreeWrapper = styled(FlexRow)`
  justify-content: space-between;
  padding-bottom: 15px;
  max-width: 43rem;
  margin: 0 auto;

  ${({ theme }) => theme.mediab.s450`
     flex-flow: column;
  `}
`;

const SubtreeContainer = styled.div`
  width: 49%;
  background: rgba(17, 17, 17, 0.04);
  padding: 1rem;

  a {
    font-family: 'Bebas Neue', cursive;
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    color: #444444;
  }

  ${({ theme }) => theme.mediab.s450`
    width: 100%;
  `}
`;

export interface CmsProps {
  sitemap: any;
  sitemapId: any;
  sitemapTree: any;
  parentSitemap: any;
  isError: boolean;
  articles: any;
  categories: any;
  isArticle: boolean;
  contentData: ThenArg<typeof API.loadArticleDetail>;
  draftContent: boolean;
  lang: string;
  currency: string;
  dispatch: (action: any) => void;
}

class Cms extends React.Component<
  CmsProps & WithRouterProps,
  { photoIndex: number; isOpen: boolean; galleryPhotos: any[] }
> {
  public static async getInitialProps(CmsProps) {
    const { sitemapId, contentId, lang, dispatch, currency } = CmsProps;
    try {
      const returnProps: any = {};
      returnProps.isArticle = false;
      returnProps.articles = [];
      returnProps.categories = [];
      returnProps.sitemapId = sitemapId;
      let articleDetail;
      let parentSitemapId;
      if (sitemapId) {
        const sitemapData = await API.loadSitemap(
          sitemapId,
          {},
          { xAcceptLanguage: lang },
        );
        articleDetail = sitemapData.content;
        parentSitemapId = sitemapData.parent_id;

        const { sitemap_tree } = await API.loadSitemapSubtree(
          sitemapId,
          {},
          { xAcceptLanguage: lang },
        );
        returnProps.sitemapTree = sitemap_tree;
      } else {
        returnProps.isArticle = true;
        articleDetail = await API.loadArticleDetail(
          contentId,
          {},
          { xAcceptLanguage: lang, xCurrency: currency },
        );

        parentSitemapId = articleDetail.sitemap_item_id;
      }

      if (parentSitemapId) {
        returnProps.sitemap = (
          await API.loadSitemapSubtree(
            parentSitemapId,
            {},
            { xAcceptLanguage: lang },
          )
        ).sitemap_tree;

        const parentSitemapData = await API.loadSitemap(
          parentSitemapId.toString(),
          {},
          { xAcceptLanguage: lang },
        );
        if (parentSitemapData) {
          returnProps.categories.push(parentSitemapData);
        }
      }
      if (
        articleDetail &&
        articleDetail.sitemapIds &&
        articleDetail.sitemapIds.length > 0
      ) {
        dispatch(setBreadCrumbPath(BreadCrumbType.CMS, articleDetail));
      } else {
        dispatch(setBreadCrumbPath(BreadCrumbType.CMS_GENERAL, articleDetail));
      }
      returnProps.contentData = articleDetail;
      return returnProps;
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  public constructor(props) {
    super(props);
  }

  public dispatchContactForm = (email, message, name) => {
    const { dispatch } = this.props;
    dispatch(submitContactForm(email, message, name));
  };

  public render() {
    const { sitemap } = this.props;
    return (
      <>
        <Helmet>
          <script
            async
            src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}`}
          ></script>
        </Helmet>
        <RootWrapper className="container container--wide">
          {this.renderCategories()}
          {this.renderArticle()}
        </RootWrapper>
        <RootMobileWrapper className="container container--wide">
          {this.renderMobileCategories()}
          {!sitemap || sitemap.length === 0 ? this.renderArticle() : null}
        </RootMobileWrapper>
        <ToTopButton />
      </>
    );
  }

  public renderCategories = () => {
    const { sitemap } = this.props;
    const sitemapId = 8;

    // const subid = this.props.location.query.subid;
    const remappedSitemap =
      sitemap &&
      sitemap.map(s => ({
        name: s.name || '',
        id: s.id || '',
        descId: s.descr_content_id || '',
      }));

    return (
      <SitemapList
        currentId={(sitemapId && sitemapId.toString()) || -1}
        handleCategoryChange={this.handleCategoryChange}
        sitemaps={remappedSitemap}
        isBlog={false}
      />
    );
  };

  public renderMobileCategories = () => {
    const { sitemap, contentData } = this.props;
    const sitemapId = 8;

    // const subid = this.props.location.query.subid;
    const article = this.renderArticle();
    const remappedSitemap =
      sitemap &&
      sitemap.map(s => ({
        name: s.name || '',
        id: s.id || '',
        descId: s.descr_content_id || '',
        children:
          s.descr_content_id === prop(contentData, 'id') ? article : null,
      }));

    return (
      <SitemapList
        currentId={(sitemapId && sitemapId.toString()) || -1}
        handleCategoryChange={this.handleCategoryChange}
        sitemaps={remappedSitemap}
        isBlog={false}
        currentDescrId={prop(contentData, 'id')}
      />
    );
  };

  public handleCategoryChange = async (newId: number | string) => {
    const { sitemap } = this.props;
    const newSitemap = sitemap.find(
      item => item.id.toString() === newId.toString(),
    );
    const { location, dispatch } = this.props;
    dispatch(
      push({
        pathname: `/${newSitemap.url}`,
        search: '',
      }),
    );
  };

  public renderArticle() {
    const {
      contentData,
      isError,
      categories,
      isArticle,
      sitemap,
      sitemapTree,
    } = this.props;
    const lastUpdate = prop(contentData, 'last_update');
    const createdAt = prop(contentData, 'created_date');
    const author = prop(contentData, 'author');
    const article = prop(contentData, 'json_content');
    const photogallery = prop(contentData, 'photogallery');

    let titleImage = '';
    if (photogallery) {
      titleImage = prop(photogallery[0], 'document_path');
    }
    const { name, annotation } = resolveArticleContentDetails(contentData);
    const { videos } = getArticleDetailInfo(contentData);
    const downloads = prop(contentData, 'downloads', []);
    const products = getArticleProducts(contentData);
    const lightboxItems =
      photogallery && photogallery.length > 0
        ? photogallery.map(item => {
            return getImagePath(
              item.document_path,
              { width: 1, height: 1 },
              true,
              false,
              false,
            );
          })
        : [];

    if (!sitemap) {
      return (
        <Wrapper className="container container--wide">
          <InfoText type={InfoType.ERROR} info={__('Článok nebol nájdený')} />
        </Wrapper>
      );
    }
    const currentSitemap = sitemap.find(i => i.id === this.props.sitemapId);
    const sitemapUniqid = prop(currentSitemap, 'uniqid', '');
    const sitemapChildren = sitemapTree
      ? sitemapTree
      : prop(currentSitemap, 'children', []);

    return (
      <Wrapper className="container container--wide">
        {isError || !contentData ? (
          <>
            <NotFoundArticle>
              {__('Rubrika neobsahuje žiadny obsah.')}
            </NotFoundArticle>
            {sitemapChildren.length > 0 && (
              <SubtreeWrapper>
                {sitemapChildren.map(item => {
                  return (
                    <SubtreeContainer>
                      <a href={`/${item.url}`}>{item.name}</a>
                    </SubtreeContainer>
                  );
                })}
              </SubtreeWrapper>
            )}
          </>
        ) : (
          <>
            <MetaTags
              tags={getCmsMetaTags(
                article,
                titleImage
                  ? getImagePath(titleImage, { width: 1920, height: 600 }, true)
                  : '',
              )}
            />
            <ArticleMicrodata
              headline={name}
              image={
                titleImage
                  ? getImagePath(titleImage, { width: 1920, height: 600 }, true)
                  : ''
              }
              datePublished={
                createdAt ? formatDate(createdAt, 'MM/DD/YYYY') : ''
              }
              description={stripHtmlTags(annotation)}
              dateModified={
                lastUpdate ? formatDate(lastUpdate, 'MM/DD/YYYY') : ''
              }
              author={author}
            />
            <Article
              article={contentData}
              categories={categories}
              isArticle={isArticle}
            />
            {sitemapChildren.length > 0 && (
              <SubtreeWrapper>
                {sitemapChildren.map(item => {
                  return (
                    <>
                      <SubtreeContainer>
                        <a href={`/${item.url}`}>{item.name}</a>
                      </SubtreeContainer>
                    </>
                  );
                })}
              </SubtreeWrapper>
            )}
            {photogallery && photogallery.length > 0 && (
              <ItemsWrapper>
                <Title>{__('Galéria')}</Title>
                <SecondSlider
                  type="article-photogallery"
                  slides={photogallery}
                  showDots={true}
                  lightboxItems={lightboxItems}
                />
              </ItemsWrapper>
            )}
            {videos && videos.length > 0 && (
              <ItemsWrapper>
                {<Title>{__('Video')}</Title>}
                {videos.map((video, i) => (
                  <Video key={i}>
                    {video.outside_url ? (
                      <iframe
                        title={video.video_name}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                        frameBorder="0"
                        src={video.final_url}
                      />
                    ) : (
                      <video
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                        controls
                      >
                        <source src={video.final_url} />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </Video>
                ))}
              </ItemsWrapper>
            )}
            {downloads && downloads.length > 0 && (
              <ItemsWrapper>
                <DownloadsList
                  title={__('Na stiahnutie')}
                  downloads={downloads}
                />
              </ItemsWrapper>
            )}
            {products && products.length > 0 && (
              <ProductsItemsWrapper>
                <SecondSlider
                  type="article-product"
                  slides={products}
                  showDots={true}
                />
              </ProductsItemsWrapper>
            )}
            {sitemapUniqid === 'CUSTOMER_SERVICE' &&
            prop(currentSitemap, 'lang') !== 'sk' ? (
              <ContactForm sendContantForm={this.dispatchContactForm} />
            ) : null}
            <ShareButtonsWrapper>
              <ShareWrapper
                title={name}
                desc={stripHtmlTags(annotation)}
                withTitle={false}
                image={titleImage}
              />
            </ShareButtonsWrapper>
          </>
        )}
      </Wrapper>
    );
  }
}

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.secondary};
  color: ${({ theme }) => theme.color.primary};
  font-weight: 500;
  font-size: ${rem(24)};
  line-height: ${rem(28)};
  margin-bottom: ${rem(24)};
  padding-bottom: ${rem(16)};
  border-bottom: ${({ theme }) => theme.borders.primary};
`;

const ItemsWrapper = styled.div`
  max-width: ${rem(688)};
  margin: auto;
  margin-top: ${rem(48)};
`;

const ProductsItemsWrapper = styled(ItemsWrapper)`
  ${({ theme }) => theme.mediab.m810`
  margin-bottom: ${rem(32)};
    height: ${rem(400)}
  `}
`;

const RootWrapper = styled(FlexRow)`
  width: 100%;
  padding: ${rem(56)} ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: 0 ${rem(24)} ${rem(32)};
  `}

  ${({ theme }) => theme.mediab.m580`
    display: none;
  `}
`;

const RootMobileWrapper = styled(FlexCol)`
  display: none;
  flex-flow: column;
  padding: 0 ${rem(24)} ${rem(32)};
  width: 100%;

  ${({ theme }) => theme.mediab.m580`
    display: flex;
  `}
`;

const Wrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediab.m580`
    padding: 0 !important;
  `}
`;

const NotFoundArticle = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  margin: 0 auto;
  padding: ${rem(24)};
  font-size: ${rem(16)};
  text-align: center;
  width: 100%;
`;

const VideoWrapper = styled.div``;
const Video = styled.div`
  position: relative;
  padding-bottom: 56.28%;
  margin-bottom: ${rem(16)};
  padding-top: ${rem(25)};
  height: 0;
`;

const ShareButtonsWrapper = styled.div`
  margin-top: ${rem(56)};
`;

const mapStateToProps = state => ({
  lang: state.general.lang,
  currency: state.general.currency,
});

export default connect(mapStateToProps)(withRouter(Cms));
