import * as React from 'react';
import { __ } from 'react-i18n';
import Footer from '../../components/Footer/Footer';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import {
  langSelector,
  contactInfoSelector,
  footerDataSelector,
  sitemapSubtreeDataSelector,
} from '../App/selectors';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { prop } from '../../utilities';

interface Props {
  lang: string;
  sitemap: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  phone: string;
  contactInfo: {
    email: string;
    phone: string;
  };
  sitemapSubtreeData: any;
}

class FooterContainer extends React.PureComponent<Props> {
  public render() {
    const {
      sitemap,
      contactInfo: { email, phone },
      sitemapSubtreeData,
    } = this.props;

    const { BENEFITS: benefits } = sitemapSubtreeData;
    const secondInfoTexts = prop(benefits, 'sitemap_tree', []);

    return (
      <>
        {' '}
        <InfoBanner secondInfoTexts={secondInfoTexts} />{' '}
        <Footer phone={phone} email={email} items={sitemap} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    contactInfo: contactInfoSelector(state),
    sitemap: footerDataSelector(state),
    sitemapSubtreeData: sitemapSubtreeDataSelector(state),
  };
};

export default connect(mapStateToProps)(FooterContainer);
