import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags, CartContactInfoPart } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { updateCart } from './cartSlice';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  deliveryAddresses: any;
  deliveryAddressesIsFetching: boolean;
  showProblems: boolean;
  dispatchCartContactInfo: any;
  cartContactInfo: any;
}

class CartSecondStepContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, data } = props;
    try {
      // if (data) {
      //   dispatch(updateCart({ ...data, step: 2 }));
      // }
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const {
      data,
      isFetching,
      deliveryAddresses,
      deliveryAddressesIsFetching,
      dispatchCartContactInfo,
      showProblems,
      cartContactInfo,
    } = this.props;

    return (
      <>
        <MetaTags
          tags={{ title: __('Košík - Osobné údaje | GOLF CENTRUM.sk') }}
        />
        <CartContactInfoPart
          data={data}
          isFetching={isFetching}
          updateCart={this.updateCart}
          deliveryAddresses={deliveryAddresses}
          deliveryAddressesIsFetching={deliveryAddressesIsFetching}
          showProblems={showProblems}
          dispatchCartContactInfo={dispatchCartContactInfo}
          cartContactInfo={cartContactInfo}
        />
      </>
    );
  }

  private updateCart = data => {
    this.props.dispatch(updateCart(data, true));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartSecondStep' })(CartSecondStepContainer),
);
