import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const generalDomainSelector = state => state.general || {};
export const authDomainSelector = state => state.auth || {};
export const categoryDomainSelector = state => state.category || {};
export const categoryDetailDomainSelector = state => state.categoryDetail || {};

export const categoryProductsSelector = createSelector(
  categoryDetailDomainSelector,
  substate => prop(substate, 'category.products.products', null),
);

export const categoryProductsIsFetchingSelector = createSelector(
  categoryDetailDomainSelector,
  substate => prop(substate, 'category.products.isFetching', false),
);

export const sortDirSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'category.products.sortDir', ''),
);

export const sortSelector = createSelector(categoryDomainSelector, substate =>
  prop(substate, 'category.products.sort', ''),
);

export const tokenSelector = createSelector(authDomainSelector, substate =>
  prop(substate, 'token', ''),
);

export const langSelector = createSelector(generalDomainSelector, substate =>
  prop(substate, 'lang', ''),
);

export const titleSelector = createSelector(generalDomainSelector, substate =>
  prop(substate, 'title', ''),
);

export const categoryIsFetching = createSelector(
  categoryDetailDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const categoryArticles = createSelector(
  categoryDetailDomainSelector,
  substate => prop(substate, 'articles', []),
);

export const categoryArticlesOffsetSelector = createSelector(
  categoryDetailDomainSelector,
  substate => prop(substate, 'offset', []),
);

export const categoryDataSelector = createSelector(
  categoryDetailDomainSelector,
  substate => prop(substate, 'category.data', {}),
);

export const resolveCategoryUrl = (
  id: string | number,
  url: string,
  query?: string,
): string => {
  return `/kategoria/${id}/${url}${query ? `?${query}` : ''}`;
};

export const resolveShortCategoryUrl = (
  id: string | number,
  url: string,
  query?: string,
): string => {
  return `/${url}${query ? `?${query}` : ''}`;
};
