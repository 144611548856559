import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const InputStyles = css`
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  padding: 0;
  margin: 0;
  appearance: none;
  box-shadow: none;
  &:focus {
  }
  &:hover {
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const Label = css`
  display: block;
  font-size: ${rem(12)};
  line-height: 1;
  margin-bottom: ${rem(10)};
  &[for] {
    cursor: pointer;
  }
`;

interface CheckboxIO {
  name: string;
  id: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: () => void;
}

const CheckboxLabel = styled.label`
  display: inline-block;
  font-size: ${rem(14)};
  line-height: 1;
  padding: ${rem(13)} ${rem(5)} ${rem(13)} ${rem(34)};
  position: relative;
  &[for] {
    cursor: pointer;
  }
  &:before,
  &:after {
    display: inline-block;
    content: '';
    position: absolute;
  }
  &:before {
    background: ${({ theme }) => theme.color.white};
    border: ${rem(2)} solid ${({ theme }) => theme.color.gray80};
    border-radius: ${rem(4)};
    width: ${rem(24)};
    height: ${rem(24)};
    top: ${rem(8)};
    left: 0;
    box-shadow: ${({ theme }) => theme.boxShadow.default};
    ${({ theme }) =>
      theme.transitions('background-color, border-color, box-shadow')};
  }
  &:after {
    border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.white};
    border-left: ${rem(2)} solid ${({ theme }) => theme.color.white};
    width: ${rem(10)};
    height: ${rem(6)};
    transform: rotate(-45deg);
    top: ${rem(15)};
    left: ${rem(7)};
  }
`;

const CheckboxInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  &:focus + ${CheckboxLabel} {
    &:before {
      border-color: ${({ theme }) => theme.color.success};
      box-shadow: ${({ theme }) => theme.boxShadow.success};
    }
  }
  &:checked + ${CheckboxLabel} {
    &:before {
      background: ${({ theme }) => theme.color.success};
      border-color: ${({ theme }) => theme.color.success};
    }
  }
`;

export class Checkbox extends React.PureComponent<CheckboxIO> {
  public render() {
    const { name, id, label, disabled, checked, onChange } = this.props;
    return (
      <React.Fragment>
        <CheckboxInput
          checked={checked}
          type="checkbox"
          name={name}
          id={id}
          disabled={disabled}
          onChange={onChange}
        />
        <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
      </React.Fragment>
    );
  }
}

interface RadioIO {
  name: string;
  id: string;
  label: string;
  disabled?: boolean;
  marginRight?: any;
}

const RadioLabel = styled.label<{ margin?: number }>`
  display: inline-block;
  font-size: ${rem(14)};
  line-height: 1;
  padding: ${rem(13)} ${rem(5)} ${rem(13)} ${rem(34)};
  position: relative;
  margin-right: ${props => (props.margin ? props.margin : 0)};
  &[for] {
    cursor: pointer;
  }
  &:before,
  &:after {
    display: inline-block;
    content: '';
    border-radius: 50%;
    position: absolute;
  }
  &:before {
    background: ${({ theme }) => theme.color.white};
    border: ${rem(2)} solid ${({ theme }) => theme.color.grayLight};
    width: ${rem(24)};
    height: ${rem(24)};
    top: ${rem(8)};
    left: 0;
  }
  &:after {
    background: ${({ theme }) => theme.color.green};
    width: ${rem(12)};
    height: ${rem(12)};
    top: ${rem(14)};
    left: ${rem(6)};
    ${({ theme }) => theme.transition('transform')};
    transform: scale(0);
  }
`;

export const RadioInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  &:focus + ${RadioLabel} {
    &:before {
      border-color: ${({ theme }) => theme.color.success};
      box-shadow: ${({ theme }) => theme.boxShadow.success};
    }
  }
  &:checked + ${RadioLabel} {
    &:before {
      border-color: ${({ theme }) => theme.color.success};
    }
    &:after {
      transform: scale(1);
    }
  }
`;

export class Radio extends React.PureComponent<RadioIO> {
  public render() {
    const { name, id, label, disabled, marginRight } = this.props;
    return (
      <React.Fragment>
        <RadioInput type="radio" name={name} id={id} disabled={disabled} />
        <RadioLabel margin={marginRight} htmlFor={id}>
          {label}
        </RadioLabel>
      </React.Fragment>
    );
  }
}
