import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  searchResultsSelector,
  searchResultsIsFetchingSelector,
} from '../App/selectors';
import {
  FlexCol,
  FlexColCenterHorizontal,
  FlexRowCenterVertical,
  GolfcentrumProductList as ProductList,
  GolfcentrumLoadMoreProductsBtn as LoadMoreProductsBtn,
  GolfcentrumPagination as Pagination,
  GolfcentrumCategoryProductsOnPage as CategoryProductsOnPage,
  prop,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  loadSearchTermResults,
  loadMoreSearchTermResults,
} from '../App/actions';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { langSelector } from '../Category/selectors';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { addItemToCart } from '../Cart/cartSlice';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import ToTopButton from '../../components/_helpers/Default/ToTopButton';
import {
  attribsObjectToUrl,
  urlAttribsToObject,
} from '../Category/categorySlice';

interface Props {
  offset: number;
  lang: string;
  location: {
    query: {
      q: string;
      offset?: number;
      displayProducts?: number;
    };
    pathname: string;
  };
  searchedProducts: ThenArg<typeof API.searchProducts>;
  searchIsFetching: boolean;
  dispatch: (action: any) => void;
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(56)} ${rem(20)};
  width: 100%;
`;

const Header = styled(FlexCol)`
  padding-bottom: ${rem(32)};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  font-size: ${rem(30)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;
  text-transform: uppercase;
  line-height: ${rem(38)};
  flex-flow: row wrap;
  display: block;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
     line-height: ${rem(20)};
  `}
`;

const HighlitedSearchTerm = styled.span`
  font-weight: 700;
  margin-left: ${rem(5)};
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: ${rem(16)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(8)};
     font-size: ${rem(16)};
  `}
`;

class SearchResults extends React.Component<Props & WithRouterProps> {
  public static getSearchQuery = (props: Props): string =>
    props.location.query.q;

  public static async getInitialProps(props: Props) {
    try {
      const { dispatch, location } = props;
      const {
        query: { offset },
      } = location;

      const searchTerm = SearchResults.getSearchQuery(props);
      dispatch(setBreadCrumbPath(BreadCrumbType.NONE));

      const urlAttribs = prop(location, 'query');
      const numberOfProducts = prop(urlAttribs, 'displayProducts');
      const numberOfProductsVal = numberOfProducts ? numberOfProducts : '12';

      await dispatch(
        loadSearchTermResults(searchTerm, numberOfProductsVal, offset),
      );
      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    const q = SearchResults.getSearchQuery(this.props);

    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: `${__('Vyhľadávanie')}: ${q}` }} />
          {this.renderHeader()}
          {this.renderResults()}
        </Wrapper>
        <ToTopButton />
      </>
    );
  }

  public renderHeader() {
    const { searchedProducts, router, dispatch, location } = this.props;

    const urlAttribs = prop(location, 'query');
    const urlAttribsObj = urlAttribsToObject(urlAttribs);
    const paramUrl = prop(location, 'pathname');

    const handleProductsOnPage = e => {
      const value = e.target.value;
      router.push(
        attribsObjectToUrl(paramUrl, {
          otherAttribs: {
            ...urlAttribsObj.otherAttribs,
            ...urlAttribs,
            displayProducts: value,
            offset: 0,
          },
        }),
      );
    };

    const numberOfProducts = prop(urlAttribs, 'displayProducts');
    const numberOfProductsVal = numberOfProducts ? numberOfProducts : '12';

    const searchTerm = SearchResults.getSearchQuery(this.props);
    let foundText = __('Našlo sa ');
    let resultsText = __(' výsledkov');
    if (searchedProducts) {
      foundText =
        searchedProducts.total === 1
          ? __('Našiel sa ')
          : searchedProducts.total < 5 && searchedProducts.total > 0
          ? __('Našli sa ')
          : __('Našlo sa ');
      resultsText =
        searchedProducts.total === 1
          ? __(' výsledok')
          : searchedProducts.total < 5 && searchedProducts.total > 0
          ? __(' výsledky')
          : __(' výsledkov');
    }

    return (
      <Header>
        <SearchedTermWrapper>
          {__('Hľadaný výraz')}{' '}
          <HighlitedSearchTerm>"{searchTerm}"</HighlitedSearchTerm>
        </SearchedTermWrapper>
        <ResultsTotal>
          {__(foundText)}
          <b>{` ${
            searchedProducts && searchedProducts.total
              ? searchedProducts.total
              : 0
          } `}</b>
          {__(resultsText)}
        </ResultsTotal>
        <CategoryProductsOnPage
          handleChange={handleProductsOnPage}
          displayNumber={numberOfProductsVal}
          text={__('Produktov na stránke:')}
          customOptions={[
            { value: '12', name: '12' },
            { value: '36', name: '36' },
            { value: '54', name: '54' },
          ]}
        />
      </Header>
    );
  }

  public renderResults() {
    const {
      dispatch,
      location,
      searchedProducts: products,
      searchIsFetching,
    } = this.props;

    const urlAttribs = prop(location, 'query');

    const numberOfProducts = prop(urlAttribs, 'displayProducts');
    const offset = prop(urlAttribs, 'offset', 0);
    const numberOfProductsVal = numberOfProducts
      ? parseInt(numberOfProducts)
      : 12;

    const loadMoreProducts = async () => {
      const searchTerm = SearchResults.getSearchQuery(this.props);
      await dispatch(
        loadMoreSearchTermResults(searchTerm, numberOfProductsVal, offset),
      );
    };

    let fullLoadedProducts = false;
    let diff = products.total - products.offset;
    if (diff <= products.limit) {
      fullLoadedProducts = true;
    } else {
      fullLoadedProducts = false;
    }
    if (products.total <= 0) {
      fullLoadedProducts = true;
    }

    return (
      <FlexCol>
        <ProductList
          isFetching={searchIsFetching}
          products={products.products}
          addToCart={this.addToCart}
          addToRequest={this.addToRequest}
          inArticle={true}
        />
        <LoadMoreButtonWrapper>
          {!fullLoadedProducts ? (
            <LoadMoreProductsBtn
              text={__('Načitať dalšie produkty')}
              onClick={loadMoreProducts}
              isFetching={false}
            />
          ) : null}
        </LoadMoreButtonWrapper>
        <Pagination
          query={this.getPaginationQuery()}
          totalItems={(products && products.total) || 0}
          limit={numberOfProductsVal}
          offset={(products && products.offset) || 0}
          onOffsetChange={this.handleOffsetChange}
        />
      </FlexCol>
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    const q = this.props.location.query.q;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?q=${q}&offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    const urlAttribs = prop(this, 'props.location.query');
    const urlAttribsObj = urlAttribsToObject(urlAttribs);
    const paramUrl = prop(this, 'props.location.pathname');

    return attribsObjectToUrl(paramUrl, {
      ...urlAttribsObj,
      otherAttribs: {
        ...urlAttribsObj.otherAttribs,
      },
    });
    return `${this.props.location.pathname}?q=${this.props.location.query.q}`;
  };

  private addToCart = (product: any, count: number = 1) => {
    this.props.dispatch(addItemToCart(product, count));
  };

  private addToRequest = () => {
    console.log('add to request');
  };
}

const mapStateToProps = state => ({
  searchIsFetching: searchResultsIsFetchingSelector(state),
  searchedProducts: searchResultsSelector(state),
  lang: langSelector(state),
});

const LoadMoreButtonWrapper = styled(FlexColCenterHorizontal)`
  margin: ${rem(30)} auto;
`;

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SearchResults' })(withRouter(SearchResults)),
);
