import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import {
  MetaTags,
  FlexCol,
  FlexRow,
  ArticleList,
  SitemapList,
  Pagination,
} from 'eshop-defaults';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { connectSsr } from 'ssr-service';
import { __ } from 'react-i18n';
import { loadCMSCategoriesTree, loadCMSArticles } from './actions';
import {
  sitemapSelector,
  allArticlesSelector,
  sitemapIsFetchingSelector,
  articlesIsFetchingSelector,
  sitemapByIdSelector,
} from './selectors';
import API, { ThenArg } from '../../services/API';
import { withRouter, WithRouterProps } from 'react-router';
import { push } from 'react-router-redux';
import { prop } from '../../utilities';
import LazyLoad from 'react-lazyload';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import ToTopButton from '../../components/_helpers/Default/ToTopButton';

interface Props {
  dispatch: (action: any) => void;
  sitemap: ThenArg<typeof API.loadSitemapTree>['sitemap_tree'];
  isFetching: boolean;
  allArticles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  sitemapsById: object;
}

class CMSArticles extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(parentProps) {
    try {
      const { dispatch, location } = parentProps;
      const {
        query: { offset, subid },
      } = location;
      await dispatch(loadCMSCategoriesTree());
      await dispatch(loadCMSArticles(subid, 6, offset));
      dispatch(setBreadCrumbPath(BreadCrumbType.CMS_ARTICLES));
      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: __('Magazín') }} />
          {this.renderCategories()}
          {this.renderArticles()}
        </Wrapper>
        <ToTopButton />
      </>
    );
  }

  public renderCategories = () => {
    const { sitemap } = this.props;
    const subid = this.props.location.query.subid;
    const remappedSitemap =
      sitemap &&
      sitemap.map(s => ({
        name: s.name || '',
        id: s.id || '',
      }));

    return (
      <SitemapList
        currentId={(subid && subid.toString()) || -1}
        handleCategoryChange={this.handleCategoryChange}
        sitemaps={remappedSitemap}
        isBlog={true}
      />
    );
  };

  public renderArticles = () => {
    const {
      allArticles,
      isFetchingArticles,
      location,
      sitemapsById,
    } = this.props;
    const articles = prop(allArticles, 'articles');
    const total = prop(allArticles, 'total', 0);
    const subid = location.query.subid ? location.query.subid.toString() : '-1';
    const offset = prop(location, 'query.offset', 0);
    const catName = sitemapsById[subid] && sitemapsById[subid].name;

    return (
      <ContentWrapper>
        <ArticlesWrapper>
          <ArticleList
            sitemapsById={sitemapsById}
            articles={articles}
            isFetching={isFetchingArticles}
          />
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={total}
            limit={6}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </ArticlesWrapper>
      </ContentWrapper>
    );
  };

  public handleOffsetChange = (newOffset: number) => {
    const subid = this.props.location.query.subid;
    const searchQuery = subid
      ? `?subid=${subid}&offset=${newOffset}`
      : `?offset=${newOffset}`;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: searchQuery,
      }),
    );
  };

  public handleCategoryChange = async (newId: number | string) => {
    // const offset = this.props.location.query.offset;
    const { location, dispatch } = this.props;
    const searchQuery = `?subid=${newId}`;
    dispatch(
      push({
        pathname: location.pathname,
        search: searchQuery,
      }),
    );
  };

  public getPaginationQuery = () => {
    const { location } = this.props;
    const {
      query: { subid },
    } = location;

    return subid
      ? `${location.pathname}?subid=${subid}`
      : `${location.pathname}?`;
  };
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: ${rem(56)} ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: 0 ${rem(24)} ${rem(32)};
  `}
`;

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  justify-content: flex-end;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
  `}
`;

const ArticlesWrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0;
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}
`;

const mapStateToProps = state => {
  return {
    sitemap: sitemapSelector(state),
    isFetching: sitemapIsFetchingSelector(state),
    allArticles: allArticlesSelector(state),
    isFetchingArticles: articlesIsFetchingSelector(state),
    sitemapsById: sitemapByIdSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CMSArticles' })(withRouter(CMSArticles)),
);
