import { AppThunk, RootState } from './../../rootReducer';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import * as cookie from 'react-cookies';
import API, { ThenArg } from '../../services/API';
import {
  ErrorTypeAPI,
  startFetching,
  stopFetching,
} from '../../utilities/redux';
import { saveCartCookie, loadCartCookie, removeCartCookie } from './helpers';
import { langSelector, currencySelector } from '../App/selectors';
import { prop, arrayToObject } from '../../utilities';
import {
  getCartProductInfo,
  getProductBaseInfo,
  getProductDefaultInfo,
} from 'eshop-defaults/lib/utilities/selectors';
import { createNewCart } from './utilities';
import {
  fbAddToCart,
  ga4AddToCart,
} from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';

interface CartState {
  data: ThenArg<typeof API.getCart> | null;
  error: ErrorTypeAPI;
  errorModal: boolean;
  isFetching: boolean;
  deliveryPaymentInfo: {
    data: ThenArg<typeof API.getDeliveryPaymentInfo> | null;
    byId: Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>> | null;
    ids: string[];
    isFetching: boolean;
    error: ErrorTypeAPI;
  };
  addToCartModal: boolean;
  addToCartModalProduct: any;
  addToCartModalIsRequest: boolean;
  wasAddedToCartModal: boolean;
  wasAddedToCartModalProduct: any;
  wasAddedToCartModalCount: number;
  wasAddedToCartModalFetching: boolean;
  wasAddedToCartModalBoughtToo: ThenArg<
    typeof API.loadProductConnections
  > | null;
  freeDeliveryRemainingAmount: number | null;
  showProblems: boolean;
}

const initialState: CartState = {
  data: null,
  isFetching: false,
  error: null,
  errorModal: false,
  deliveryPaymentInfo: {
    data: null,
    isFetching: false,
    error: null,
    byId: null,
    ids: [],
  },
  addToCartModal: false,
  addToCartModalProduct: null,
  addToCartModalIsRequest: false,
  wasAddedToCartModal: false,
  wasAddedToCartModalProduct: null,
  wasAddedToCartModalCount: 0,
  wasAddedToCartModalFetching: false,
  wasAddedToCartModalBoughtToo: null,
  freeDeliveryRemainingAmount: null,
  showProblems: false,
};

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setShowProblems(state, action: PayloadAction<{ show: boolean }>) {
      state.showProblems = action.payload.show;
    },
    setInitialCart(state) {
      state.data = null;
      state.error = null;
      stopFetching(state, '');
    },
    startFetchingCart(state) {
      startFetching(state, '');
    },
    stopFetchingCart(state) {
      stopFetching(state, '');
    },
    fetchCartSuccess(
      state,
      action: PayloadAction<{
        cart: ThenArg<typeof API.getCart>;
      }>,
    ) {
      state.data = action.payload.cart;
      stopFetching(state, '');
    },
    openErrorModal(state) {
      state.errorModal = true;
    },
    setErrorModal(state, action: PayloadAction<boolean>) {
      state.errorModal = action.payload;
    },
    fetchCartError(state, action: PayloadAction<ErrorTypeAPI>) {
      // check if it is not 404
      if (action.payload?.status !== 404) {
        state.error = action.payload;
        if (action.payload) {
          state.errorModal = true;
        }
      }
      stopFetching(state, '');
    },

    startFetchingDelPayInfo(state) {
      startFetching(state, 'deliveryPaymentInfo');
    },
    fetchDelPayInfoSuccess(
      state,
      action: PayloadAction<{
        ids: string[];
        deliveriesById: Record<
          string,
          ThenArg<typeof API.getDeliveryPaymentInfo>
        >;
      }>,
    ) {
      state.deliveryPaymentInfo.ids = action.payload.ids;
      state.deliveryPaymentInfo.byId = action.payload.deliveriesById;
      stopFetching(state, 'deliveryPaymentInfo');
    },
    fetchDelPayInfoError(state, action: PayloadAction<ErrorTypeAPI>) {
      // state.data = null;
      state.error = action.payload;
      stopFetching(state, 'deliveryPaymentInfo');
    },

    setAddToCartModal(
      state,
      action: PayloadAction<{ product?: any; isRequest?: boolean }>,
    ) {
      state.addToCartModal = !state.addToCartModal;
      state.addToCartModalProduct = action.payload.product || null;
      state.addToCartModalIsRequest = action.payload.isRequest || false;
    },

    setWasAddedToCartModal(
      state,
      action: PayloadAction<{ product?: any; count?: number }>,
    ) {
      state.wasAddedToCartModal = !state.wasAddedToCartModal;
      state.wasAddedToCartModalProduct = action.payload.product || null;
      state.wasAddedToCartModalCount = action.payload.count || 0;
    },

    fetchProductInCartRecommended(state) {
      state.wasAddedToCartModalFetching = true;
    },
    setProductInCartRecommendedSuccess(
      state,
      action: PayloadAction<{
        connections: ThenArg<typeof API.loadProductConnections>;
      }>,
    ) {
      state.wasAddedToCartModalBoughtToo = action.payload.connections;
      state['wasAddedToCartModalFetching'] = false;
    },
    setProductInCartRecommendedError(
      state,
      action: PayloadAction<ErrorTypeAPI>,
    ) {
      state.error = action.payload;
      state.wasAddedToCartModalFetching = false;
    },
    setFreeDeliveryRemainingAmount(
      state,
      action: PayloadAction<{
        freeDeliveryRemainingAmount: number | null;
      }>,
    ) {
      state.freeDeliveryRemainingAmount =
        action.payload.freeDeliveryRemainingAmount;
    },
  },
});

export const {
  startFetchingCart,
  fetchCartSuccess,
  fetchCartError,
  startFetchingDelPayInfo,
  fetchDelPayInfoSuccess,
  fetchDelPayInfoError,
  setAddToCartModal,
  setWasAddedToCartModal,
  fetchProductInCartRecommended,
  setProductInCartRecommendedSuccess,
  setProductInCartRecommendedError,
  setInitialCart,
  setFreeDeliveryRemainingAmount,
  setShowProblems,
  stopFetchingCart,
  setErrorModal,
} = cart.actions;
export default cart.reducer;

const cartDomainSelector = (state: RootState) => state.cart;

export const cartDataSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data', null),
);

export const cartIdSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data.id', null),
);

export const cartIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'isFetching', null),
);

export const cartErrorSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'error', null),
);

export const cartShowProblemsSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'showProblems', false),
);

export const cartDelPayInfoIdsSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.ids', null),
);

export const cartDelPayInfoByIdSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.byId', null),
);

export const cartDelPayInfoIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.isFetching', null),
);

export const addToCartModalVisibleSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModal', false),
);

export const addToCartModalProductSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalProduct', null),
);

export const addToCartModalIsRequestSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalIsRequest', false),
);

export const wasAddedToCartModalProductSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalProduct', null),
);

export const wasAddedToCartModalSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModal', false),
);

export const wasAddedToCartModalCountSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalCount', 0),
);

export const wasAddedToCartModalIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalFetching', false),
);

export const wasAddedToCartModalBoughtTooSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalBoughtToo', null),
);

export const freeDeliveryRemainingAmountSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'freeDeliveryRemainingAmount', null),
);

export const errorModalSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'errorModal', false),
);

export const setAddToCartModalVisibility = (
  isRequest?: boolean,
  product?: any,
): AppThunk => dispatch => {
  dispatch(setAddToCartModal({ product, isRequest }));
};

export const setWasAddedToCartModalVisibility = (
  product?: any,
  count?: number,
): AppThunk => dispatch => {
  dispatch(setWasAddedToCartModal({ product, count }));
};

export const fetchCart = (refresh: boolean = false): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const userCartId = prop(state, 'auth.user.cartId');
  const cookieCartId = loadCartCookie();

  if (
    refresh ||
    (userCartId && userCartId !== cookieCartId) ||
    !cartDataSelector(state)
  ) {
    let cartId: string | undefined = userCartId || cookieCartId;

    if (!cartId) {
      return;
    }

    if (cartId) {
      saveCartCookie(cartId);
      try {
        dispatch(startFetchingCart());
        const cart = await API.getCart(cartId, { vatGroups: 1 });
        dispatch(fetchCartSuccess({ cart }));
      } catch (err) {
        if (err.details.status === 404) {
          const id = await createNewCart(state, API);
          removeCartCookie();
          saveCartCookie(id);
          dispatch(fetchCart(refresh));
        } else {
          if (
            prop(err, 'details.description').includes(
              'Není možné přidat takové',
            ) ||
            prop(err, 'details.description').includes('Nie je možné pridať')
          ) {
            dispatch(fetchCartError(err));
          }

          if (window) {
            // window.alert(
            //   prop(
            //     err,
            //     'details.description',
            //     'Nastala chyba pri pridávaní do košíka',
            //   ),
            // );
          }
        }
      }
    }
  }
};

export const addItemToCartFromModal = (count: number = 1): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const product = addToCartModalProductSelector(state);
  const isRequest = addToCartModalIsRequestSelector(state);
  const { productId, goodId } = getProductDefaultInfo(product);

  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }

  try {
    dispatch(startFetchingCart());
    const cart = await API.addCartItem(
      cartId,
      goodId,
      productId,
      {
        count,
        isDemand: isRequest ? 1 : 0,
        currencyId: 'EUR',
      },
      { xAcceptLanguage: langSelector(state) },
    );

    if (window.fbq) {
      fbAddToCart(product);
    }

    dispatch(fetchCartSuccess({ cart }));
    dispatch(setAddToCartModalVisibility());
    dispatch(setWasAddedToCartModalVisibility(product, count));
  } catch (err) {
    if (err.details.status === 404) {
      const id = await createNewCart(state, API);
      removeCartCookie();
      saveCartCookie(id);
      dispatch(addItemToCartFromModal(count));
    } else {
      if (
        prop(err, 'details.description').includes('Není možné přidat takové') ||
        prop(err, 'details.description').includes('Nie je možné pridať')
      ) {
        dispatch(fetchCartError(err));
      }

      if (window) {
        // window.alert(
        //   prop(
        //     err,
        //     'details.description',
        //     'Nastala chyba pri pridávaní do košíka',
        //   ),
        // );
      }
    }
  }
};

export const addItemToCart = (
  product: any,
  count: number = 1,
  isDemand: boolean = false,
  openModalAfter: boolean = true,
  callBack?: any,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const { productId, goodId } = getProductDefaultInfo(product);
  const currency = currencySelector(state);

  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }

  try {
    dispatch(startFetchingCart());
    const cart = await API.addCartItem(
      cartId,
      goodId,
      productId,
      {
        count,
        isDemand: isDemand ? 1 : 0,
        currencyId: currency,
      },
      { xAcceptLanguage: langSelector(state) },
    );

    const gProduct = cart.items.filter(p => p.good.good_id === goodId);

    if (window.gtag) {
      // @ts-ignore
      const g = gProduct.find(p => !p.isFromSale);
      if (g) {
        ga4AddToCart(
          g.good.product_id,
          g.product.name,
          count,
          g.good.final_price,
          getGoogleAdsId(),
          currency,
        );
      }
    }

    if (window.fbq) {
      fbAddToCart(product);
    }

    dispatch(fetchCartSuccess({ cart }));
    dispatch(loadFreeDeliveryRemainingAmount(cartId));
    if (openModalAfter) {
      // dispatch(setAddToCartModalVisibility(false, product));
      dispatch(setWasAddedToCartModalVisibility(product, count));
    }
    if (callBack) {
      callBack();
    }
  } catch (err) {
    console.log({ err });
    if (
      err.details.status === 404 &&
      !prop(err, 'message.description').includes('Produkt sa nenašiel')
    ) {
      const id = await createNewCart(state, API);
      removeCartCookie();
      saveCartCookie(id);
      dispatch(addItemToCart(product, count, isDemand, openModalAfter));
    } else {
      if (
        prop(err, 'details.description').includes('Není možné přidat takové') ||
        prop(err, 'details.description').includes('Nie je možné pridať')
      ) {
        dispatch(fetchCartError(err));
      } else {
        dispatch(fetchCartError(null));
      }

      if (window) {
        // window.alert(
        //   prop(
        //     err,
        //     'details.description',
        //     'Nastala chyba pri pridávaní do košíka',
        //   ),
        // );
      }
    }
  }
};
export const addSaleGiftToCart = (
  product_id: number,
  sale_id: number,
  good_id: number,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();

  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }

  try {
    dispatch(startFetchingCart());
    const cart = await API.addCartItem(
      cartId,
      good_id ? good_id : 0,
      product_id,
      {
        count: 1,
        isDemand: 0,
        currencyId: 'EUR',
        fromSale: 1,
        saleId: sale_id,
      },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
    dispatch(loadFreeDeliveryRemainingAmount(cartId));
  } catch (err) {
    console.log({ err });
    if (err.details.status === 404) {
    } else {
      if (
        prop(err, 'details.description').includes('Není možné přidat takové') ||
        prop(err, 'details.description').includes('Nie je možné pridať')
      ) {
        dispatch(fetchCartError(err));
      } else {
        dispatch(fetchCartError(null));
      }

      if (window) {
        // window.alert(
        //   prop(
        //     err,
        //     'details.description',
        //     'Nastala chyba pri pridávaní do košíka',
        //   ),
        // );
      }
    }
  }
};

export const removeItemFromCart = (
  goodId: number,
  productId: number,
  isSale: any,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.deleteCartItem(id, productId, goodId, {
      fromSale: !!isSale,
    });
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const changeItemNote = (
  goodId: number,
  note: string,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.addItemNote(
      id,
      goodId,
      {},
      { note },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const changeCartNote = (note: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.addNote(
      id,
      {},
      { note },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const updateCart = (
  data: any,
  vatGroups: boolean = true,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.updateCart(
      id,
      { vatGroups: vatGroups ? 1 : 0 },
      data,
    );

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const addGiftCard = (cardNumber: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());

    let cart = await API.addCard(
      id,
      {},
      {
        card_type: 'GIFTCARD',
        card_number: cardNumber,
      },
    );
    cart = await API.getCart(id, { vatGroups: 1 });

    if (cart && cart.problems) {
      const giftcardProblem = !cart.problems.some(p => p.type === 'warning');
      if (!giftcardProblem) {
        dispatch(showProblemsInCart(true));
      }
    }

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const removeGiftCard = (cardNumber: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());

    const cart = await API.removeCard(id, 'giftcard', cardNumber);

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const fetchDeliveryPaymentInfo = (
  startFetchingInfoLoader = true,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    if (startFetchingInfoLoader) {
      dispatch(startFetchingDelPayInfo());
    }
    const info = await API.getDeliveryPaymentInfo(
      id,
      {},
      {
        xAcceptLanguage: langSelector(state),
        xCurrency: currencySelector(state),
      },
    );

    const deliveriesById = arrayToObject(
      prop(info, 'delivery', []),
      'delivery_id',
    );
    const ids = prop(info, 'delivery', []).map(d => d.delivery_id);
    dispatch(fetchDelPayInfoSuccess({ ids, deliveriesById }));
  } catch (err) {
    console.log(err);
    dispatch(fetchDelPayInfoError(err));
  }
};

export const loadProductInCartRecommended = (id: number): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(fetchProductInCartRecommended());

    const lang = langSelector(getState());
    const connections = await API.loadProductConnections(
      id,
      {
        connectionType: 'ALTERNATIVE',
        limit: 4,
      },
      { xAcceptLanguage: lang },
    );
    dispatch(setProductInCartRecommendedSuccess({ connections }));
  } catch (e) {
    dispatch(setProductInCartRecommendedError(e));
  }
};

export const resetCart = (): AppThunk => async (dispatch, getState, API) => {
  const state = getState();

  dispatch(setInitialCart());
  await createNewCart(state, API);
};

export const loadFreeDeliveryRemainingAmount = (
  cartId?: string,
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!cartId) {
      cartId = loadCartCookie();
    }

    if (cartId) {
      const lang = langSelector(state);
      const currency = currencySelector(state);
      const amount = await API.getFreeDeliveryInfo(cartId, {
        xAcceptLanguage: lang,
        xCurrency: currency,
      });

      if (
        amount &&
        typeof amount.remaining_price === 'number' &&
        amount?.remaining_price >= 0
      ) {
        dispatch(
          setFreeDeliveryRemainingAmount({
            freeDeliveryRemainingAmount: amount.remaining_price,
          }),
        );
      } else {
        dispatch(
          setFreeDeliveryRemainingAmount({ freeDeliveryRemainingAmount: null }),
        );
      }
    } else {
      dispatch(
        setFreeDeliveryRemainingAmount({ freeDeliveryRemainingAmount: null }),
      );
    }
  } catch (e) {
    console.log({ error: e });
    dispatch(
      setFreeDeliveryRemainingAmount({ freeDeliveryRemainingAmount: null }),
    );
  }
};

export const showProblemsInCart = (
  show: boolean,
): AppThunk => async dispatch => {
  dispatch(setShowProblems({ show }));
};

export const closeErrorModal = (): AppThunk => async dispatch => {
  dispatch(setErrorModal(false));
};

export const addVoucherCard = (cardNumber: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);
  try {
    dispatch(startFetchingCart());

    let cart = await API.addCard(
      id,
      {},
      {
        card_type: 'ANS_VOUCHER',
        card_number: cardNumber,
      },
    );
    cart = await API.getCart(id, { vatGroups: 1 });

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const removeVoucherCard = (cardNumber: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());

    const cart = await API.removeCard(id, 'ans_voucher', cardNumber);

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};
