import { FlexCol, FlexRowCenter } from 'eshop-defaults';
import { rem } from 'polished';
import * as React from 'react';
import { __ } from 'react-i18n';
import { Link } from 'react-router';
import styled from '../../theme/styled-components';
import NotFound from './NotFound';
import { HeaderLogo as HeaderSmallLogo } from 'eshop-defaults';
// import { __ } from "react-i18n";

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
`;

const WrapperContainer = styled.div`
  width: 100%;
  max-width: ${rem(1440)};
  padding: 0 ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
    padding: 0 ;
  `};
`;

const TopWrapper = styled(FlexCol)`
  padding: ${rem(200)} 0;
  border-bottom: ${rem(1)} solid rgba(204, 204, 204, 0.25);
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m580`
    padding: ${rem(70)} ${rem(6)};
    border: 0;
  `};
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(40)};
  color: ${({ theme }) => theme.colors.textPrimary};
  text-align: center;
  font-weight: 700;
  margin-bottom: ${rem(24)};
`;

const NotFoundWrapper = styled(FlexRowCenter)`
  text-align: center;
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: ${rem(32)};
  flex-flow: column;
  max-width: ${rem(400)};
  margin: ${rem(16)} auto ${rem(40)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
  `}
`;

const Icon = styled.img`
  width: ${rem(60)};
  margin-left: ${rem(10)};

  ${({ theme }) => theme.mediab.m580`
     width: ${rem(30)};
  `}
`;

const HomeLink = styled.a`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-weight: 500;
  padding: ${rem(16)};
  border: ${rem(1)} solid #606060;
  width: initial;
  margin: 0 auto;
  text-decoration: none;
  max-width: ${rem(350)};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EmailLink = styled.a`
  color: ${({ theme }) => theme.color.green};
  font-weight: 600;
`;

const Image = styled.img`
  max-width: ${rem(350)};
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-bottom: ${rem(40)};
`;

export function ErrorBoundaryNew({ lang }) {
  let logoSrc = '/images/golfcentrum/logo-extended.svg';

  switch (lang) {
    case 'cz':
      logoSrc = '/images/golfcentrum/logo-extended-cz.svg';
      break;
    case 'en':
      logoSrc = '/images/golfcentrum/logo-extended-online.svg';
      break;
    default:
      logoSrc = '/images/golfcentrum/logo-extended.svg';
  }

  return (
    <Wrapper>
      <WrapperContainer>
        <TopWrapper>
          <Image src={logoSrc} alt="Logo" />
          <Text>{__('Vyskytla sa chyba!')}</Text>
          <NotFoundWrapper>
            {__(
              'Na odstránení pracujeme. V prípade, že chyba pretrváva, kontaktujte nás prosím na adrese ',
            )}
            <EmailLink href={`mailto:${__('eshop@golfcentrum.sk')}`}>
              {__('eshop@golfcentrum.sk')}
            </EmailLink>
          </NotFoundWrapper>
          <HomeLink href="/">{__('Prejsť na hlavnú stránku')}</HomeLink>
        </TopWrapper>
      </WrapperContainer>
    </Wrapper>
  );
}
