import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FooterRowWrapper,
  TextP,
  FlexBetween,
  FlexCol,
  FooterItem,
  FooterMenuCol,
  FlexRow,
  FlexColCenter,
  IconImg,
  GolfcentrumFooterLastRow as FooterLastRow,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { Link } from 'react-router';

const FooterWrapper = styled.footer`
  z-index: 1;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  flex-flow: column;
  @media print {
    display: none;
  }
  ${({ theme }) => theme.mediab.m580`
   margin-top: 0;
`};
`;

const MapWrapper = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
    display: none;  
  `}
`;

const MenuMapWrapper = styled(FlexBetween)`
  align-items: flex-start;
  width: 100%;
`;

const MenuWrapper = styled(FlexRow)`
  flex: 1;
  justify-content: space-between;

  ${({ theme }) => theme.mediab.m600`
    flex-flow: column;  
  `}
`;

const MobileMapWrapper = styled(FlexColCenter)`
  margin-bottom: ${rem(32)};
`;

const MapImage = styled.div`
  width: ${rem(262)};
  height: ${rem(180)};
  margin-right: ${rem(48)};
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${props =>
    `linear-gradient(270deg, #55555500 65%,  #555555 100%), url(${props.src})`};

  ${({ theme }) => theme.mediab.l1050`
   margin-right: ${rem(12)};
  `}
`;

const MapTextWrapper = styled(FlexCol)`
  max-width: ${rem(220)};
`;

const MapLink = styled.a`
  color: ${({ theme }) => theme.colors.footerLink};
  font-size: ${rem(14)};
  margin-top: ${rem(16)};
`;

const DesktopWrapper = styled.div`
  display: block;

  ${({ theme }) => theme.mediab.l925`
    display: none;  
  `}
`;

const MobileWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediab.l925`
    display: block;
  `}
`;

const NeedHelp = styled(FlexRowCenterVertical)`
  justify-content: flex-start;
  margin-right: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
      margin-bottom: ${rem(16)};
      margin-right: 0;
  `};
`;

const FooterImg = styled.img`
  width: ${rem(55)};
  height: ${rem(18)};
  margin-left: ${rem(24)};
  &:first-child {
    margin-left: 0;
  }
`;

const SmallTextP = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.colors.white};
  line-height: ${rem(17)};
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediab.l925`
      margin-bottom: ${rem(16)};
      margin-bottom: ${rem(16)};
  `};
`;

const SmallTextPhoneEmailLink = styled.a`
  text-decoration: none;
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.colors.white};
  line-height: ${rem(17)};

  &:hover {
    text-decoration: underline;
  }
`;

const MobileAddress = styled(SmallTextP)`
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${rem(16)};
`;

const DotDelimeter = styled.span`
  margin: 0 ${rem(12)};
  height: ${rem(2)};
  width: ${rem(2)};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: inline-block;

  ${({ theme }) => theme.mediab.m580`
    margin: 0 ${rem(8)};
  `}
`;

const ItemsWrapper = styled(FlexRowCenterVertical)`
  justify-content: flex-start;
  margin-right: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
      margin-bottom: ${rem(8)};
      margin-right: 0;
      flex-flow: column;
  `};
`;

const ItemsWrapper2 = styled(FlexRowCenterVertical)`
  width: 100%;
  justify-content: center;
  padding-top: ${rem(32)};
  border-top: ${({ theme }) => theme.borders.footer};

  ${({ theme }) => theme.mediab.l925`
  `};
`;

const TextLink = styled(Link)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.color.white};
  line-height: ${rem(17)};
  display: flex;
  align-items: center;
  text-decoration: none;

  ${({ theme }) => theme.mediab.l925`
      margin-bottom: ${rem(16)};
      margin-bottom: ${rem(16)};
  `};
  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  items: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  phone: string;
  email: string;
}

interface State {
  breakInnerWidth: number;
}

class Footer extends React.Component<Props, State> {
  public render() {
    return (
      <FooterWrapper>
        {/*{this.renderFirstRow()}*/}
        {this.renderSecondRow()}
        {this.renderThirdRow()}
      </FooterWrapper>
    );
  }

  private renderFirstRow = () => {
    const { items }: any = this.props;

    const mapSrc = '/images/zemplin/map.png';
    const mapsAdressLink =
      'https://www.google.com/maps?q=ZEMPLÍN+s.r.o.+–+centrum+vykurovania';

    if (!items) {
      return null;
    }

    return (
      <FooterRowWrapper>
        {' '}
        <MenuMapWrapper>
          <MenuWrapper>
            {items &&
              items.map((item: any) => (
                <FooterMenuCol key={item.id} title={item.name}>
                  {item.children &&
                    item.children.map(child => (
                      <FooterItem
                        key={child.id}
                        url={`/${child.url}`}
                        title={child.name}
                        hasIcon={true}
                      />
                    ))}
                </FooterMenuCol>
              ))}
          </MenuWrapper>
          <MapWrapper>
            <MapImage src={mapSrc} alt="mapa" />
            <MapTextWrapper>
              <SmallTextP>
                {'Jasenovská 31, 066 01 Humenné Slovenská republika'}
              </SmallTextP>
              <MapLink href={mapsAdressLink} target="_blank">
                {'Zobraziť na mape'}
              </MapLink>
            </MapTextWrapper>
          </MapWrapper>
        </MenuMapWrapper>
      </FooterRowWrapper>
    );
  };

  private renderSecondRow = () => {
    const { items }: any = this.props;
    const { phone, email } = this.props;
    const visaSrc = '/images/golfcentrum/visa.svg';
    const masterCardSrc = '/images/golfcentrum/mastercard.svg';
    const mapSrc = '/images/zemplin/map.png';
    const locationSrc = '/images/zemplin/location.svg';
    const mapsAdressLink =
      'https://www.google.com/maps?q=ZEMPLÍN+s.r.o.+–+centrum+vykurovania';

    const totalItems = items.length;
    return (
      <>
        <DesktopWrapper>
          <FooterRowWrapper>
            <ItemsWrapper>
              {items &&
                items.map((item: any, index) => (
                  <TextLink key={item.id} to={`/${item.url}`}>
                    {item.name}
                    {totalItems !== index + 1 && <DotDelimeter key={item.id} />}
                  </TextLink>
                ))}
            </ItemsWrapper>
            <FlexRowCenterVertical>
              <FooterImg src={visaSrc} alt="visa" />
              <FooterImg src={masterCardSrc} alt="mastercard" />
            </FlexRowCenterVertical>
          </FooterRowWrapper>
        </DesktopWrapper>
        <MobileWrapper>
          <FooterRowWrapper>
            <ItemsWrapper>
              {items &&
                items.map((item: any, index) => (
                  <TextLink key={item.id} to={`/${item.url}`}>
                    {item.name}
                  </TextLink>
                ))}
            </ItemsWrapper>
            <ItemsWrapper2>
              <FooterImg src={visaSrc} alt="visa" />
              <FooterImg src={masterCardSrc} alt="mastercard" />
            </ItemsWrapper2>
          </FooterRowWrapper>
        </MobileWrapper>
      </>
    );
  };

  private renderThirdRow = () => {
    return (
      <FooterLastRow
        leftText={__('Copyright © 2023 Golfcentrum')}
        withoutSitemapLink={true}
      />
    );
  };
}

export default Footer;
