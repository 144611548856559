import * as actionTypes from './constants';
import APIClass from '../../services/API';
import { langSelector } from '../Category/selectors';
import { setRedirect } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { currencySelector } from '../App/selectors';

const requestProductDetail = () => ({
  type: actionTypes.REQUEST_PRODUCT_DETAIL,
});

const receiveProductDetailSuccess = productDetail => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_SUCCESS,
  payload: {
    productDetail,
  },
});

const receiveProductDetailError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetail = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetail());

      const lang = langSelector(getState());
      const currency = currencySelector(getState());
      const productDetail = await API.loadProduct(
        id,
        {
          withParentCategories: '1',
        },
        { xAcceptLanguage: lang, xCurrency: currency },
      );

      if (productDetail && !productDetail.isactive) {
        const url: string = process.env.REACT_APP_BASE_URL as string;
        dispatch(setRedirect(url, 301));
      }

      dispatch(receiveProductDetailSuccess(productDetail));
    } catch (e) {
      dispatch(receiveProductDetailError(e));
    }
  };
};

const requestProductDetailRelated = () => ({
  type: actionTypes.REQUEST_PRODUCT_RELATED_DETAIL,
});

const receiveProductDetailRelatedSuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductDetailRelatedError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_RELATED_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailRelated = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailRelated());

      const lang = langSelector(getState());
      const connections = await APIClass.loadProductConnections(
        id,
        {
          connectionType: 'ALTERNATIVE',
          limit: 12,
        },
        { xAcceptLanguage: lang },
      );

      dispatch(receiveProductDetailRelatedSuccess(connections));
    } catch (e) {
      dispatch(receiveProductDetailRelatedError(e));
    }
  };
};

const requestProductDetailAccessory = () => ({
  type: actionTypes.REQUEST_PRODUCT_ACCESSORY_DETAIL,
});

const receiveProductDetailAccessorySuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_ACCESSORY_DETAIL_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductDetailAccessoryError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ACCESSORY_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailAccessory = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailAccessory());

      const lang = langSelector(getState());
      const connections = await APIClass.loadProductConnections(
        id,
        {
          connectionType: 'ACCESSORY',
          limit: 12,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveProductDetailAccessorySuccess(connections));
    } catch (e) {
      dispatch(receiveProductDetailAccessoryError(e));
    }
  };
};

const requestProductDetailColorsVariants = () => ({
  type: actionTypes.REQUEST_PRODUCT_DETAIL_COLORS_VARIANTS,
});

const receiveProductDetailColorsVariantsSuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_COLORS_VARIANTS_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductDetailColorsVariantsError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_COLORS_VARIANTS_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailColorsVariants = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailColorsVariants());

      const lang = langSelector(getState());
      const connections = await API.loadProductConnections(
        id,
        {
          connectionType: 'COLORS',
          limit: 12,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveProductDetailColorsVariantsSuccess(connections));
    } catch (e) {
      dispatch(receiveProductDetailColorsVariantsError(e));
    }
  };
};
