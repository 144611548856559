import * as cookies from 'react-cookies';
import {
  CURRENCY_COOKIE,
  LANG_COOKIE,
  SET_CURRENCY,
  SET_DEFAULT_TITLE,
  SET_LANGUAGE,
  SET_TOP_MENU_ITEMS,
  SET_FOOTER_DATA,
  SET_CONTACT_INFO,
  SET_SOCIAL_URLS,
  SET_META_TAGS,
  REQUEST_REGISTER_USER,
  RECEIVE_REGISTER_USER_SUCCESS,
  RECEIVE_REGISTER_USER_FAILURE,
  OPEN_DIMMER,
  CLOSE_DIMMER,
  REQUEST_CREATED_ORDER,
  RECEIVE_CREATED_ORDER_SUCCESS,
  RECEIVE_CREATED_ORDER_ERROR,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  SET_INVOICE_DATA,
  SET_CONTROL_INSTITUTE_DATA,
  SET_POINTS_AMOUNT_RATIO,
  SET_FREE_DELIVERY_INFO,
  SET_TOP_MENU_BRANDS,
  REQUEST_SEARCH_TERM,
  RECEIVE_SEARCH_TERM_SUCCESS,
  RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS,
  REQUEST_PARTIAL_SEARCH_TERM,
  SET_SEARCH_RESULTS_FILTERS,
  ERROR_TOP_MENU_ITEMS,
  REQUEST_TOP_MENU_ITEMS,
  RECEIVE_SITEMAP_SUCCESS,
  REQUEST_PARTIAL_CATEGORY_SEARCH_TERM,
  RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS,
  SET_CURRENT_THEME,
  SET_SETTINGS_LOADED,
  REQUEST_OTHER_TEXT,
  RECEIVE_OTHER_TEXT_SUCCESS,
  RECEIVE_OTHER_TEXT_ERROR,
  RECEIVE_SITEMAP_SUBTREE_SUCCESS,
  SET_NOT_FOUND,
  RECEIVE_MORE_SEARCH_TERM_SUCCESS,
  SET_HOSTNAME,
} from './constants';
import { generalInitialState } from './general-reducer';
import { cookiesExpiresDate, prop } from '../../utilities';
import {
  currencySelector,
  langSelector,
  searchResultsIsFetchingSelector,
  searchResultsOffsetSelector,
} from './selectors';
import APIClass from '../../services/API';
import { searchOffsetSelector } from '../Search/selectors';

export const setLanguage = lang => {
  const fromCookie = cookies.load(LANG_COOKIE);
  if (fromCookie) {
    lang = fromCookie;
  } else if (!lang) {
    lang = generalInitialState.lang;
  }

  cookies.save(LANG_COOKIE, lang, { path: '/', expires: cookiesExpiresDate() });

  return {
    type: SET_LANGUAGE,
    payload: {
      lang,
    },
  };
};

export const setHostname = (hostname: string) => {
  return {
    type: SET_HOSTNAME,
    payload: {
      hostname,
    },
  };
};

export const changeLanguage = newLang => {
  cookies.save(LANG_COOKIE, newLang, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
  return {
    type: SET_LANGUAGE,
    payload: {
      lang: newLang,
    },
  };
};

export const setCurrency = currency => {
  if (!currency) {
    currency = generalInitialState.currency;
  }

  const currencyFromCookies = cookies.load(CURRENCY_COOKIE);
  if (currencyFromCookies) {
    currency = currencyFromCookies;
  } else if (!currency) {
    currency = generalInitialState.currency;
  }

  cookies.save(CURRENCY_COOKIE, currency, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
  return {
    type: SET_CURRENCY,
    payload: {
      currency,
    },
  };
};

export const handleCurrencyChange = () => {
  let currency;

  const currencyFromCookies = cookies.load(CURRENCY_COOKIE);
  if (currencyFromCookies) {
    currency = currencyFromCookies;
  } else if (!currencyFromCookies) {
    currency = generalInitialState.currency;
  }

  let changedCurrency;
  switch (currency) {
    case 'CZK':
      changedCurrency = 'EUR';
      break;
    case 'EUR':
      changedCurrency = 'CZK';
      break;
    default:
      changedCurrency = 'EUR';
      break;
  }

  cookies.save(CURRENCY_COOKIE, changedCurrency, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
  return {
    type: SET_CURRENCY,
    payload: {
      changedCurrency,
    },
  };
};

export const setDefaultMetaTitle = defaultTitle => {
  return {
    type: SET_DEFAULT_TITLE,
    payload: {
      defaultTitle,
    },
  };
};

export const loadDefaultMetaTitle = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const [{ value: defaultTitle }] = await API.getSettingByName(
        'meta_title',
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );

      dispatch(setDefaultMetaTitle(defaultTitle));
    } catch (e) {
      console.log(e);
    }
  };
};

export const requestTopMenuItems = () => {
  return {
    type: REQUEST_TOP_MENU_ITEMS,
  };
};

export const errorTopMenuItems = () => {
  return {
    type: ERROR_TOP_MENU_ITEMS,
  };
};

export const setTopMenuItems = menuItems => {
  return {
    type: SET_TOP_MENU_ITEMS,
    payload: {
      menuItems,
    },
  };
};

export const loadTopMenuItems = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestTopMenuItems());
      const currentState = getState();

      const sitemaps = await API.loadSitemapSubtree(
        'HEADER',
        {},
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );
      const menuItems = sitemaps.sitemap_tree;

      dispatch(setTopMenuItems(menuItems));
    } catch (e) {
      console.log(e);
      dispatch(errorTopMenuItems());
    }
  };
};

export const setTopMenuBrands = brands => {
  return {
    type: SET_TOP_MENU_BRANDS,
    payload: {
      brands,
    },
  };
};

export const loadTopMenuBrands = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const brands = await APIClass.loadBrands(
        { sort: 'brand_name', sortDir: 'asc' },
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );
      const topMenuBrands = brands.brands.filter(
        brand => brand.brand_top === true,
      );

      dispatch(setTopMenuBrands(topMenuBrands));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setFooterData = footerData => {
  return {
    type: SET_FOOTER_DATA,
    payload: {
      footerData,
    },
  };
};

export const loadFooterData = () => {
  return async (dispatch, getState, API) => {
    try {
      const lang = getState().general.lang;
      const currentState = getState();
      if (!currentState.general.footerData) {
        const footerData = await API.loadSitemapSubtree(
          'FOOTER',
          {},
          { xAcceptLanguage: lang },
        );
        dispatch(setFooterData(footerData));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const receiveSitemapSubtreeSuccess = (
  sitemapSubtreeData,
  sitemapUid: string,
) => {
  return {
    type: RECEIVE_SITEMAP_SUBTREE_SUCCESS,
    payload: {
      sitemapSubtreeData,
      sitemapUid,
    },
  };
};

export const loadSitemapSubtreeByUid = (uid: string) => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const lang = langSelector(currentState);
      // if (!currentState.general.footerData) {
      const data = await API.loadSitemapSubtree(
        uid,
        {},
        { xAcceptLanguage: lang },
      );
      dispatch(receiveSitemapSubtreeSuccess(data, uid));
      // }
    } catch (e) {
      console.log(e);
    }
  };
};

export const setContactInfo = contactInfo => {
  return {
    type: SET_CONTACT_INFO,
    payload: {
      contactInfo,
    },
  };
};

export const loadContactInfo = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let contactInfo = await API.getSettingByName('email,tel, ', {
        xAcceptLanguage: currentState.general.lang,
      });

      const [{ value: contactEmail }, { value: contactTel }] = contactInfo;

      contactInfo = {
        contactEmail,
        contactTel,
      };

      dispatch(setContactInfo(contactInfo));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setSocialUrls = socialUrls => {
  return {
    type: SET_SOCIAL_URLS,
    payload: {
      socialUrls,
    },
  };
};

export const loadSocialUrls = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let socialUrls = await API.getSettingByName(
        'socials_facebook,socials_instagram,socials_youtube,socials_blog,heureka_url',
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );

      const [
        { value: fbUrl },
        { value: igUrl },
        { value: partyBlog },
        { value: heurekaUrl },
      ] = socialUrls;

      socialUrls = {
        fbUrl,
        igUrl,
        partyBlog,
        heurekaUrl,
      };

      dispatch(setSocialUrls(socialUrls));
    } catch (e) {
      console.log(e);
    }
  };
};

const setSettingsLoaded = (isLoaded: boolean) => {
  return {
    type: SET_SETTINGS_LOADED,
    payload: {
      isLoaded,
    },
  };
};

export const setNotFound = notFound => {
  return {
    type: SET_NOT_FOUND,
    payload: {
      notFound,
    },
  };
};

export const setMetaTags = metaTags => {
  return {
    type: SET_META_TAGS,
    payload: {
      metaTags,
    },
  };
};

export const loadMetaTags = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let metaTags = await API.getSettingByName(
        'meta_description,meta_author,meta_keywords',
        {
          xAccpetLanguage: currentState.general.lang,
        },
      );

      const [
        { value: metaDescription },
        { value: metaKeywords },
        { value: metaAuthor },
      ] = metaTags;

      metaTags = {
        metaDescription,
        metaKeywords,
        metaAuthor,
      };

      dispatch(setMetaTags(metaTags));
    } catch (e) {
      console.log(e);
    }
  };
};

export const showDimmer = (lockScrollBar: boolean = true) => ({
  type: OPEN_DIMMER,
  payload: {
    lockScrollBar,
  },
});

export const hideDimmer = () => ({
  type: CLOSE_DIMMER,
});

const requestCreatedOrder = () => ({
  type: REQUEST_CREATED_ORDER,
});

const receivecreatedOrderSuccess = createdOrder => ({
  type: RECEIVE_CREATED_ORDER_SUCCESS,
  payload: {
    createdOrder,
  },
});

const receiveCreatedOrderError = error => ({
  type: RECEIVE_CREATED_ORDER_ERROR,
  payload: {
    error,
  },
});

export const loadCreatedOrder = (createdOrderId: string) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCreatedOrder());
      const order = await API.getOrder(createdOrderId);
      dispatch(receivecreatedOrderSuccess(order));
    } catch (e) {
      dispatch(receiveCreatedOrderError(e));
    }
  };
};

export const openLoginModal = () => ({
  type: OPEN_LOGIN_MODAL,
});

export const hideLoginModal = () => ({
  type: CLOSE_LOGIN_MODAL,
});

const requestRegisterUser = () => ({
  type: REQUEST_REGISTER_USER,
});

const registerUserSuccess = () => ({
  type: RECEIVE_REGISTER_USER_SUCCESS,
});

const registerUserError = error => ({
  type: RECEIVE_REGISTER_USER_FAILURE,
  payload: {
    error,
  },
});

export const registerUser = (
  email: string,
  password: string,
  passwordAgain: string,
  termsAccept: boolean,
  newsletterAccept: boolean = false,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestRegisterUser());
      const lang: string = langSelector(getState()) || 'sk';
      await API.registerUser(
        {},
        {
          email,
          lang,
          password,
          password_again: passwordAgain,
          newsletter_accept: newsletterAccept,
        },
      );
      dispatch(registerUserSuccess());
    } catch (e) {
      dispatch(registerUserError(e.details.description));
    }
  };
};

export const setInvoiceData = invoiceData => ({
  type: SET_INVOICE_DATA,
  payload: {
    invoiceData,
  },
});

export const setControlInstituteData = controlInstituteData => ({
  type: SET_CONTROL_INSTITUTE_DATA,
  payload: {
    controlInstituteData,
  },
});

export const setPointsAmountRatio = pointsAmountRatio => ({
  type: SET_POINTS_AMOUNT_RATIO,
  payload: {
    pointsAmountRatio,
  },
});

export const setFreeDeliveryInfo = freeDeliveryInfo => ({
  type: SET_FREE_DELIVERY_INFO,
  payload: {
    freeDeliveryInfo,
  },
});

export const loadDefaultSettings = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      if (!currentState.general.footerData) {
        const lang = getState().general.lang;
        /* const currency = getState().general.currency;

        const freeDeliveryInfo = await API.getGeneralFreeDeliveryInfo(
          {},
          { xAcceptLanguage: lang, xCurrency: currency },
        );


        console.log(freeDeliveryInfo);
        dispatch(setFreeDeliveryInfo(freeDeliveryInfo));*/

        const data = await API.getSettingByName(
          'infoEmail,infoPhone,meta_title,meta_description,meta_keywords,meta_author,',
          {},
          {
            xAcceptLanguage: lang,
          },
        );

        const [{ value: infoEmail }, { value: infoPhone }] = data;

        let title = '';
        if (data && data[2]) {
          title = prop(data[2], 'value');
        }

        let metaDescription = '';
        if (data && data[3]) {
          metaDescription = prop(data[3], 'value');
        }

        let metaKeywords = '';
        if (data && data[4]) {
          metaKeywords = prop(data[4], 'value');
        }

        let metaAuthor = '';
        if (data && data[5]) {
          metaAuthor = prop(data[5], 'value');
        }

        if (title) {
          dispatch(setDefaultMetaTitle(title));
        }
        const contactInfo = {
          email: infoEmail,
          phone: infoPhone,
        };

        if (contactInfo) {
          dispatch(setContactInfo(contactInfo));
        }

        const metaTags = {
          description: metaDescription,
          keywords: metaKeywords,
          title,
        };

        if (metaTags) {
          dispatch(setMetaTags(metaTags));
        }

        dispatch(setSettingsLoaded(true));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const requestSearchTerm = (searchTerm: string) => ({
  type: REQUEST_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receiveSearchTermResultsSuccess = (searchTerm, products) => ({
  type: RECEIVE_SEARCH_TERM_SUCCESS,
  payload: {
    products,
    searchTerm,
  },
});
const receiveSearchTermMoreResultsSuccess = (searchTerm, products) => ({
  type: RECEIVE_MORE_SEARCH_TERM_SUCCESS,
  payload: {
    products,
    searchTerm,
  },
});

export const loadSearchTermResults = (
  searchTerm: string,
  limit: number = 12,
  offset: number = 0,
) => {
  return async (dispatch, getState, API) => {
    try {
      if (!searchResultsIsFetchingSelector(getState())) {
        dispatch(requestSearchTerm(searchTerm));
        dispatch(setSearchResultsFilters({ offset }));

        const lang = langSelector(getState());
        const currency: string = currencySelector(getState());

        let products = await API.searchProducts(
          {
            limit,
            q: searchTerm,
            offset,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            searchBrand: '1',
            withPublish: '1',
          },
          { xAcceptLanguage: lang, xCurrency: currency },
        );

        if (products.total['value'] >= 0) {
          products['total'] = products.total['value'];
        }
        dispatch(receiveSearchTermResultsSuccess(searchTerm, products));
      }
    } catch (e) {
      console.error(e);
    }
  };
};
export const loadMoreSearchTermResults = (
  searchTerm: string,
  limit: any = 12,
  offset: number = 0,
) => {
  return async (dispatch, getState, API) => {
    try {
      if (!searchResultsIsFetchingSelector(getState())) {
        // dispatch(requestSearchTerm(searchTerm));
        const oldOffset = searchResultsOffsetSelector(getState());
        let newOffset = parseInt(oldOffset) + parseInt(limit);
        dispatch(setSearchResultsFilters({ offset: newOffset }));
        const lang = langSelector(getState());
        const currency: string = currencySelector(getState());
        const products = await API.searchProducts(
          {
            limit,
            q: searchTerm,
            offset: newOffset,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            searchBrand: '1',
            withPublish: '1',
          },
          { xAcceptLanguage: lang, xCurrency: currency },
        );

        dispatch(receiveSearchTermMoreResultsSuccess(searchTerm, products));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const loadSitemap = lang => {
  return async (dispatch, getState, API) => {
    try {
      // const content = await APIClass.getHtmlSitemap(lang);
      // await dispatch(receiveSitemapSuccess(content));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setSearchResultsFilters = filters => ({
  type: SET_SEARCH_RESULTS_FILTERS,
  payload: {
    filters,
  },
});

const requestPartialSearchTerm = (searchTerm: string) => ({
  type: REQUEST_PARTIAL_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receivePartialSearchTermResultsSuccess = (searchTerm, products) => ({
  type: RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS,
  payload: {
    products,
    searchTerm,
  },
});

export const loadPartialSearchTermResults = (
  searchTerm: string,
  limit: number = 4,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestPartialSearchTerm(searchTerm));
      const lang: string = langSelector(getState());
      const currency: string = currencySelector(getState());
      const products = await API.searchProducts(
        {
          limit,
          q: searchTerm,
          withAttribs: '0',
          withGifts: '0',
          withBrand: '0',
          columns: 'product_name,url,picture',
          withPublish: '1',
        },
        {
          xAcceptLanguage: lang,
          xCurrency: currency,
        },
      );

      dispatch(receivePartialSearchTermResultsSuccess(searchTerm, products));
    } catch (e) {
      console.error(e);
    }
  };
};

const requestPartialCategorySearchTerm = (searchTerm: string) => ({
  type: REQUEST_PARTIAL_CATEGORY_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receivePartialCategorySearchTermResultsSuccess = (
  searchTerm,
  products,
) => ({
  type: RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS,
  payload: {
    products,
    searchTerm,
  },
});

export const loadPartialCategorySearchTermResults = (searchTerm: string) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestPartialCategorySearchTerm(searchTerm));
      const lang: string = langSelector(getState());

      const categories = await APIClass.loadCategories(
        {
          q: searchTerm,
          withPublish: '1',
        },
        { xAcceptLanguage: lang },
      );

      dispatch(
        receivePartialCategorySearchTermResultsSuccess(searchTerm, categories),
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const setCurrentTheme = theme => ({
  type: SET_CURRENT_THEME,
  payload: {
    theme,
  },
});

const requestOtherText = () => ({
  type: REQUEST_OTHER_TEXT,
});

const receiveOtherTextSuccess = (otherText, textId: string) => ({
  type: RECEIVE_OTHER_TEXT_SUCCESS,
  payload: {
    otherText,
    textId,
  },
});

const receiveOtherTextError = error => ({
  type: RECEIVE_OTHER_TEXT_ERROR,
  payload: {
    error,
  },
});

export const loadOtherText = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestOtherText());

      const lang = langSelector(getState());
      const otherText = await API.loadOtherTexts(
        id,
        {},
        { xAcceptLanguage: lang },
      );
      dispatch(receiveOtherTextSuccess(otherText, id));
    } catch (e) {
      dispatch(receiveOtherTextError(e));
    }
  };
};

export const resetToken = () => {
  return async (dispatch, getState, API) => {
    API.setToken('');
  };
};
