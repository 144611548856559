import styled, { StyledFunction } from 'styled-components';
import { rem } from 'polished';
import DefaultButton from '../../_helpers/forms/Button/Button';
import {
  FlexRowCenterHorizontal,
  Flexbox,
  FlexRow,
  FlexColCenterHorizontal,
} from 'eshop-defaults/lib';
import { Link } from 'react-router';

export const MainContainer = styled.div`
  max-width: ${rem(960)};
  width: 100%;
  margin: ${rem(30)} auto 0;
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => theme.boxShadow.default};

  ${({ theme }) => theme.mediab.m580`
    margin-top: 0;
  `}
`;

const BoldFunc: StyledFunction<
  { fontSize?: number } & React.HTMLAttributes<HTMLSpanElement>
> = styled.span;

export const Bold = BoldFunc`
  font-weight: 700;
  font-size: ${({ fontSize }) => (fontSize ? rem(fontSize) : 'inherit')};
  font-family: ${({ theme }) => theme.font.secondary};
  text-transform: uppercase;
`;

export const SuccessText = styled.div`
  color: ${({ theme }) => theme.color.success};
`;

export const DangerText = styled.div`
  color: ${({ theme }) => theme.color.brand};
`;

export const Button = styled(DefaultButton)`
  width: ${rem(282)};
  height: ${rem(60)};
  background: ${({ theme }) => theme.color.brand};
  color: ${({ theme }) => theme.color.white};
  border-radius: ${rem(4)};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(20)};
  font-weight: 700;
  text-transform: uppercase;
`;

export const CartLink = styled(Link)`
  color: ${({ theme }) => theme.color.link};

  &:hover {
    cursor: pointer;
  }
`;

const DropdownArrowFunc: StyledFunction<
  { rotate?: boolean } & React.HTMLAttributes<HTMLDivElement>
> = styled(Flexbox);

export const DropdownArrow = DropdownArrowFunc`
  width: ${rem(8)};
  height: ${rem(4)};
  position: absolute;
  right: 0;
  top: 0;
  transition: transform ${({ theme }) => theme.transition.duration} 
    ${({ theme }) => theme.transition.function};
  
  ${({ rotate }) => `
    transform: ${rotate ? 'rotate(180deg)' : 'undefined'} ;
  `}

`;

// CART FOOTER

export const CartFreeLinkBottom = styled(FlexRowCenterHorizontal)`
  margin: ${rem(30)} auto ${rem(60)};
  font-size: ${rem(14)};
  & > div {
    margin-right: ${rem(5)};
  }

  & > div:last-of-type {
    margin-right: 0;
  }

  ${({ theme }) => theme.mediab.m810`
    display: none;
  `}
`;

export const TelNumber = styled.div`
  color: ${({ theme }) => theme.color.link};
  text-decoration: underline;
`;

export const SecondaryText = styled.div`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.color.textSecondary};
`;

const IconWrapperFunc: StyledFunction<
  { width?: number; height?: number } & React.HTMLAttributes<HTMLDivElement>
> = styled(Flexbox);

export const IconWrapper = IconWrapperFunc`
  width: ${({ width }) => (width ? rem(width) : '100%')};
  height: ${({ height }) => (height ? rem(height) : '100%')};
`;

export const Grid = styled(Flexbox)`
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: 580px) and (max-width: 960px) {
    justify-content: flex-start;
    & > div {
      flex: 1 1 45%;
      max-width: unset;
    }

    & > div:nth-of-type(2n) {
      margin-left: ${rem(10)};
    }
  }

  ${({ theme }) => theme.mediab.m580`
    & > div {
      max-width: unset;
    }

    & > div:last-of-type {
      margin-bottom: 0;
    }
  `}
`;

export const GridItem = styled(FlexRow)`
  max-width: ${rem(282)};
  width: 100%;
  margin-bottom: ${rem(15)};
  align-items: flex-end;
`;

export const AdvantagesBoxArrow = styled.div`
  background: #fff;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: ${rem(10)};
    margin-left: ${rem(-10)};
  }

  &:before {
    border-color: rgba(38, 178, 94, 0);
    border-bottom-color: #26b25e;
    border-width: ${rem(13)};
    margin-left: ${rem(-13)};
  }
`;

export const EmptyCart = styled(FlexColCenterHorizontal)`
  max-width: ${rem(960)};
  width: 100%;
  margin: ${rem(40)} auto 0;
  background: ${({ theme }) => theme.color.white};
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => theme.blogShadows.default};
  padding: ${rem(20)};
`;

export const EmptyCartText = styled.div`
  margin: ${rem(20)} 0;
  max-width: ${rem(600)};
  text-align: center;
`;
