import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexBetween,
  FlexRow,
  FlexCol,
  MobileNavigationItem as MobileNavItem,
  ItemType,
  IconImg,
  TextP,
  MobileNavSubItem,
  FlexRowCenter,
  SvgIcon,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getImagePath } from '../../utilities/product';
import { Loader } from '../_helpers/Loader/Loader';
import { Link } from 'react-router';
import { __ } from 'react-i18n';
import { IconType } from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import {
  resolveCategoryName,
  resolveCategoryUrlString,
} from '../../utilities/category';
import { resolveShortCategoryUrl } from '../../containers/Category/selectors';

export interface Props {
  items: ThenArg<typeof API.loadTree>;
  closeMenu: () => void;
}

function MobileNavigation({ items, closeMenu }: Props) {
  const [level, setLevel] = useState<number>(1);
  const [title, setTitle] = useState<string>('Menu');
  const [currentItems, setCurrentItems] = useState<any>(items);
  const [previous, setPrevious] = useState<
    Array<{ items: any; title: string }>
  >([]);
  console.log({ items });
  const upLevel = (id: number = -1) => {
    const currentLvl = level;

    if (currentLvl >= 1 && id >= 0) {
      const item = currentItems[id];
      if (item) {
        const newItems = currentItems[id].children;
        const newTitle = currentItems[id].category_name;
        const newPrevious = previous;
        newPrevious.push({
          items: currentItems,
          title,
        });
        setPrevious(newPrevious);
        setLevel(level + 1);
        setTitle(newTitle);
        setCurrentItems(newItems);
      }
    }
  };

  const downLevel = () => {
    const currentLvl = level;

    if (currentLvl > 1) {
      setLevel(level - 1);
      setTitle(previous[previous.length - 1].title);
      setCurrentItems(previous[previous.length - 1].items);

      const newPrevious = previous;
      newPrevious.splice(-1, 1);
      setPrevious(newPrevious);
    }
  };

  return (
    <Wrapper>
      <IconTitleWrapper>
        <SvgIcon
          icon={level === 1 ? IconType.close : IconType.back}
          alt="Zavrieť menu"
          viewBox={level === 1 ? '0 0 24 24' : '0 0 16 16'}
          width={level === 1 ? 24 : 16}
          height={level === 1 ? 24 : 16}
          cursor={'pointer'}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={level === 1 ? closeMenu : downLevel}
        />
        <Title>{title}</Title>
      </IconTitleWrapper>
      {renderContentItems(level, upLevel, closeMenu, currentItems)}
    </Wrapper>
  );
}

const renderContentItems = (level, upLevel, closeMenu, currentItems) => {
  const rightSrc = '/images/zemplin/list-arrow_black.svg';
  //
  // if (level === 1) {
  //   return (
  //     <MenuItemsWrapper>
  //       {items &&
  //       items.map((item, i) => {
  //         return (
  //           <NavigationItem
  //             onClick={item.children && item.children.length > 0 ? toggleNav : ''}
  //             key={item.category_id}
  //             type={ItemType.PRIMARY}
  //             title={
  //               <FlexRowCenter>
  //                 <span>{item.category_name}</span>
  //                 {item.children && item.children.length > 0 &&
  //                   <SvgIcon
  //                     width={16}
  //                     height={16}
  //                     icon={IconType.caretDownBold}
  //                     alt={'cart down'}
  //                     viewBox="0 0 16 16"
  //                     marginLeft={12}
  //                   />
  //                 }
  //               </FlexRowCenter>
  //             }
  //           />
  //
  //         );
  //       })}
  //     </MenuItemsWrapper>
  //   );
  // }
  if (level >= 1) {
    return (
      currentItems &&
      currentItems.map((category, i) => {
        // if (
        //   (!category.counts || category.counts === 0) &&
        //   category.category_name.toLowerCase() !== 'nakupovať novinky'
        // ) {
        //   return null;
        // }
        return (
          <MobileNavSubItem
            key={category.category_id}
            imgSrc={
              category.image
                ? getImagePath(
                    category.image,
                    {
                      width: 128,
                      height: 128,
                    },
                    false,
                    false,
                    true,
                  )
                : '/images/zemplin/placeholder.svg'
            }
            showArrow={category.children.length > 0}
            arrowSrc={rightSrc}
            name={resolveCategoryName(category)}
            url={resolveCategoryUrlString(category)}
            id={category.category_id}
            onClickLink={closeMenu}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => upLevel(i)}
          />
        );
      })
    );
  }

  return null;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const IconTitleWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${rem(24)};
  padding: 0 ${rem(16)};
`;

const MenuItemsWrapper = styled(FlexCol)``;

const Title = styled(TextP)`
  font-weight: 700;
  font-size: ${rem(14)};
`;

export { MobileNavigation };
