import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { MetaTags, LoginForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { UrlHashService } from '../../services/HashService';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { push, replace } from 'react-router-redux';
import { onlyPublic } from '../../utilities/auth';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { cartIdSelector } from '../Cart/cartSlice';
import { langSelector } from '../Category/selectors';
import {
  resolveBaseApiUrlByLang,
  resolveBaseUrlByLang,
} from '../../configureTrans';
import { __ } from 'react-i18n';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
  lang: string;
}

interface State {
  error: string;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  margin: ${rem(80)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: 0 ${rem(16)};
     margin: ${rem(32)} auto;
  `}
`;

class LoginPage extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  public componentDidMount() {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.LOGIN, null));

    const { hash } = this.props.location;
    const urlHashService = new UrlHashService(hash.replace('#', ''));
    const accessToken = urlHashService.get('access_token');
    const error = decodeURIComponent(urlHashService.get('error'));
    const previousUrl = decodeURIComponent(urlHashService.get('state'));

    const backtocart = hash.search('backtocart');

    if (accessToken) {
      this.props.dispatch(loginUser(accessToken));
      if (backtocart > 0) {
        this.props.dispatch(replace('/kosik/osobne-udaje'));
      }

      // window.location.href = previousUrl;
      // this.props.dispatch(push('/'));
    } else if (error) {
      this.setState({
        error: error && error !== 'null' ? error : '',
      });
    }
  }

  public render() {
    const {
      cartId,
      location: { pathname, search },
      lang,
    } = this.props;
    const { error } = this.state;

    return (
      <>
        <Wrapper>
          <MetaTags tags={{ title: __('Prihlásenie | GOLF CENTRUM.sk') }} />
          <LoginForm
            baseApiUrl={resolveBaseApiUrlByLang(lang)}
            baseUrl={resolveBaseUrlByLang(lang)}
            cartId={cartId}
            pathname={pathname}
            search={search}
            error={error}
            lang={lang}
          />
        </Wrapper>
      </>
    );
  }

  public handleFacebookLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/facebook?state="${this.props.cartId}"`;
  };

  public handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/google?state="${this.props.cartId}"`;
  };
}

const mapStateToProps = state => {
  return {
    cartId: cartIdSelector(state),
    lang: langSelector(state),
  };
};

const withRouterLoginPage = onlyPublic(LoginPage);
export default connect(mapStateToProps)(withRouter(withRouterLoginPage));
