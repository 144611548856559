import { FlexCol } from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import {
  cartErrorSelector,
  errorModalSelector,
  closeErrorModal,
} from '../Cart/cartSlice';
import { __ } from 'react-i18n';
import { H1 } from '../../components/_helpers/Heading/Heading';
import Modal from '../../components/_helpers/Default/Modal';
import { WithRouterProps, withRouter } from 'react-router';

interface Props {
  dispatch: any;
  error: any;
  errorModalSelector: boolean;
}

function CartErrorModal({
  dispatch,
  error,
  errorModalSelector,
}: Props & WithRouterProps) {
  const closeModal = () => {
    dispatch(closeErrorModal());
  };

  return (
    <>
      {errorModalSelector && (
        <Modal
          size="small"
          showX={true}
          closeModal={closeModal}
          style={{
            height: 'auto',
            paddingBottom: '22px',
            color: 'black',
            width: 'fit-content',
            margin: '0 20px',
          }}
        >
          <ModalErrorWrapper>
            <Title>{__('Chyba')}</Title>
            {error && (
              <ErrorText>
                {error.details && error.details.description}
              </ErrorText>
            )}
            <CloseButton onClick={closeModal}>{__('Zatvoriť')}</CloseButton>
          </ModalErrorWrapper>
        </Modal>
      )}
    </>
  );
}
const ErrorText = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-top: ${rem(32)};
`;

const Title = styled(H1)`
  font-size: ${rem(26)};
  position: absolute;
  top: -${rem(8)};
`;

const CloseButton = styled.button`
  background-color: ${({ theme }) => theme.color.green};
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-left: auto;
  magin-top: ${rem(20)};
  width: fit-content;
`;
const ModalErrorWrapper = styled(FlexCol)`
  position: relative;
  max-width: ${rem(480)};
`;

const mapStateToProps = state => {
  return {
    error: cartErrorSelector(state),
    errorModalSelector: errorModalSelector(state),
  };
};

export default connect(mapStateToProps)(withRouter(CartErrorModal));
