import * as React from 'react';
import { __, __r } from 'react-i18n';
import styled, { withTheme } from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexCol,
  FlexRow,
  FlexColCenter,
  ProductTabSmall,
  Chooser,
  IconImg,
  ZemplinLoaderWrapper,
  NoItemsWrapper,
  HomepageCategoryLink,
  GolfcentrumCard as Card,
  GolfcentrumProductTabDefault as ProductTabDefault,
  GolfcentrumIconWithText as IconWithText,
  ArticleTabDefault,
  GolfcentrumCategoryHeader as CategoryHeader,
} from 'eshop-defaults';
import {
  resolveCategoryUrl,
  resolveShortCategoryUrl,
} from 'eshop-defaults/lib/utilities/selectors';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import { prop, deepFind } from '../../utilities';
import { getImagePath } from '../../utilities/product';
import { LoaderWrapper } from 'eshop-defaults/lib/components/Zemplin/General/LoaderWrapper';
import { Link } from 'react-router';
import {
  loadHomeNewProducts,
  loadHomeSaleProducts,
} from '../../containers/Home/actions';
import { useState } from 'react';
import { useEffect } from 'react';
import { useInterval } from '../../utilities/hooks';
import { NewsletterForm } from './NewsletterForm';
// import SliderComponent from '../_helpers/Slider/Slider';
import Lightbox from '../_helpers/Slider/SecondSlider';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import {
  DesktopWrapper,
  MobileWrapper,
} from '../_helpers/Default/ResponsiveWrappers';
import ToTopButton from '../_helpers/Default/ToTopButton';
import {
  resolveCategoryName,
  resolveCategoryUrlString,
} from '../../utilities/category';
import InfoBanner from '../_helpers/Default/InfoBanner';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const bannerDevices = [
  {
    device: 'desktop',
    widthFrom: 925,
    widthTo: Infinity,
  },
  {
    device: 'phone',
    widthFrom: 0,
    widthTo: 924,
  },
];

interface Props {
  banners?: any;
  dispatch: any;
  phone?: string;
  theme: any;
  categories: ThenArg<typeof API.loadTree>;
  productCategoriesIsFetching: boolean;
  addToCart: any;
  addToRequest: any;
  newProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  saleProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  landingPageProducts: {
    productsData: ThenArg<typeof API.loadProductList>;
    isFetching: false;
  };
  topArticles: {
    articles: ThenArg<typeof API.loadArticles>;
    total: number;
  };
  newArticles: {
    articles: ThenArg<typeof API.loadArticles>;
    total: number;
  };
  firstCategoryId: number;
  otherText: any;
  sitemapSubtreeData: any;
}

function Home(props: Props) {
  const { firstCategoryId, otherText, sitemapSubtreeData, dispatch } = props;

  const [newsCategoryId, setNewsCategoryId] = useState(
    firstCategoryId || firstCategoryId === 0 ? firstCategoryId : 1,
  );
  const [saleCategoryId, setSaleCategoryId] = useState(
    firstCategoryId || firstCategoryId === 0 ? firstCategoryId : 1,
  );

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.NONE));
  }, [dispatch]);

  const { BENEFITS: benefits } = sitemapSubtreeData;
  const secondInfoTexts = prop(benefits, 'sitemap_tree', []);

  const changeCurrentNewsIndex = id => {
    const { dispatch } = props;
    setNewsCategoryId(id);
    dispatch(loadHomeNewProducts(id));
  };

  const changeCurrentSaleIndex = id => {
    const { dispatch } = props;
    setSaleCategoryId(id);
    dispatch(loadHomeSaleProducts(id));
  };

  const { width: windowWidth } = useWindowDimensions();
  const renderBanners = () => {
    const deviceData = bannerDevices.find(
      device =>
        device.widthFrom <= windowWidth && device.widthTo >= windowWidth,
    );
    const device =
      deviceData && deviceData.device ? deviceData.device : 'desktop';
    const { banners } = props;

    //@todo banner isFetching -> loader
    // if(isFetching) {
    //   return <ZemplinLoaderWrapper height={360} />
    // }

    const desktopBannersToRender = banners
      ? banners.filter(item => item.device === 'desktop')
      : [];

    const mobileBannersToRender = banners
      ? banners.filter(item => item.device === 'phone')
      : [];

    if (!desktopBannersToRender || desktopBannersToRender.length === 0) {
      return null;
    }

    if (!mobileBannersToRender || mobileBannersToRender.length === 0) {
      return null;
    }
    desktopBannersToRender.sort((a, b) => (a.b_sort > b.b_sort ? 1 : -1));
    mobileBannersToRender.sort((a, b) => (a.b_sort > b.b_sort ? 1 : -1));

    return (
      <BannerWrapper>
        <BannerDesktopWrapper>
          <Lightbox
            type="hp-banner"
            slides={desktopBannersToRender}
            showButtons={true}
            showDots={false}
          />
        </BannerDesktopWrapper>
        <BannerMobileWrapper>
          <Lightbox
            type="hp-banner"
            slides={mobileBannersToRender}
            showButtons={true}
            showDots={false}
          />
        </BannerMobileWrapper>
      </BannerWrapper>
    );
  };

  const renderCategoryLinks = () => {
    const { theme, categories, productCategoriesIsFetching } = props;
    if (productCategoriesIsFetching || productCategoriesIsFetching === null) {
      return <ZemplinLoaderWrapper height={380} />;
    }

    const categoryLink = deepFind(categories, 'is_popular', true).slice(0, 2);
    return (
      <CategoryLinkWrapper>
        {categoryLink &&
          categoryLink.map((category, i) => {
            return <HomepageCategoryLink category={category} key={i} />;
          })}
      </CategoryLinkWrapper>
    );
  };

  const renderCategories = () => {
    const { theme, categories, productCategoriesIsFetching } = props;
    const hpCategories = deepFind(categories, 'is_recommended', true).slice(
      0,
      4,
    );

    if (productCategoriesIsFetching || productCategoriesIsFetching === null) {
      return <ZemplinLoaderWrapper height={380} />;
    }

    return (
      <CategoryCardsWrapper>
        {hpCategories &&
          hpCategories.map((cat: any) => {
            const { category_id, url, image, weburl } = cat;
            const category_name = resolveCategoryName(cat);
            return (
              <Card
                key={category_id}
                imageSrc={
                  image
                    ? getImagePath(
                        image,
                        { width: 328, height: 437 },
                        false,
                        false,
                        true,
                      )
                    : '/images/zemplin/placeholder.svg'
                }
                url={resolveCategoryUrlString(cat)}
                title={category_name}
                alt={`Obrázok ku ${category_name}`}
              />
            );
          })}
      </CategoryCardsWrapper>
    );
  };

  const renderNewProducts = () => {
    const {
      addToCart,
      addToRequest,
      newProducts,
      categories,
      productCategoriesIsFetching,
      topArticles,
      landingPageProducts,
    } = props;
    const isFetching = prop(newProducts, 'isFetching');

    if (
      productCategoriesIsFetching ||
      (!productCategoriesIsFetching &&
        !isFetching &&
        !newProducts.productsData &&
        !categories)
    ) {
      return <LoaderWrapper height={270} />;
    }

    const articles = prop(topArticles, 'articles', []);
    const products = prop(landingPageProducts, 'productsData.products', []);

    return (
      <NewProductsWrapper>
        <ProductTitleWrapper>
          <Title to={articles ? prop(articles[0], 'url') : ''}>
            {articles ? prop(articles[0], 'name') : ''}
          </Title>
        </ProductTitleWrapper>
        <ProductCategoryWrapper>
          <ArticlesProductWrapper>
            {/*  {isFetching ? (*/}
            {/*  <LoaderWrapper height={150} />*/}
            {/*) :*/}
            {articles && articles.length && (
              <ArticleTabDefault
                article={articles[0]}
                anotationAsTitle={true}
                maxWidth={'328px'}
                isMagazin={true}
              />
            )}
            {products && products.length > 0 && (
              <Lightbox type="hp-product" slides={products} showDots={true} />
            )}
          </ArticlesProductWrapper>
        </ProductCategoryWrapper>
      </NewProductsWrapper>
    );
  };

  const renderNewArticle = () => {
    const { newArticles } = props;

    //@TODO load article sitemap
    const articles = prop(newArticles, 'articles', null);

    return articles ? (
      articles.length ? (
        <NewProductsWrapper>
          <ProductTitleMagazinWrapper>
            <Title to={__r('routes:magazin', '/magazin')}>
              {__('Magazín')}
            </Title>
          </ProductTitleMagazinWrapper>
          <ProductCategoryWrapper>
            <ProductWrapper>
              {articles && articles.length > 0 && (
                <Lightbox type="hp-article" slides={articles} showDots={true} />
              )}
            </ProductWrapper>
          </ProductCategoryWrapper>
        </NewProductsWrapper>
      ) : null
    ) : null;
  };

  const renderCategoryHeader = () => {
    const { categories } = props;
    const isFetching = false;

    if (isFetching) {
      return <LoaderWrapper height={270} />;
    }

    const topCategory = deepFind(categories, 'is_recommended', true)
      .filter(item => item.is_top)
      .slice(0, 1)
      .pop();

    if (!topCategory) {
      return null;
    }

    return (
      <CategoryHeaderWrapper>
        <CategoryHeader category={topCategory} btnText={__('Nakupovať')} />
      </CategoryHeaderWrapper>
    );
  };

  const renderSaleProducts = () => {
    const {
      addToCart,
      addToRequest,
      saleProducts,
      productCategoriesIsFetching,
      categories,
    } = props;
    const isFetching = prop(saleProducts, 'isFetching');

    if (
      productCategoriesIsFetching ||
      (!productCategoriesIsFetching &&
        !isFetching &&
        !saleProducts.productsData &&
        !categories)
    ) {
      return <LoaderWrapper height={270} />;
    }

    const productsData = prop(saleProducts, 'productsData', {});
    const products: ThenArg<
      typeof API.searchProducts
    >['products'] = productsData
      ? prop(productsData[saleCategoryId], 'products', [])
      : [];

    return (
      <>
        <ProductTitleWrapper>
          <SaleProductsImageWrapper>
            <IconImg
              width={16}
              height={16}
              src={'/images/zemplin/percent.svg'}
              alt="percenta"
            />
          </SaleProductsImageWrapper>
          <Title to={'/vypredaj'}>{__('Výpredaj')}</Title>
        </ProductTitleWrapper>
        <ProductCategoryWrapper>
          <Chooser
            items={categories}
            currentId={saleCategoryId}
            onItemClick={changeCurrentSaleIndex}
          />
          <ProductWrapper>
            {isFetching ? (
              <LoaderWrapper height={150} />
            ) : products && products.length > 0 ? (
              products.map((p: any) => (
                <ProductTabSmall
                  key={p.product_id}
                  addToCart={addToCart}
                  addToRequest={addToRequest}
                  product={p}
                />
              ))
            ) : (
              <NoItemsWrapper
                text={'Kategória neobsahuje žiadne zľavnené produkty'}
                justify={'center'}
              />
            )}
          </ProductWrapper>
        </ProductCategoryWrapper>
      </>
    );
  };

  return (
    <>
      {renderBanners()}
      <InfoBanner
        secondInfoTexts={secondInfoTexts}
        homeInfoBanner={true}
      />{' '}
      <MainWrapper className="container container--wide">
        <MetaTags
          tags={{
            title: __('Golfový obchod'),
            description: `Golf e-shop na golfové palice, golfové bagy, golfové
loptičky, golfové oblečenie a golfovú obuv. Kompletné vybavenie pre
amatérskych a profesionálnych hráčov golfu.`,
            keywords: `golfové oblečenie, golfová obuv, golfové palice, golfový
obchod, golf e-shop`,
          }}
        />
        {renderCategoryLinks()}
        {renderCategories()}
        {renderCategoryHeader()}
        {renderNewProducts()}
        {renderNewArticle()}
        {/*{renderSaleProducts()}*/}
        <NewsletterForm otherText={otherText} />
        <ToTopButton />
      </MainWrapper>
    </>
  );
}

const CategoryLinkWrapper = styled(FlexRow)`
  margin-bottom: ${rem(56)};
  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    align-items: center;
    margin-bottom: ${rem(32)};
    margin-top: ${rem(32)};
  `}
`;

const MainWrapper = styled.div`
  padding: ${rem(56)} ${rem(24)} 0;
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
     padding: ${rem(32)} ${rem(16)} 0;
  `}
`;

const CategoryCardsWrapper = styled(FlexRow)`
  margin-bottom: ${rem(56)};
  ${({ theme }) => theme.mediab.l925`
     flex-flow: column;
     align-items: center;
     margin-bottom: 0;
  `}
`;

const ProductWrapper = styled(FlexRow)`
  background-color: ${({ theme }) => theme.color.white};
  width: 100%;
  height: ${rem(450)};
  ${({ theme }) => theme.mediab.l925`
   height:  ${rem(480)};
  `}
`;

const ArticlesProductWrapper = styled(FlexRow)`
  background-color: ${({ theme }) => theme.color.white};
  width: 100%;
  ${({ theme }) => theme.mediab.l925`
    flex-direction: column;
 `}
`;

const MobileProductWrapper = styled.div`
  display: none;
  margin-right: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
   display: block;
  `}
`;

const NewProductsImageWrapper = styled(FlexColCenter)`
  width: ${rem(48)};
  height: ${rem(48)};
  padding: ${rem(14)};
  background-color: #2dcc8d;
  margin-right: ${rem(16)};
  border-radius: ${rem(2)};
`;

const SaleProductsImageWrapper = styled(NewProductsImageWrapper)`
  background-color: #ff2d58;
`;

const ProductTitleWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(32)};
  ${({ theme }) => theme.mediab.l925`
    margin-bottom: 0
  `}
`;
const ProductTitleMagazinWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(32)};
  ${({ theme }) => theme.mediab.l925`
    margin-bottom: ${rem(68)};
  `}
`;

const Title = styled(Link)`
  text-decoration: none;
  font-size: ${rem(48)};
  font-family: ${({ theme }) => theme.font.secondary};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: ${rem(56)};
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  ${({ theme }) => theme.mediab.l925`
   font-size: ${rem(38)};
   line-height: 1.2;
  `}
`;

const ProductCategoryWrapper = styled(FlexCol)``;

const NewProductsWrapper = styled(FlexCol)`
  /* margin-bottom: ${rem(56)}; */

  ${({ theme }) => theme.mediab.l925`
    margin: 0
    position: relative;
  `}
`;

const CategoryHeaderWrapper = styled(FlexRow)`
  margin-bottom: ${rem(56)};
`;

const BannerWrapper = styled(FlexRow)`
  background-color: ${({ theme }) => theme.color.gray04};
  justify-content: center;
  width: 100%;
  max-width: ${rem(1456)};
  margin: 0 auto;

  ${({ theme }) => theme.mediab.l925`
     margin-bottom: ${rem(8)};
  `}
`;

const Banner = styled(FlexRow)``;

const BannerImage = styled.img``;

const BannerDesktopWrapper = styled(DesktopWrapper)`
  width: 100%;
`;

const BannerMobileWrapper = styled(MobileWrapper)`
  width: 100%;
`;

export default withTheme(Home);
