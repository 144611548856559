import moment from 'moment';
import React from 'react';
import { __ } from 'react-i18n';

const mediamgrUrl = process.env.REACT_APP_MEDIA_MANAGER_IMAGES_URL;

export const getProp = (object, path, defaultValue: any = '') => {
  if (path === undefined || path === null || path.length <= 0) {
    return defaultValue;
  }
  if (typeof path !== 'string') {
    throw new Error(`Invalid getProp argument path of type: ${typeof path}`);
  }
  const val = path.split('.').reduce((o, x) => {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined' || val === null) {
    return defaultValue;
  }
  return val;
};

export const getSectionUrl = (section: { url?: string; _id?: string }) => {
  if (section.url) {
    return `/${section.url}`;
  } else {
    return `/section/${section._id}`;
  }
};

export const getArticleUrl = (article: { url: string; _id: string }) => {
  if (article.url) {
    return `/${article.url}`;
  } else {
    return `/article/${article._id}`;
  }
};

export const getWidgetPictureUri = (
  item: { picture: { uri: string } },
  size = '',
): string => {
  return mediamgrUrl + '/' + size + '/' + item.picture.uri;
};

export const getBannerPictureUri = (
  item: { uri?: string },
  size: string = '',
): string => {
  return mediamgrUrl + '/' + size + '/' + item.uri;
};

export const getIdFromProps = props => {
  let id = props.id;
  if (!id) {
    id = getProp(props, 'params.id', '');
  }
  return String(id);
};

export const getPictureUrl = (item, width = 600, defaultPicUrl) => {
  const pictureUri = getProp(item, 'picture.uri', null);
  let imgUrl = defaultPicUrl;
  if (pictureUri) {
    imgUrl = `${process.env.REACT_APP_MEDIA_MANAGER_IMAGES_URL}/${width}x0xresize/${pictureUri}`;
  }
  return imgUrl;
};

export const prop = (object, path, defaultValue: any = null) => {
  const val = path.split('.').reduce((o, x) => {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined') {
    return defaultValue;
  }
  return val;
};

export const countDecimals = (i: number) => {
  if (Math.floor(i.valueOf()) === i.valueOf()) {
    return 0;
  }
  return i.toString().split('.')[1].length || 0;
};

export const propByKey = (obj, key, def) => {
  if (obj !== undefined && obj[key] !== undefined) {
    return obj[key];
  }

  return def;
};

export const getNumericQueryProp = (obj, key, def) => {
  if (obj !== undefined && obj[key] !== undefined) {
    if (!isNaN(obj[key])) {
      return obj[key];
    }
  }

  return def;
};

export function now(format: string = 'YYYY-MM-DD HH:mm:ss'): string {
  return moment().format(format);
}

export function formatDate(
  date: Date,
  format: string = 'YYYY-MM-DD HH:mm:ss',
): string {
  return moment(date).format(format);
}

export function formatMoment(
  date: moment.Moment,
  format: string = 'YYYY-MM-DD HH:mm:ss',
): string {
  return date.format(format);
}

export const stringToMoment = (date: string | null) => {
  if (date === null) {
    return null;
  }
  return moment(date, 'YYYY-MM-DD HH:mm:ss');
};

export function formatPrice(
  price: number,
  currency: string,
  decimalPlaces: number = 2,
  zeroPriceText: boolean = false,
) {
  if (price) {
    let priceValue: string;

    if (Number.isInteger(price)) {
      priceValue = `${price},${'0'.repeat(decimalPlaces)}`;
    } else {
      priceValue = price.toFixed(decimalPlaces).replace('.', ',');
    }

    if (zeroPriceText && price === 0) {
      return __('ZADARMO');
    }

    switch (currency) {
      case 'EUR':
        return (
          <>
            {priceValue}
            &nbsp;€
          </>
        );
      case 'CZK':
        return (
          <>
            {priceValue}
            &nbsp;Kč
          </>
        );
      default:
        return priceValue;
    }
  }
  return 0;
}

export const checkMetaTag = tag => {
  if (typeof tag === 'string') {
    return tag;
  } else {
    return '';
  }
};

export const cookiesExpiresDate = () => {
  // expires in 1 year
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

export const transformRemainingItemsText = count => {
  if (count === 1) {
    return `...${__('ďalší')} ${count} ${__('produkt v košíku')}`;
  }

  if (count > 1 && count < 5) {
    return `...${__('ďalšie')} ${count} ${__('produkty v košíku')}`;
  }
  if (count >= 5) {
    return `...${__('ďalšých')} ${count} ${__('produktov v košíku')}`;
  }

  return null;
};

export enum cartRoutes {
  FIRST_STEP = 'routes:cart',
  SECOND_STEP = 'routes:cart/delivery-and-payment',
  THIRD_STEP = 'routes:cart/shopper-form',
}

export const createCartRoutesMap = (): Map<number, string> => {
  const cartRoutesMap = new Map();
  cartRoutesMap.set(1, __(cartRoutes.FIRST_STEP));
  cartRoutesMap.set(2, __(cartRoutes.SECOND_STEP));
  cartRoutesMap.set(3, __(cartRoutes.THIRD_STEP));

  return cartRoutesMap;
};

export interface PayloadedAction<TType, TPayload extends object> {
  type: TType;
  payload: TPayload;
}

export interface Action<TType> {
  type: TType;
}

export const MyAccountRoutes: {
  myAccount: string;
  myEntries: string;
  myAddresses: string;
  orders: string;
  requests: string;
  changePassword: string;
  addDeliveryAddress: string;
  manageAccounts: string;
  orderReturns: string;
} = {
  myAccount: __('routes:moj-ucet'),
  myEntries: __('routes:moj-ucet/moje-udaje'),
  myAddresses: __('routes:moj-ucet/moje-adresy'),
  orders: __('routes:moj-ucet/objednavky'),
  requests: __('routes:moj-ucet/dopyty'),
  changePassword: __('routes:moj-ucet/zmena-hesla'),
  orderReturns: __('routes:moj-ucet/vratenie-tovaru'),
  addDeliveryAddress: '/moj-ucet/dodacia-adresa',
  manageAccounts: '/moj-ucet/sprava-uctov',
};

export const createMyAccountRouteMap = (): Map<string, string> => {
  const myAccountRoutesMap = new Map<string, string>();
  myAccountRoutesMap.set(__(MyAccountRoutes.myAccount), __('MÔJ ÚČET'));
  myAccountRoutesMap.set(__(MyAccountRoutes.myEntries), __('MOJE ÚDAJE'));
  myAccountRoutesMap.set(__(MyAccountRoutes.myAddresses), __('MOJE ADRESY'));
  myAccountRoutesMap.set(__(MyAccountRoutes.orders), __('OBJEDNÁVKY'));
  myAccountRoutesMap.set(__(MyAccountRoutes.changePassword), __('ZMENA HESLA'));
  myAccountRoutesMap.set(
    __(MyAccountRoutes.addDeliveryAddress),
    __('NOVÁ DODACIA ADRESA'),
  );
  myAccountRoutesMap.set(__(MyAccountRoutes.requests), __('DOPYTY'));
  myAccountRoutesMap.set(
    __(MyAccountRoutes.manageAccounts),
    __('SPRÁVA ÚČTOV'),
  );
  myAccountRoutesMap.set(
    __(MyAccountRoutes.orderReturns),
    __('VRATENIE TOVARU'),
  );
  return myAccountRoutesMap;
};

export const textEllipsis = (text, requiredLength) => {
  if (text.length <= requiredLength) {
    return text;
  }
  return `${text.substring(0, requiredLength)}...`;
};

export function getAuthorUrl(id, url) {
  return `/autor/${id}/${url}`;
}

export function getRedirectRouteAfterLanguageChange(pathname, search, lang) {
  switch (pathname) {
    case '/':
      return lang === 'cz' ? '/cz' : '';

    case '/cz':
      return lang === 'cz' ? '/cz' : '';

    case __('routes:vyhladavanie'):
      return lang === 'cz'
        ? `/vyhledavani${search ? search : ''}`
        : `/vyhladavanie${search ? search : ''}`;
    // return `${__('routes:vyhladavanie')}${search ? search : ''}`;

    default:
      return pathname;
  }
}

export function getCorrectBrandFromTranslations(translations, lang) {
  return translations.filter(a => a.lang_id === lang);
}

export const stripHtmlTags = (str: string): string => {
  if (str === null || str === '' || typeof str === 'object') {
    return '';
  } else {
    str = str.toString();
  }
  return str.replace(/<[^>]*>/g, '');
};

export function isValidURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

export function returnColorBasedOnCategoryName(
  name: string,
  theme: any,
): { primary: string; secondary: string } {
  switch (name) {
    case 'Vykurovacia technika':
      return {
        primary: theme.categoryColors.heat.primary,
        secondary: theme.categoryColors.heat.secondary,
      };
    case 'Pre drevárov':
      return {
        primary: theme.categoryColors.wood.primary,
        secondary: theme.categoryColors.wood.secondary,
      };
    case 'Ostatné':
      return {
        primary: theme.categoryColors.rest.primary,
        secondary: theme.categoryColors.rest.secondary,
      };

    default:
      return {
        primary: theme.categoryColors.default.primary,
        secondary: theme.categoryColors.default.secondary,
      };
  }
}

export const arrayToObject = (array, key) =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

/**
 * @param obj
 * @param key
 * @param value
 * @param result
 */
export function deepFind(obj, key, value, result: any[] = []) {
  if (
    obj &&
    typeof obj === 'object' &&
    obj.hasOwnProperty(key) &&
    obj[key] === value
  ) {
    result.push(obj);
  }

  for (let k in obj) {
    if (typeof obj[k] === 'object') {
      deepFind(obj[k], key, value, result);
    }
  }

  return result;
}
export const getCurrencyFromLang = (lang: string): string => {
  switch (lang) {
    case 'sk':
      return 'EUR';

    case 'cz':
      return 'CZK';

    default:
      return 'EUR';
  }
};
