import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags, CartItemsPart, EmptyCart, prop } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  addItemToCart,
  removeItemFromCart,
  changeItemNote,
  changeCartNote,
  fetchCart,
  updateCart,
  addGiftCard,
  removeGiftCard,
  addVoucherCard,
  removeVoucherCard,
  addSaleGiftToCart,
} from './cartSlice';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  freeDeliveryRemainingAmount: number | null;
}

class CartFirstStep extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, data } = props;
    try {
      // if (props.user) {
      //   dispatch(updateCart({ ...data, step: 1 }));
      // }
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const { data, isFetching, freeDeliveryRemainingAmount, user } = this.props;

    if (!data) {
      return null;
    }

    const { items } = data;
    return (
      <>
        <MetaTags
          tags={{ title: __('Košík - Nákupný košík | GOLF CENTRUM.sk') }}
        />
        {items.length === 0 ? (
          <EmptyCart />
        ) : (
          <CartItemsPart
            user={user}
            data={data}
            isFetching={isFetching}
            addToRequest={this.addToRequest}
            addToCart={this.addToCart}
            addGiftCard={this.addGiftCard}
            removeGiftCard={this.removeGiftCard}
            removeFromCart={this.removeFromCart}
            changeCartItemNote={this.changeCartItemNote}
            changeCartNote={this.changeCartNote}
            refreshCart={this.refreshCart}
            freeDeliveryRemainingAmount={freeDeliveryRemainingAmount}
            addVoucherCard={this.addVoucherCard}
            removeVoucherCard={this.removeVoucherCard}
            setGiftForSale={this.setGiftForSale}
          />
        )}
      </>
    );
  }

  private refreshCart = () => {
    this.props.dispatch(fetchCart(true));
  };

  private addToRequest = (product: any, count: number) => {
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    this.props.dispatch(addItemToCart(product, count, true, false));
  };

  private addToCart = (product: any, count: number, callBack: any) => {
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    this.props.dispatch(
      addItemToCart(cartProduct, count, false, false, callBack),
    );
  };

  private addGiftCard = (cardNumber: string) => {
    this.props.dispatch(addGiftCard(cardNumber));
  };

  private removeGiftCard = (cardNumber: string) => {
    this.props.dispatch(removeGiftCard(cardNumber));
  };
  private addVoucherCard = (cardNumber: string) => {
    this.props.dispatch(addVoucherCard(cardNumber));
  };

  private removeVoucherCard = (cardNumber: string) => {
    this.props.dispatch(removeVoucherCard(cardNumber));
  };

  private removeFromCart = (pId: number, gId: number, isSale: any) => {
    this.props.dispatch(removeItemFromCart(gId, pId, isSale));
  };

  private changeCartItemNote = (goodId: number, note: string) => {
    this.props.dispatch(changeItemNote(goodId, note));
  };

  private changeCartNote = (note: string) => {
    this.props.dispatch(changeCartNote(note));
  };
  private setGiftForSale = (
    product_id: number,
    sale_id: number,
    goodId: number,
  ) => {
    this.props.dispatch(addSaleGiftToCart(product_id, sale_id, goodId));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartFirstStep' })(CartFirstStep),
);
