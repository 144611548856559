import API from './services/API';
import * as cookies from 'react-cookies';
import { LANG_COOKIE } from './containers/App/constants';
import { prop } from './utilities';

export async function loadTranslations(location) {
  return API.loadLangTranslation(
    resolveLangByHostname(prop(location, 'hostname')),
  );
}

export function resolveLangFromCookies(hostname: string): string {
  return cookies.load(LANG_COOKIE) || 'sk';
}

export function resolveLangByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development') {
    return 'sk';
  }

  if (hostname && hostname.includes('golfcentrum.store')) {
    return 'cz';
  } else if (hostname && hostname.includes('supergolf.cz')) {
    return 'cz';
  } else if (hostname && hostname.includes('golfcentrum.online')) {
    return 'en';
  } else {
    return 'sk';
  }
}

export function resolveCurrencyByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development') {
    return 'EUR';
  }

  if (hostname && hostname.includes('golfcentrum.store')) {
    return 'CZK';
  } else if (hostname && hostname.includes('supergolf.cz')) {
    return 'CZK';
  } else {
    return 'EUR';
  }
}

export function resolveCurrencySignFromLang(lang: string): string {
  if (lang === 'cz') {
    return 'Kč';
  } else {
    return '€';
  }
}

export const DEFAULT_SITEMAP_ID = {};

export const DEFAULT_CATEGORY_ID = {};

export function resolveBaseUrlByLang(lang?: string): string {
  // default is base url
  if (!lang) {
    return process.env.REACT_APP_BASE_URL as string;
  }

  // we are returning other url - if lang is cz we return sk url
  switch (lang) {
    case 'cz':
      return process.env.REACT_APP_BASE_URL?.replace(
        'golfcentrum.sk',
        `supergolf.cz`,
      ) as string;
    case 'en':
      return process.env.REACT_APP_BASE_URL?.replace(
        '.sk',
        `.online`,
      ) as string;
    default:
      return process.env.REACT_APP_BASE_URL as string;
  }
}
export function resolveBaseApiUrlByLang(lang?: string): string {
  // default is base url
  if (!lang) {
    return process.env.REACT_APP_API_BASE_URL as string;
  }

  // we are returning other url - if lang is cz we return sk url
  switch (lang) {
    case 'cz':
      return process.env.REACT_APP_API_BASE_URL?.replace(
        'golfcentrum.sk',
        `supergolf.cz`,
      ) as string;
    case 'en':
      return process.env.REACT_APP_API_BASE_URL?.replace(
        '.sk',
        `.online`,
      ) as string;
    default:
      return process.env.REACT_APP_API_BASE_URL as string;
  }
}
