import * as React from 'react';
import { __, __r } from 'react-i18n';
import { Link, withRouter, WithRouterProps } from 'react-router';
import API, { ThenArg } from '../../services/API';
import {
  loadSearchTermResults,
  loadPartialSearchTermResults,
  showDimmer,
  hideDimmer,
  loadPartialCategorySearchTermResults,
  setCurrency,
  handleCurrencyChange,
} from '../../containers/App/actions';
import { DesktopWrapper } from '../_helpers/Default/ResponsiveWrappers';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

interface HeaderProps {
  user: any;
  brands: any;
  lang: string;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  items: ThenArg<typeof API.loadTree>;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  cartData: ThenArg<typeof API.getCart>;
}

interface HeaderState {
  searchTerm: string;
  isResponsiveCategoryMenuVisible: boolean;
  isResponsiveSearchFormVisible: boolean;
  isResponsiveSearchFromResultviewerVisible: boolean;
  redirect: boolean;
  resultsDropdownVisible: boolean;
  mobileResultsDropdownVisible: boolean;
}

class Header extends React.Component<
  HeaderProps & WithRouterProps,
  HeaderState
> {
  private loadResultsTimeout;
  constructor(props) {
    super(props);

    this.loadResultsTimeout = null;

    this.state = {
      searchTerm: '',
      isResponsiveCategoryMenuVisible: false,
      isResponsiveSearchFormVisible: false,
      isResponsiveSearchFromResultviewerVisible: false,
      redirect: false,
      resultsDropdownVisible: false,
      mobileResultsDropdownVisible: false,
    };
  }

  public render() {
    const {
      socialUrls,
      items,
      lang,
      contact,
      dispatch,
      partialSearchProducts,
      partialSearchProductsIsFetching,
      partialSearchCategories,
      partialSearchCategoriesIsFetching,
      user,
      cartData,
      brands,
    } = this.props;
    const {
      resultsDropdownVisible,
      searchTerm,
      mobileResultsDropdownVisible,
    } = this.state;

    return (
      <div style={{ zIndex: 10 }}>
        <DesktopWrapper>
          <DesktopHeader
            user={user}
            partialSearchProducts={partialSearchProducts}
            partialSearchProductsIsFetching={partialSearchProductsIsFetching}
            partialSearchCategories={partialSearchCategories}
            partialSearchCategoriesIsFetching={
              partialSearchCategoriesIsFetching
            }
            searchTerm={searchTerm}
            dispatch={dispatch}
            socialUrls={socialUrls}
            items={items}
            lang={lang}
            contact={contact}
            handleSearchTermChange={this.handleSearchTermChange}
            toggleResponsiveCategoryMenu={this.toggleResponsiveCategoryMenu}
            handleSearchFocus={this.handleSearchFocus}
            hideSearchResultsViewer={this.hideSearchResultsViewer}
            resultsDropdownVisible={resultsDropdownVisible}
            redirectToSearchResultsPage={this.redirectToSearchResultsPage}
            changeDimmerVisible={this.changeDimmerVisible}
            cartData={cartData}
            brands={brands}
            onCurrencyChange={() => {}}
          />
        </DesktopWrapper>
        <MobileHeader
          partialSearchProducts={partialSearchProducts}
          partialSearchProductsIsFetching={partialSearchProductsIsFetching}
          partialSearchCategories={partialSearchCategories}
          partialSearchCategoriesIsFetching={partialSearchCategoriesIsFetching}
          dispatch={dispatch}
          socialUrls={socialUrls}
          items={items}
          lang={lang}
          contact={contact}
          toggleResponsiveCategoryMenu={this.toggleResponsiveCategoryMenu}
          searchTerm={searchTerm}
          handleSearchTermChange={this.handleSearchTermChange}
          redirectToSearchResultsPage={this.redirectToSearchResultsPage}
          handleSearchFocus={this.handleMobileSearchFocus}
          hideSearchResultsViewer={this.hideSearchResultsViewerMobile}
          resultsDropdownVisible={mobileResultsDropdownVisible}
          cartData={cartData}
          brands={brands}
        />
      </div>
    );
  }

  public redirectToSearchResultsPage = e => {
    e.preventDefault();
    const { searchTerm } = this.state;
    if (searchTerm && searchTerm.length > 0) {
      this.props.dispatch(loadSearchTermResults(searchTerm));
      this.toggleResponsiveSearchForm();
      this.closeSearchResultViewers();

      const route = __r('routes:vyhladavanie', '/vyhladavanie');
      this.props.router.push(`${route}?q=${searchTerm}`);
    }
  };

  public changeDimmerVisible = newVal => {
    if (newVal) {
      this.props.dispatch(showDimmer());
    } else {
      this.props.dispatch(hideDimmer());
    }
  };

  public toggleResponsiveCategoryMenu = () => {
    this.setState({
      isResponsiveCategoryMenuVisible: !this.state
        .isResponsiveCategoryMenuVisible,
    });
  };

  public closeSearchResultViewers = () => {
    this.setState({
      mobileResultsDropdownVisible: false,
      resultsDropdownVisible: false,
    });
  };

  public toggleResponsiveSearchForm = () => {
    // set timeout, so form doesnt reopen if there is click on search/close icon
    setTimeout(() => {
      this.setState({
        isResponsiveSearchFormVisible: !this.state
          .isResponsiveSearchFormVisible,
      });
    }, 1);
  };

  public handleSearchTermChange = e => {
    const newVal = e.target.value;
    this.setState({ searchTerm: newVal });
    this.setState({
      mobileResultsDropdownVisible: true,
      resultsDropdownVisible: true,
    });
    if (this.loadResultsTimeout) {
      clearTimeout(this.loadResultsTimeout);
    }

    this.loadResultsTimeout = setTimeout(async () => {
      this.props.dispatch(loadPartialSearchTermResults(newVal, 4));
      this.props.dispatch(loadPartialCategorySearchTermResults(newVal));
    }, 200);
  };

  public handleResponsiveSearchFormFocus = () => {
    this.setState({
      isResponsiveSearchFromResultviewerVisible: true,
    });
  };

  public hideResponsiveSearchResultViewer = () => {
    this.setState({
      isResponsiveSearchFromResultviewerVisible: false,
    });
  };

  public handleSearchFocus = () => {
    if (this.state.resultsDropdownVisible) {
      return;
    }
    this.setState({
      resultsDropdownVisible: true,
    });
  };

  public hideSearchResultsViewer = () => {
    if (this.state.resultsDropdownVisible) {
      this.setState({
        resultsDropdownVisible: false,
      });
    }
  };

  public handleMobileSearchFocus = () => {
    if (this.state.mobileResultsDropdownVisible) {
      return;
    }
    this.setState({
      mobileResultsDropdownVisible: true,
    });
  };

  public hideSearchResultsViewerMobile = () => {
    this.setState({
      mobileResultsDropdownVisible: false,
    });
  };

  public handleCurrencyChange = () => {
    this.props.dispatch(handleCurrencyChange());
  };
}

export default withRouter(Header);
