import { ProductMicrodata } from '@bart.sk-ecommerce/react-microdata';
import {
  BottomInfo,
  FlexCol,
  FlexRow,
  getProductDefaultInfo,
  getProductDetailInfo,
  getZemplinProductDefaultInfo,
  GolftCentrumLeftUpper as LeftUpper,
  GolftCentrumProductTitleImage as ProductTitleImage,
  GolftCentrumRightSide as RightSide,
  InfoText,
  ZemplinLoaderWrapper,
  ProductSizeChartTable,
  GolfcentrumIconType,
  FlexColCenterVertical,
  TextP,
  SaleComponentWithProducts,
} from 'eshop-defaults';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import { rem } from 'polished';
import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import {
  addItemToCart,
  setAddToCartModalVisibility,
  setWasAddedToCartModalVisibility,
} from '../../containers/Cart/cartSlice';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import { getDefaultGood, getGoodProductId } from '../../utilities/cart';
import { getProductMetaTags } from '../../utilities/metatags';
import InfoBanner from '../_helpers/Default/InfoBanner';
import {
  default as Lightbox,
  default as SecondSlider,
} from '../_helpers/Slider/SecondSlider';

import Modal from '../_helpers/Default/Modal';

import {
  getElasticCategoryProductInfo,
  getProductSizeChartData,
} from 'eshop-defaults/lib/utilities/selectors';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import { resolveSizeChartLangAttribFromProduct } from '../../utilities/product';

interface Props {
  user: ThenArg<typeof API.tokeninfo>;
  lang?: string;
  product: ThenArg<typeof API.loadProduct>;
  isFetching: boolean;
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  accessoriesProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAccessories: boolean;
  colorsVariantsProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingColorsVariants: boolean;
  dispatch: any;
  otherText: any;
  cartIsFetching: any;
  currentCategory?: string;
  saleDataFromCart?: any;
}

function Product({
  product,
  isFetching,
  alternativeProducts,
  isFetchingAlternatives,
  accessoriesProducts,
  isFetchingAccessories,
  colorsVariantsProducts,
  isFetchingColorsVariants,
  dispatch,
  otherText,
  cartIsFetching,
  currentCategory,
  user,
  saleDataFromCart,
  lang,
}: Props) {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const bottomRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  const relatedRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  const accessoriesRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  React.useEffect(() => {
    dispatch(
      setBreadCrumbPath(BreadCrumbType.PRODUCT, product, {
        currentCategory: currentCategory,
      }),
    );
  }, [product, dispatch, currentCategory]);

  const [userPlu, setUserPlu] = React.useState('');
  const [pickerErrorMessage, setPickerErrorMessage] = React.useState('');
  const { good: defaultGood } = getDefaultGood(product);
  const [selectedGood, setSelectedGood] = React.useState<any>(defaultGood);
  const [
    sizeChartTableModalVisible,
    setSizeChartTableModalVisible,
  ] = React.useState(false);

  const [saleInfoModalVisible, setSaleInfoModalVisible] = React.useState(false);

  React.useEffect(() => {
    const { good: defaultGood } = getDefaultGood(product);
    setSelectedGood(defaultGood);
  }, [product]);

  const info = getProductDetailInfo(product);

  const {
    unit,
    price,
    assembling,
    productPackages,
    onStock,
    oldPrice,
  } = getZemplinProductDefaultInfo(product);
  const isMks = unit === 'Mks';
  const initialCount = isMks ? 1 : 1;
  const [count, setCount] = React.useState(initialCount);
  React.useEffect(() => {
    const initialCount = isMks ? 1 : 1;
    setCount(initialCount);
  }, [unit, isMks]);

  if (isFetching) {
    return <ZemplinLoaderWrapper height={300} />;
  }

  const closeSizeChartTableModal = () => {
    setSizeChartTableModalVisible(false);
  };

  const openSizeChartTableModal = () => {
    setSizeChartTableModalVisible(true);
  };

  const closeSaleInfoModal = () => {
    setSaleInfoModalVisible(false);
  };

  const openSaleInfoModal = () => {
    setSaleInfoModalVisible(true);
  };

  const productSizeChardData = getProductSizeChartData(product);

  // jazykové preklady size chart atributov
  resolveSizeChartLangAttribFromProduct(product);

  let sale = prop(product, 'sales.0', null);
  const saleInfo = prop(saleDataFromCart, 'saleInfo', []);

  const renderUpperPart = () => {
    const {
      name,
      image,
      description,
      ordernr,
      annotation,
      currency,
      inPackage,
      issaleout,
      isnew,
      issale,
      photogallery,
      availability,
      videos,
    } = info;
    return (
      <>
        <UpperDesktopWrapper>
          <LeftUpper
            issaleout={issaleout}
            isnew={isnew}
            issale={issale}
            title={name}
            image={image}
            desc={annotation}
            plu={ordernr}
            userPlu={userPlu}
            onChange={e => setUserPlu(e.target.value)}
            bottomRef={bottomRef}
            galleryItems={photogallery}
            price={price}
            oldPrice={oldPrice}
            videos={videos}
            selectedGood={selectedGood}
          />
          <>
            <RightSide
              pickerErrorMessage={pickerErrorMessage}
              user={user}
              currency={currency}
              onStock={onStock}
              unit={unit}
              inPackage={productPackages}
              price={price}
              oldPrice={oldPrice}
              addToRequest={addToRequest}
              addToCart={openCartModal}
              count={count}
              handleCountChange={count => setCount(count)}
              product={product}
              selectedGood={selectedGood}
              otherText={otherText}
              cartIsFetching={cartIsFetching}
              availability={availability}
              colorsVariantsProducts={colorsVariantsProducts}
              isFetchingColorsVariants={isFetchingColorsVariants}
              handleGoodChange={handleGoodChange}
              brand={brand}
              showSizeChartTable={openSizeChartTableModal}
              sale={sale}
              showSaleModal={openSaleInfoModal}
              lang={lang}
            />
          </>
        </UpperDesktopWrapper>
        <UpperMobileWrapper>
          <ProductTitleImage
            issaleout={issaleout}
            isnew={isnew}
            title={name}
            image={image}
            issale={issale}
            price={price}
            oldPrice={oldPrice}
          />
          {photogallery && photogallery.length > 0 && (
            <SecondSlider
              type="product-detail"
              slides={photogallery}
              showDots={true}
            />
          )}
          <RightSide
            pickerErrorMessage={pickerErrorMessage}
            user={user}
            currency={currency}
            onStock={onStock}
            unit={unit}
            inPackage={productPackages}
            price={price}
            oldPrice={oldPrice}
            addToRequest={addToRequest}
            addToCart={openCartModal}
            count={count}
            handleCountChange={count => setCount(count)}
            product={product}
            selectedGood={selectedGood}
            otherText={otherText}
            cartIsFetching={cartIsFetching}
            availability={availability}
            colorsVariantsProducts={colorsVariantsProducts}
            isFetchingColorsVariants={isFetchingColorsVariants}
            handleGoodChange={handleGoodChange}
            brand={brand}
            showSizeChartTable={openSizeChartTableModal}
            sale={sale}
            videos={videos}
            showSaleModal={openSaleInfoModal}
            lang={lang}
          />
        </UpperMobileWrapper>
      </>
    );
  };

  const renderBottomPart = () => {
    const { description, attribs, downloads } = info;
    return (
      <div ref={bottomRef}>
        <BottomInfo
          accessoriesRef={accessoriesRef}
          relatedRef={relatedRef}
          desc={description}
          attribs={attribs}
          downloads={downloads}
          showAssembling={assembling}
        />
      </div>
    );
  };

  const renderRelated = () => {
    return (
      <>
        {!isFetchingAlternatives ? (
          alternativeProducts &&
          alternativeProducts.products &&
          alternativeProducts.products.length > 0 && (
            <div ref={relatedRef}>
              <SliderWrapper>
                <Title>{__('Mohlo by vás zaujímať')}</Title>
                <Lightbox
                  type="product"
                  slides={alternativeProducts.products}
                  showDots={true}
                />
              </SliderWrapper>
            </div>
          )
        ) : (
          <ZemplinLoaderWrapper height={250} />
        )}
      </>
    );
  };

  const renderAccessories = () => {
    return (
      <>
        {!isFetchingAccessories ? (
          accessoriesProducts &&
          accessoriesProducts.products &&
          accessoriesProducts.products.length > 0 && (
            <div ref={accessoriesRef}>
              <AccessorySliderWrapper>
                <Title>{__('Príslušenstvo')}</Title>
                <Lightbox
                  type="product"
                  slides={accessoriesProducts.products}
                />
              </AccessorySliderWrapper>
            </div>
          )
        ) : (
          <ZemplinLoaderWrapper height={300} />
        )}
      </>
    );
  };

  const handleGoodChange = (e, goodId) => {
    const { goods } = getProductDetailInfo(product);
    // const dataset = e.currentTarget.dataset;
    // const goodId = parseInt(dataset.goodId, 10);

    const good = goods.find(good => good.good_id === goodId);
    setSelectedGood(good);

    if (good) {
      setPickerErrorMessage('');
    }
  };

  const openCartModal = () => {
    if (selectedGood && selectedGood.good_id) {
      const cartProduct = {
        ...product,
        product_id: prop(selectedGood, 'product_id'),
        main_good: {
          good_id: prop(selectedGood, 'good_id'),
        },
      };

      addToCart(cartProduct);
    } else {
      setPickerErrorMessage(__('Zvoľte variant'));
    }
  };

  const addToCart = (product: any) => {
    dispatch(addItemToCart(product, count, false, true));
  };

  const addToRequest = () => {
    const { goodId, productId } = getGoodProductId(product);
    const getProductTrueCount = isMks ? Math.round(count * 1000) : count;
    dispatch(addItemToCart(product, getProductTrueCount));
  };

  const defaultInfo = getProductDefaultInfo(product);
  const { ordernr } = defaultInfo;
  const attribs = prop(product, 'attribs') ? prop(product, 'attribs') : [];

  const brandAttrib = attribs.find(a => a.attrib_name === 'Značka');
  const brand = brandAttrib
    ? prop(prop(brandAttrib, 'values', [])[0], 'attrib_value')
    : '';

  const { name, photogallery, description } = info;
  return (
    <>
      <Wrapper className="container container--wide">
        <MetaTags tags={getProductMetaTags(product)} />
        <ProductMicrodata
          name={name}
          image={photogallery}
          brand={brand}
          description={description}
          gtin={ordernr}
          sku={ordernr}
        />

        {/*offers?: Offer;*/}
        {/*review?: Review;*/}
        {/*aggregateRating?: AggregateRating;*/}

        {isFetching ? (
          <ZemplinLoaderWrapper height={300} />
        ) : product ? (
          <>
            {renderUpperPart()}
            {/*{renderBottomPart()}*/}
            {/*{renderAccessories()}*/}
            {renderRelated()}
          </>
        ) : (
          <InfoText
            info={__('Nenašiel sa žiadny produkt')}
            type={InfoType.ERROR}
          />
        )}
      </Wrapper>

      {sizeChartTableModalVisible &&
        productSizeChardData &&
        productSizeChardData.length > 0 && (
          <Modal
            size="big"
            showX={true}
            closeModal={() => {
              closeSizeChartTableModal();
            }}
            style={{ height: 'auto' }}
          >
            <ProductSizeChartTable data={productSizeChardData} />
          </Modal>
        )}
      {saleInfoModalVisible && (
        <Modal
          size="medium"
          showX={true}
          closeModal={() => {
            closeSaleInfoModal();
          }}
          style={{ height: 'auto', padding: 0 }}
        >
          <SaleComponentWithProducts sale={sale} saleInfo={saleInfo} />
        </Modal>
      )}
    </>
  );
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(56)} ${rem(24)} ${rem(80)} !important;
  color: #444444;
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(32)} 0;
  `}
`;

const SliderWrapper = styled(FlexCol)`
  // max-height: ${rem(540)};
  // ${({ theme }) => theme.mediab.l925`
  //   max-height: ${rem(362)};
  // `}
`;

const AccessorySliderWrapper = styled(SliderWrapper)`
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
     margin-bottom: ${rem(32)};
  `}
`;
const Title = styled.h4`
  font-weight: 400;
  font-size: ${rem(40)};
  line-height: ${rem(34)};
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.secondary};

  ${({ theme }) => theme.mediab.l925`
    font-size: ${rem(24)};
    line-height: ${rem(32)};
    margin:  0;
  `}
`;

const UpperWrapper = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
  `}
`;

const UpperMobileWrapper = styled(UpperWrapper)`
  display: none;

  ${({ theme }) => theme.mediab.l925`
    display: flex;
    margin:0 auto;
    position: relative;
  `}

  ${({ theme }) => theme.mediab.s450`
    margin: initial;
  `}
`;

const UpperDesktopWrapper = styled(UpperWrapper)`
  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;
export default Product;
