import React from 'react';
import {
  IconImg,
  FlexRowCenterVertical,
  FlexCol,
  TextP,
  GolfcentrumSvgIcon as SvgIcon,
  GolfcentrumIconType as IconType,
} from 'eshop-defaults';
import styled from '../../theme/styled-components';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import { Link } from 'react-router';
import { rem } from 'polished';
import { __r } from 'react-i18n/lib';

interface Props {
  user: any;
}

function HeaderProfile({ user }: Props) {
  const { name, surname, email } = user || {};
  const userName =
    name || surname ? `${name || ''} ${surname || ''}` : `${email}`;

  return (
    <Wrapper
      to={
        user
          ? __r('routes:moj-ucet', '/moj-ucet')
          : __r('routes:prihlasenie', '/prihlasenie')
      }
    >
      <SvgIcon
        icon={IconType.user}
        width={32}
        height={32}
        alt="user-icon"
        cursor={'pointer'}
      />
      <NameLinkWrapper>
        {user ? (
          <>
            {/*<UserName>{userName}</UserName>*/}
            <LinkIconWrapper>
              <MyAccountText>{__('Môj účet')}</MyAccountText>
              {/*<IconImg*/}
              {/*  width={8}*/}
              {/*  height={4}*/}
              {/*  src="/images/arrow_triangle_black.svg"*/}
              {/*  alt="arrow-down"*/}
              {/*/>*/}
            </LinkIconWrapper>
          </>
        ) : (
          <MyAccountText>{__('Prihlásiť sa')}</MyAccountText>
        )}
      </NameLinkWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-right: ${rem(40)};
  height: ${rem(48)};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color 0.2s ease;
  cursor: pointer;
  z-index: 500;

  &:hover p {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.mediab.l925`
    margin-right:${rem(16)};
  `}
`;

const NameLinkWrapper = styled(FlexCol)`
  margin-left: ${rem(12)};

  ${({ theme }) => theme.mediab.l1050`
    display: none;
  `}
`;

const LinkIconWrapper = styled(FlexRowCenterVertical)`
  margin-top: ${rem(4)};
`;

const MyAccountText = styled(TextP)`
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(20)};
  white-space: nowrap;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(HeaderProfile);
