import APIClass from '../../services/API';
import {
  REQUEST_CATEGORIES,
  RECEIVE_CATEGORIES_SUCCESS,
  RECEIVE_CATEGORIES_ERROR,
  RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR,
  REQUEST_TOP_PRODUCTS_CATEGORY,
  RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS,
  REQUEST_CATEGORY_PRODUCTS,
  RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  RECEIVE_CATEGORY_PRODUCTS_ERROR,
  SET_CATEGORY_FILTER,
  REQUEST_CATEGORY_BRANDS,
  RECEIVE_CATEGORY_BRANDS_SUCCESS,
  RECEIVE_CATEGORY_BRANDS_ERROR,
  SET_CATEGORY_FILTERS,
  RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS,
  REQUEST_SITEMAP_ARTICLES,
  RECEIVE_SITEMAP_ARTICLES_ERROR,
  RECEIVE_SITEMAP_ARTICLES_SUCCESS,
  SET_CATEGORY_ARTICLES_FILTERS,
  REQUEST_SITEMAP_DATA,
  RECEIVE_SITEMAP_DATA_SUCCESS,
  RECEIVE_SITEMAP_DATA_ERROR,
} from './constants';
import { prop } from '../../utilities';
import { currencySelector, langSelector } from '../App/selectors';
import { categoryDataSelector } from './selectors';
import { resolveCurrentThemeFromCategory } from '../../utilities/category';
import { setCurrentTheme } from '../App/actions';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';

export const setCategoryFilter = (sort, sortDir) => ({
  type: SET_CATEGORY_FILTER,
  payload: { sort, sortDir },
});

const requestCategoryProducts = () => ({
  type: REQUEST_CATEGORY_PRODUCTS,
});

const receiveCategoryProductsSuccess = products => ({
  type: RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

const receiveCategoryProductsError = error => ({
  type: RECEIVE_CATEGORY_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const setCategoryInitialStateFromUrl = location => {
  return async dispatch => {
    const { query } = location;
    let offset = 0;
    let sort = '';
    let sortDir = '';
    if (query.offset) {
      if (isNaN(query.offset)) {
        offset = 0;
      } else {
        offset = Number(query.offset);
      }
    }
    if (query.sort) {
      sort = prop(query, 'sort', '');
    }
    if (query.sortDir) {
      sortDir = prop(query, 'sortDir', '');
    }
    dispatch(setCategoryFilters({ offset, pathname: location.pathname }));
    dispatch(setCategoryFilter(sort, sortDir));
  };
};

export const getNextCategoryUrl = (category, newOffset = 0, sort, sortDir) => {
  return {
    pathname: category.category.data.url,
    query: {
      offset:
        newOffset.toString() || category.category.products.products.offset,
      sort: sort || sort === '' ? sort : category.category.products.sort,
      sortDir:
        sortDir || sortDir === ''
          ? sortDir
          : category.category.products.sortDir,
    },
    state: {
      reload: false,
    },
  };
};

export const setCategoryFilters = filters => ({
  type: SET_CATEGORY_FILTERS,
  payload: {
    filters,
  },
});

export const loadCategoryProducts = (categoryId, offset) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategoryProducts());

      const state = getState();
      const lang: string = langSelector(state);
      const currency: string = currencySelector(state);
      const products = await APIClass.searchProducts(
        {
          categoryId,
          offset,
          limit: 12,
          sort: prop(state, 'categoryDetail.category.products.sort'),
          sortDir: prop(state, 'categoryDetail.category.products.sortDir'),
          withAttribs: '0',
          withGifts: '0',
          withBrand: '0',
        },
        {
          xAcceptLanguage: lang,
          xCurrency: currency,
        },
      );
      dispatch(receiveCategoryProductsSuccess(products));
    } catch (e) {
      dispatch(receiveCategoryProductsError(e));
    }
  };
};

export const loadCategorySpecialProducts = (pathname, offset) => {
  return async (dispatch, getState, API) => {
    const param = pathname.includes('novinky')
      ? 'isNew'
      : pathname.includes('akcie')
      ? 'isSale'
      : 'isSaleout';
    try {
      dispatch(requestCategoryProducts());

      const state = getState();
      const lang: string = langSelector(state);
      const currency: string = currencySelector(state);
      const products = await APIClass.searchProducts(
        {
          offset,
          limit: 12,
          sort: 'product_plu',
          sortDir: 'asc',
          [param]: 1,
          // sort: prop(getState(), 'categoryDetail.category.products.sort'),
          // sortDir: prop(getState(), 'categoryDetail.category.products.sortDir'),
          withAttribs: '0',
          withGifts: '0',
          withBrand: '0',
          withProductPackages: '1',
        },
        {
          xAcceptLanguage: lang,
          xCurrency: currency,
        },
      );
      dispatch(receiveCategoryProductsSuccess(products));
    } catch (e) {
      dispatch(receiveCategoryProductsError(e));
    }
  };
};

const requestCategory = () => ({
  type: REQUEST_CATEGORIES,
});

const receiveCategorySuccess = category => ({
  type: RECEIVE_CATEGORIES_SUCCESS,
  payload: {
    category,
  },
});

const receiveCategoryError = error => ({
  type: RECEIVE_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const loadCategory = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      const currentCategory = categoryDataSelector(getState());
      let category = currentCategory;
      if (
        !category ||
        !category.category_id ||
        category.category_id.toString() !== categoryId
      ) {
        dispatch(requestCategory());
        category = await API.loadCategory(categoryId);
        dispatch(receiveCategorySuccess(category));
      }

      const { category_number, parent_categories } = category;
      const uniqueCatIds: string[] = [];
      uniqueCatIds.push(category_number);
      parent_categories.map(p => uniqueCatIds.push(p.category_number));
      const theme = resolveCurrentThemeFromCategory(uniqueCatIds);
      dispatch(setCurrentTheme(theme));
      dispatch(setBreadCrumbPath(BreadCrumbType.CATEGORY, category));
    } catch (e) {
      dispatch(receiveCategoryError(e));
    }
  };
};
