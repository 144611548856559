import {
  IrequestCategoriesTree,
  IreceiveCategoriesTreeError,
  IreceiveCategoriesTreeSuccess,
  CategoriesTree,
  IrequestSubcategoriesTree,
  IreceiveSubcategoriesTreeSuccess,
  SubcategoriesTree,
  IrereceiveSubcategoriesTreeError,
} from './types';
import { langSelector } from '../App/selectors';
import { productCategoriesSelector } from './selectors';

export const namespace = 'Header';

const requestCategoriesTree = (): IrequestCategoriesTree => ({
  type: 'Header/REQUEST_CATEGORIES',
});

const receiveCategoriesTreeSuccess = (
  categories: CategoriesTree,
): IreceiveCategoriesTreeSuccess => ({
  type: 'Header/RECEIVE_CATEGORIES_SUCCESS',
  payload: {
    categories,
  },
});

const receiveCategoriesTreeError = (error): IreceiveCategoriesTreeError => ({
  type: 'Header/RECEIVE_CATEGORIES_ERROR',
  payload: {
    error,
  },
});

export const loadCategoriesTree = () => {
  return async (dispatch, getState, API) => {
    try {
      const seoshow = true;
      if (!getState().header.categories.productCategories) {
        const lang = langSelector(getState());

        dispatch(requestCategoriesTree());
        let categories = await API.loadTree(
          { withPublish: '1' },
          { xAcceptLanguage: lang },
        );

        if (seoshow) {
          categories = categories.filter(category => category.seoshow !== 1);
        }

        dispatch(receiveCategoriesTreeSuccess(categories));

        // load homepage products
        if (categories && categories[0]) {
          const id = categories[0].category_id;
          return id;
        }
      } else {
        return productCategoriesSelector(getState())[0].category_id;
      }
    } catch (e) {
      console.log({ e });
      dispatch(receiveCategoriesTreeError(e));
    }
  };
};

const requestSubcategoriesTree = (
  parentCategoryId: number,
): IrequestSubcategoriesTree => ({
  type: 'Header/REQUEST_SUBCATEGORIES',
  payload: {
    parentCategoryId,
  },
});

const receiveSubcatergoriesTreeSuccess = (
  subcategoriesTree: SubcategoriesTree,
  parentCategoryId: number,
): IreceiveSubcategoriesTreeSuccess => ({
  type: 'Header/RECEIVE_SUBCATEGORIES_SUCCESS',
  payload: {
    subcatgories: subcategoriesTree,
    parentCategoryId,
  },
});

const receiveSubcatergoriesTreeError = (
  error: any,
): IrereceiveSubcategoriesTreeError => ({
  type: 'Header/RECEIVE_SUBCATEGORIES_ERROR',
  payload: {
    error,
  },
});

export const loadSubcategoriesTree = (categoryId: number) => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      dispatch(requestSubcategoriesTree(categoryId));
      const subcategoriesTree: SubcategoriesTree = await API.loadSubTree(
        categoryId,
        {},
        { xAcceptLanguage: lang },
      );
      dispatch(receiveSubcatergoriesTreeSuccess(subcategoriesTree, categoryId));
    } catch (e) {
      dispatch(receiveSubcatergoriesTreeError(e));
    }
  };
};

export const changeDimmerVisibility = (newValue: boolean) => ({
  type: 'Header/CHANGE_TOGGLE_VISIBLE',
  payload: {
    newValue,
  },
});

// const requestSearchTerm = (searchTerm: string): IrequestSearchTerm => ({
//   type: 'Header/REQUEST_SEARCH_TERM',
//   payload: {
//     searchTerm,
//   },
// });

// const receiveSearchTermResultsSuccess = (
//   searchTerm,
//   articles,
// ): IreceiveSearchTermResultSuccess => ({
//   type: 'Header/RECEIVE_SEARCH_TERM_SUCCESS',
//   payload: {
//     articles,
//     searchTerm,
//   },
// });

// export const loadSearchTermResults = (
//   searchTerm: string,
//   limit: number = 5,
//   offset: number = 0,
//   sort: string = '',
//   sortDir: string = '',
// ) => {
//   return async (dispatch, getState, API) => {
//     try {
//       dispatch(requestSearchTerm(searchTerm));
//       // const lang: string = langSelector(getState());
//       // const currency: string = currencySelector(getState());
//       const articlesResult = await API.loadArticles({
//         sitemapId: 135,
//       });

//       dispatch(receiveSearchTermResultsSuccess(searchTerm, articlesResult));
//     } catch (e) {
//       console.error(e);
//     }
//   };
// };
