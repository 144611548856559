import { __r } from 'react-i18n';
import { prop } from 'eshop-defaults';

export enum cartRoutes {
  INDEX = 'routes:kosik',
  SECOND_STEP = 'routes:kosik/osobne-udaje',
  THIRD_STEP = 'routes:cart/dodanie-a-platba',
}

export const cartIndexRoute = () => __r('routes:kosik', '/kosik');
export const cartSecondStepRoute = () =>
  __r('routes:kosik/osobne-udaje', '/kosik/osobne-udaje');
export const cartThirdStepRoute = () =>
  __r('routes:cart/dodanie-a-platba', '/kosik/dodanie-a-platba');

export function getGoodProductId(
  product: any,
): { goodId: number; productId: number } {
  const goodId = prop(product, 'main_good.good_id');
  const productId = prop(product, 'product_id');
  return { goodId, productId };
}

export function getDefaultGood(product: any): { good: any } {
  const goods = prop(product, 'goods', []);
  const onStockGoods = goods.filter(g => g.on_stock_count > 0);
  const goodsOnStockWithAttribs = onStockGoods.filter(
    g => g.web_attribs && g.web_attribs.length,
  );
  const goodsWithAttribs = onStockGoods.filter(
    g => g.web_attribs && g.web_attribs.length,
  );
  const good =
    goodsWithAttribs && goodsWithAttribs.length > 0
      ? goodsWithAttribs[0]
      : goods.length > 0
      ? goods[0]
      : {};
  const goodWithAttribs =
    goodsOnStockWithAttribs && goodsOnStockWithAttribs[0]
      ? goodsOnStockWithAttribs[0]
      : good;
  return { good: goodWithAttribs };
}
