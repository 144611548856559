import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  MetaTags,
  ZemplinTitle as Title,
  Requests,
  ZemplinSelect as Select,
  ZemplinCheckbox as CheckBox,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import {
  fetchChildUsers,
  fetchRequests,
  requestsDataSelector,
  requestsByIdSelector,
  requestsIsFetchingSelector,
  childUsersSelector,
} from './myAccountSlice';
import API, { ThenArg } from '../../services/API';
import MyRequests from '../../components/MyAccount/MyRequests';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';

const LIMIT = 5;

interface Props {
  user: any;
  dispatch: any;
  isFetching: boolean;
  requestsById: object;
  requestsData: any;
  childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
}

interface State {
  currentId: string;
}

class MyRequestsContainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_REQUESTS));
      await Promise.all([
        await dispatch(fetchChildUsers()),
        await dispatch(fetchRequests(null, LIMIT, 0)),
      ]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
    };
  }

  public render() {
    const {
      user,
      requestsById,
      requestsData,
      childUsers,
      isFetching,
    } = this.props;
    const { currentId } = this.state;

    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: (user.id || '').toString(),
          }))
          .map(a => a)
      : [];
    const options = [
      { name: 'Ja', value: user.id.toString() },
      ...childUserOptions,
    ];

    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Moje dopyty' }} />
        <MyRequests
          requests={requestsById[currentId]}
          requestsData={requestsData}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetching={isFetching}
        />
      </>
    );
  }

  public renderFilters() {
    return (
      <FilterWrapper>
        <Select
          value={''}
          options={[{ name: '', value: '' }]}
          onChange={() => {}}
        />
        <CheckBox label="Spracovaný" selected={true} />
        <CheckBox label="Spracováva sa" selected={false} />
        <CheckBox label="Čaká na spracovanie" selected={true} />
      </FilterWrapper>
    );
  }

  public handleSelectChange = e => {
    const id = e.target.value;
    this.setState({ currentId: id });
    this.props.dispatch(fetchRequests(id, LIMIT, 0));
  };

  public handleOffsetChange = e => {
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;
    const offset = LIMIT * num;
    this.props.dispatch(fetchRequests(currentId, LIMIT, offset));
  };
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const FilterWrapper = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    align-items: flex-start;
  `}
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    requestsData: requestsDataSelector(state),
    requestsById: requestsByIdSelector(state),
    isFetching: requestsIsFetchingSelector(state),
    childUsers: childUsersSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyRequests' })(MyRequestsContainer),
);
