import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  GolfcentrumTitle as Title,
  OrderProducts,
  OrderReturnSummary,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import { useForm } from '../../utilities/hooks';
interface ContactInfo {
  email: string;
  phone: string;
  name: any;
  surname: string;
  city: string;
  street: string;
  street_number: string;
  zip: string;
  country: string;
  iban: string;
  note: string;
  delivery: number;
}
interface Props {
  order: ThenArg<typeof API.getOrder>;
  onProductReturnClick: (order_nr, good, step, delivery) => void;
  onChangeContact: (order_nr: number, data: ContactInfo, step?: number) => void;
  step: number;
  isFetching: boolean;
  setDelivery: (order_nr, value) => void;
}

function FullOrder({
  order,
  onProductReturnClick,
  onChangeContact,
  step,
  isFetching,
  setDelivery,
}: Props) {
  const payloadAddress = prop(order, 'payload.order_to_return.order_address');
  const payloadDelivery = prop(order, 'payload.order_to_return.order_delivery');
  let contactInfoInitialState;
  if (payloadAddress && payloadAddress.zip) {
    contactInfoInitialState = {
      email: prop(payloadAddress, 'person.email', ''),
      phone: prop(payloadAddress, 'person.phone', ''),
      name: prop(payloadAddress, 'person.name', ''),
      surname: prop(payloadAddress, 'person.surname', ''),
      city: prop(payloadAddress, 'city', ''),
      street: prop(payloadAddress, 'street', ''),
      street_number: prop(payloadAddress, 'street_number', ''),
      zip: prop(payloadAddress, 'zip', ''),
      country: prop(payloadAddress, '.country', 'sk'),
      iban: prop(payloadAddress, 'iban', ''),
      note: prop(payloadAddress, 'note', ''),
      delivery: prop(payloadDelivery, 'delivery_type'),
    };
  } else {
    contactInfoInitialState = {
      email: prop(order, 'address.person.email', ''),
      phone: prop(order, 'address.person.phone', ''),
      name: prop(order, 'address.person.name', ''),
      surname: prop(order, 'address.person.surname', ''),
      city: prop(order, 'address.city', ''),
      street: prop(order, 'address.street', ''),
      street_number: prop(order, 'address.street_number', ''),
      zip: prop(order, 'address.zip', ''),
      country: prop(order, 'address.country', 'sk'),
      iban: '',
      note: '',
      delivery: null,
    };
  }

  const [orderContactInfo, dispatchOrderContactInfo] = useForm<ContactInfo>(
    contactInfoInitialState,
  );

  const [s, setStep] = React.useState(step);

  const handleStep = s => {
    setStep(s);
    onChangeContact(order.order_id, orderContactInfo, s);
  };

  const handleBlur = () => {
    onChangeContact(order.order_id, orderContactInfo);
  };

  const handleProductReturnClick = async (
    good_id,
    isReturn,
    quantity,
    is_sale,
  ) => {
    const good = { good_id, isReturn, quantity, is_sale };
    onProductReturnClick(order.order_id, good, step, orderContactInfo.delivery);
  };

  const handelDelivery = value => {
    dispatchOrderContactInfo({ field: 'delivery', value: value });
    setDelivery(order.order_id, value);
  };

  return (
    <Wrapper>
      <Title marginTop={0} marginBottom={32}>
        {__('Objednávka č.')} {order.order_nr}
      </Title>
      {step === 1 ? (
        <OrderProducts
          onProductReturnClick={handleProductReturnClick}
          handleStepChange={handleStep}
          order={order}
        />
      ) : (
        <OrderReturnSummary
          handleStepChange={handleStep}
          handleBlur={handleBlur}
          setDelivery={handelDelivery}
          order={order}
          orderContactInfo={orderContactInfo}
          // @ts-ignore
          dispatchOrderContactInfo={dispatchOrderContactInfo}
          isFetching={isFetching}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: auto;
  margin-left: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default FullOrder;
