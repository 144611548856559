import * as React from 'react';
import { connect } from 'react-redux';
import { default as API, ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import { langSelector, titleSelector } from '../Category/selectors';
import {
  userSelector,
  contactInfoSelector,
  otherTextSelector,
  currencySelector,
} from '../App/selectors';
import { showDimmer, openLoginModal } from '../App/actions';
import isEqual from 'lodash/isEqual';
import Product from '../../components/Product/Product';
import { connectSsr } from 'ssr-service';
import {
  loadProductDetail,
  loadProductDetailRelated,
  loadProductDetailAccessory,
  loadProductDetailColorsVariants,
} from './actions';

import { loadOtherText } from '../App/actions';
import { WithRouterProps } from 'react-router';

import {
  productDataSelector,
  productDetailIsFetchingSelector,
  productAccessoriesSelector,
  productAccessoriesIsFetchingSelector,
  productAlternativesIsFetchingSelector,
  productAlternativesSelector,
  productColorsVariantsIsFetchingSelector,
  productColorsVariantsSelector,
} from './selectors';

import {
  cartIsFetchingSelector,
  fetchCart,
  setWasAddedToCartModalVisibility,
} from '../Cart/cartSlice';
import { getGoogleAdsId } from '../../configureTagManager';
import {
  fbViewItem,
  ga4ViewItem,
} from '@bart.sk-ecommerce/react-online-marketing';

export interface ProductProps {
  user: ThenArg<typeof API.tokeninfo>;
  productData: ThenArg<typeof API.loadProduct>;
  dispatch: any;
  isFetching: boolean;
  lang: string;
  currency: string;
  params: {
    product_id: number | string;
    product_url: string;
  };
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  accessoriesProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAccessories: boolean;
  otherText: any;
  cartIsFetching: boolean;
  colorsVariantsProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingColorsVariants: boolean;
  saleDataFromCart: any;
}

class ProductContainer extends React.Component<ProductProps & WithRouterProps> {
  public static async getInitialProps({
    product_id,
    dispatch,
    params: { product_id: productId },
  }) {
    try {
      const correctProductId = product_id || productId;
      await Promise.all[
        (await dispatch(loadProductDetail(correctProductId)),
        await dispatch(loadProductDetailRelated(correctProductId)),
        await dispatch(loadProductDetailAccessory(correctProductId)),
        await dispatch(loadOtherText('SHIPPING_AND_RETURN')),
        await dispatch(loadOtherText('CUSTOMER_SERVICE')),
        await dispatch(loadProductDetailColorsVariants(correctProductId)))
      ];
      return;
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  public componentDidMount() {
    if (this.props.productData) {
      ga4ViewItem(
        this.props.productData,
        getGoogleAdsId(),
        this.props.currency,
      );
      fbViewItem(this.props.productData);
    }
  }

  public componentDidUpdate(prevProps: ProductProps) {
    const { productData } = this.props;
    if (prevProps.productData !== productData) {
      ga4ViewItem(
        this.props.productData,
        getGoogleAdsId(),
        this.props.currency,
      );
      fbViewItem(this.props.productData);
    }
  }

  // public metaHandler = lang => {
  //   const { product } = this.props;
  //   const productPublish = propByKey(product, 'publish', []).find(
  //     pp => pp.lang_id === lang,
  //   );
  //
  //   const metaTags = propByKey(productPublish, 'content.json_content.meta', {
  //     title: this.props.defaultTitle,
  //   });
  //
  //   if (!metaTags.title.length) {
  //     metaTags.title = `${this.props.defaultTitle} - ${product.name}`;
  //   }
  //
  //   return <MetaTags tags={metaTags} />;
  // };

  public addItemToCart = async (productId, goodId, count) => {
    console.log('add');
  };

  public render() {
    const {
      productData,
      isFetching,
      alternativeProducts,
      isFetchingAlternatives,
      accessoriesProducts,
      isFetchingAccessories,
      colorsVariantsProducts,
      isFetchingColorsVariants,
      dispatch,
      user,
      otherText,
      cartIsFetching,
      location,
      saleDataFromCart,
      lang,
    } = this.props;

    const currentCategory = prop(location, 'state.category_id');

    return (
      <Product
        product={productData}
        isFetching={isFetching}
        dispatch={dispatch}
        alternativeProducts={alternativeProducts}
        isFetchingAlternatives={isFetchingAlternatives}
        accessoriesProducts={accessoriesProducts}
        isFetchingAccessories={isFetchingAccessories}
        colorsVariantsProducts={colorsVariantsProducts}
        isFetchingColorsVariants={isFetchingColorsVariants}
        user={user}
        otherText={otherText}
        cartIsFetching={cartIsFetching}
        currentCategory={currentCategory}
        saleDataFromCart={saleDataFromCart}
        lang={lang}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    lang: langSelector(state),
    currency: currencySelector(state),
    defaultTitle: titleSelector(state),
    contactInfo: contactInfoSelector(state),
    productData: productDataSelector(state),
    isFetching: productDetailIsFetchingSelector(state),
    alternativeProducts: productAlternativesSelector(state),
    isFetchingAlternatives: productAlternativesIsFetchingSelector(state),
    accessoriesProducts: productAccessoriesSelector(state),
    isFetchingAccessories: productAccessoriesIsFetchingSelector(state),
    otherText: otherTextSelector(state),
    cartIsFetching: cartIsFetchingSelector(state),
    colorsVariantsProducts: productColorsVariantsSelector(state),
    isFetchingColorsVariants: productColorsVariantsIsFetchingSelector(state),
    saleDataFromCart: state.cart.data,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ProductContainer' })(ProductContainer),
);
