import { AppThunk, RootState } from './../../rootReducer';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import API, { ThenArg } from '../../services/API';
import {
  ErrorTypeAPI,
  startFetching,
  startFetchingMore,
  stopFetching,
  stopFetchingMore,
} from '../../utilities/redux';
import { prop } from '../../utilities';
import { currencySelector, langSelector } from '../App/selectors';
import { resolveSizeChartLangAttribFromProduct } from '../../utilities/product';

interface CategoryState {
  data: any;
  error: ErrorTypeAPI;
  isFetching: boolean;
  isFetchingMore: boolean;
}

const initialState: CategoryState = {
  data: null,
  isFetching: false,
  error: null,
  isFetchingMore: false,
};

const category = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setInitialCategory(state) {
      state.data = null;
      state.error = null;
      stopFetching(state, '');
    },
    startFetchingCategory(state) {
      startFetching(state, '');
    },
    fetchCategorySuccess(
      state,
      action: PayloadAction<{
        category: any;
      }>,
    ) {
      state.data = action.payload.category;
      state.error = null;
      stopFetching(state, '');
    },
    fetchCategoryError(state, action: PayloadAction<ErrorTypeAPI>) {
      // state.data = null;
      state.error = action.payload;
      stopFetching(state, '');
    },
    startFetchingMoreProducts(state) {
      startFetchingMore(state, '');
    },
    fetchMoreProductsSuccess(
      state,
      action: PayloadAction<{ products: any; offset: number }>,
    ) {
      state.data = {
        ...state.data,
        products: {
          ...state.data.products,
          offset: action.payload.offset,
          products: [
            ...state.data.products.products,
            ...action.payload.products.products.products,
          ],
        },
      };
      stopFetchingMore(state, '');
    },
  },
});

const {
  startFetchingCategory,
  fetchCategorySuccess,
  fetchCategoryError,
  startFetchingMoreProducts,
  fetchMoreProductsSuccess,
} = category.actions;
export default category.reducer;

const categoryDomainSelector = (state: RootState) => state.category;

export const categoryIsFetchingSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const categoryInfoSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'data.categoryInfo', null),
);

export const categoryProductsSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'data.products', null),
);

export const categoryFilterDataSelector = createSelector(
  categoryDomainSelector,
  substate => ({
    minPrice: prop(substate, 'data.minPrice', null),
    maxPrice: prop(substate, 'data.maxPrice', null),
    attribs: prop(substate, 'data.attribsAggs', null),
  }),
);

export const categoryChildrenCategoriesSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'data.subTree', []),
);

export const categoryIsFetchingMoreProductsSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'isFetchingMore', false),
);

export const saleDataSelector = createSelector(
  categoryDomainSelector,
  substate => prop(substate, 'data.sales', null),
);

export const fetchCategory = (
  categoryId: number,
  filters?: any,
): AppThunk => async (dispatch, getState, API) => {
  const limitProductsOnPage = filters.otherAttribs.displayProducts;
  try {
    dispatch(startFetchingCategory());
    const langId = langSelector(getState());
    let category: any = await API.loadElasticCategoryProducts(categoryId, {
      langId,
      currency: currencySelector(getState()),
      onlySubcategories: '0',
      limit: +limitProductsOnPage ? +limitProductsOnPage : 18,
      ...filters,
      ...filters.otherAttribs,
      withCombinations: '1',
    });

    // jazykové preklady size chart atributov
    if (category && langId !== 'sk') {
      if (category.products && category.products.products) {
        category.products.products.map(product => {
          return resolveSizeChartLangAttribFromProduct(product);
        });
      }
    }
    dispatch(fetchCategorySuccess({ category }));
  } catch (err) {
    dispatch(fetchCategoryError(err));
  }
};

export const attribsObjectToUrl = (catUrl: string, urlAttribs: any) => {
  const {
    min,
    max,
    attribs,
    rangeAttribs,
    sort,
    sortDir,
    otherAttribs,
    saleFilter,
  } = urlAttribs;
  let paramsString = '';
  const sortString = sort && sortDir ? `sort=${sort}&sortDir=${sortDir}` : '';
  paramsString += sortString;

  let attribsString = '';
  if (attribs && Object.keys(attribs).length > 0) {
    attribsString = paramsString ? '&attribs=' : 'attribs=';
    Object.keys(attribs).map(key => {
      const attrib = attribs[key];
      attrib.values.map(attrib => {
        attribsString += `${key}|${attrib},`;
      });
    });
    attribsString = attribsString.substring(0, attribsString.length - 1);
  }
  paramsString += attribsString;

  let rangeAttribsString = '';
  if (rangeAttribs && Object.keys(rangeAttribs).length > 0) {
    rangeAttribsString = paramsString ? '&range_attribs=' : 'range_attribs=';
    Object.keys(rangeAttribs).map(key => {
      const attrib = rangeAttribs[key];
      rangeAttribsString += `${key}|${attrib.min}-${attrib.max},`;
    });
    rangeAttribsString = rangeAttribsString.substring(
      0,
      rangeAttribsString.length - 1,
    );
  }
  paramsString += rangeAttribsString;

  let minString = '';
  if (min) {
    minString = paramsString ? `&min=${min}` : `min=${min}`;
  }
  paramsString += minString;

  let maxString = '';
  if (max) {
    maxString = paramsString ? `&max=${max}` : `max=${max}`;
  }
  paramsString += maxString;

  let saleString = '';
  if (saleFilter) {
    saleString = paramsString
      ? `&saleFilter=${saleFilter}`
      : `saleFilter=${saleFilter}`;
  }
  paramsString += saleString;

  let otherAttribsString = '';
  if (otherAttribs) {
    Object.keys(otherAttribs).map(key => {
      otherAttribsString +=
        paramsString || otherAttribsString
          ? `&${key}=${otherAttribs[key]}`
          : `${key}=${otherAttribs[key]}`;
    });
  }

  paramsString += otherAttribsString;

  return `${catUrl}?${paramsString}`;
};

export const urlAttribsToObject = (urlAttribs: any) => {
  let attribsObj;
  const urlAttribFilters = prop(urlAttribs, 'attribs');
  if (urlAttribFilters) {
    attribsObj = {};
    const attribs = urlAttribFilters.split(',');
    attribs.map(attrib => {
      const [attribId, valueId] = attrib.split('|');
      const values = prop(attribsObj[attribId], 'values', []);
      values.push(valueId);
      attribsObj[attribId] = { values };
    });
  }

  let rangeAttribsObj;
  const urlRangeAttribFilters = prop(urlAttribs, 'range_attribs');
  if (urlRangeAttribFilters) {
    rangeAttribsObj = {};
    const attribs = urlRangeAttribFilters.split(',');
    attribs.map(attrib => {
      const [attribId, value] = attrib.split('|');
      const [min, max] = value.split('-');
      rangeAttribsObj[attribId] = { min, max };
    });
  }

  const otherAttribsWhitelist = [
    'new',
    'sale',
    'saleout',
    'onlyAvailable',
    'ordered',
    'displayProducts',
    'q',
  ];
  const otherAttribs = {};
  Object.keys(urlAttribs).map(key => {
    if (otherAttribsWhitelist.includes(key)) {
      otherAttribs[key] = urlAttribs[key];
    }
  });

  return {
    ...urlAttribs,
    attribs: attribsObj,
    rangeAttribs: rangeAttribsObj,
    activeRangeAttribs: urlRangeAttribFilters ? urlRangeAttribFilters : '',
    activeAttribs: urlAttribFilters ? urlAttribFilters : '',
    otherAttribs,
  };
};

export const loadMoreCategoryProducts = (
  categoryId,
  filters?: any,
): AppThunk => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(startFetchingMoreProducts());
      const atributes = getState().category.data.products;
      var newOffset = atributes.offset + atributes.limit;
      const products: any = await API.loadElasticCategoryProducts(categoryId, {
        langId: langSelector(getState()),
        onlySubcategories: '0',
        ...filters,
        ...filters.otherAttribs,
        limit: atributes.limit,
        offset: newOffset,
        withCombinations: '1',
      });
      dispatch(
        fetchMoreProductsSuccess({
          products: products,
          offset: newOffset,
        }),
      );
    } catch (err) {
      dispatch(fetchCategoryError(err));
    }
  };
};
