import * as React from 'react';
import { __ } from 'react-i18n/lib';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import { tokenSelector, titleSelector } from './selectors';
import { currencySelector, langSelector } from '../App/selectors';
import {
  loadCategoryProducts,
  loadCategory,
  loadCategorySpecialProducts,
} from './actions';
import { WithRouterProps, withRouter } from 'react-router';
import Category from '../../components/Category/Category';
import { connectSsr } from 'ssr-service';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import {
  fetchCategory,
  categoryIsFetchingSelector,
  categoryInfoSelector,
  categoryProductsSelector,
  categoryFilterDataSelector,
  categoryChildrenCategoriesSelector,
  urlAttribsToObject,
  categoryIsFetchingMoreProductsSelector,
  saleDataSelector,
} from './categorySlice';
import { ga4ViewCategory } from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';

export interface CategoryProps {
  location: any;
  category: any;
  parentProps: any;
  loading: boolean;
  dispatch: any;
  lang: string;
  currency: string;
  defaultTitle: string;
  token: string;
  isLoading: boolean;
  limit: number;
  sort: string;
  sortDir: string;
  isFetching: boolean;
  isFetchingMoreProducts: boolean;
  offset: number;
  // products: ThenArg<typeof API.loadElasticCategoryProducts>;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  childrenCategories: any[];
  category_id: number;
  saleData: any;
}

class CategoryContainer extends React.Component<
  CategoryProps & WithRouterProps
> {
  public static async getInitialProps(parentProps) {
    try {
      const {
        dispatch,
        location,
        category_id,
        params: { category_id: pathCategoryId },
      } = parentProps;
      const catId = category_id || pathCategoryId;
      const {
        query,
        pathname,
        query: { offset },
      } = location;
      if (catId) {
        await Promise.all([
          dispatch(fetchCategory(catId, urlAttribsToObject(query))),
        ]);
      } else {
        const catName = pathname.includes('novinky')
          ? 'Novinky'
          : pathname.includes('akcie')
          ? 'Akcie'
          : 'Výpredaj';
        dispatch(
          setBreadCrumbPath(BreadCrumbType.CUSTOM, {
            name: catName,
            url: pathname,
          }),
        );
        await Promise.all([
          dispatch(loadCategorySpecialProducts(pathname, offset)),
        ]);
      }

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }
  public componentDidMount() {
    if (this.props.products) {
      ga4ViewCategory(
        this.props.products,
        this.props.data,
        getGoogleAdsId(),
        this.props.currency,
      );
    }
  }
  public componentDidUpdate(prevProps: CategoryProps) {
    const { products, data } = this.props;
    if (prevProps.products !== products) {
      ga4ViewCategory(products, data, getGoogleAdsId(), this.props.currency);
    }
  }

  public render() {
    const {
      dispatch,
      isFetching,
      isFetchingMoreProducts,
      products,
      data,
      user,
      location,
      filterData,
      childrenCategories,
      saleData,
      lang,
    } = this.props;
    const { category_id } = this.props;
    const { pathname } = location;
    const catName = category_id
      ? ''
      : pathname.includes('novinky')
      ? 'Novinky'
      : pathname.includes('akcie')
      ? 'Akcie'
      : 'Výpredaj';

    return (
      <Category
        dispatch={dispatch}
        isFetching={isFetching}
        isFetchingMore={isFetchingMoreProducts}
        products={products}
        data={data}
        user={user}
        catName={catName}
        filterData={filterData}
        childrenCategories={childrenCategories}
        saleData={saleData}
        lang={lang}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    currency: currencySelector(state),
    token: tokenSelector(state),
    isFetching: categoryIsFetchingSelector(state),
    products: categoryProductsSelector(state),
    data: categoryInfoSelector(state),
    user: state.auth.user,
    filterData: categoryFilterDataSelector(state),
    childrenCategories: categoryChildrenCategoriesSelector(state),
    isFetchingMoreProducts: categoryIsFetchingMoreProductsSelector(state),
    saleData: saleDataSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CategoryContainer' })(
    withRouter(CategoryContainer),
  ),
);
