import React, { useState } from 'react';
import styled from '../../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  GolfcentrumIconWithText as IconWithText,
} from 'eshop-defaults';
import { useInterval } from '../../../utilities/hooks';
import { prop } from '../../../utilities';

function InfoBanner(props) {
  const secondInfoTexts = prop(props, 'secondInfoTexts', []);
  const homeInfoBanner = prop(props, 'homeInfoBanner', false);

  const [info, setInfo] = useState(0);

  const secondInfoTextsIcons = {
    SORTIMENTS: '/images/golfcentrum/tshirt.svg',
    TRADITION: '/images/golfcentrum/heart.svg',
    SHOP: '/images/golfcentrum/shop.svg',
    SAFETY_SHOPPING: '/images/golfcentrum/safety-check.svg',
  };

  useInterval(() => {
    changeInfoBanners();
  }, 3000);

  const changeInfoBanners = () => {
    const newSecondInfo = info >= secondInfoTexts.length - 1 ? 0 : info + 1;
    setInfo(newSecondInfo);
  };

  const currentInfo = secondInfoTexts[info];
  const secondCurrentInfo =
    secondInfoTexts[info >= secondInfoTexts.length - 1 ? 0 : info + 1];

  if (!currentInfo || !secondCurrentInfo) {
    return null;
  }

  return (
    <>
      <DesktopSecondInfoBanner homeInfoBanner={homeInfoBanner}>
        {secondInfoTexts.map((info: any) => (
          <IconWithText
            url={'/' + info.url}
            key={info.name}
            textMain={info.name}
            iconSrc={prop(secondInfoTextsIcons, info.uniqid, null)}
            alt={`ikonka ku ${info.name}`}
          />
        ))}
      </DesktopSecondInfoBanner>
      <TabletSecondInfoBanner>
        <IconWithText
          url={'/' + currentInfo.url}
          key={currentInfo.name}
          textMain={currentInfo.name}
          iconSrc={prop(
            secondInfoTextsIcons,
            currentInfo && currentInfo.uniqid,
            null,
          )}
          alt={`ikonka ku ${currentInfo.name}`}
        />
        <IconWithText
          url={'/' + currentInfo.url}
          key={secondCurrentInfo.name}
          textMain={secondCurrentInfo.name}
          iconSrc={prop(
            secondInfoTextsIcons,
            secondCurrentInfo && secondCurrentInfo.uniqid,
            null,
          )}
          alt={`ikonka ku ${secondCurrentInfo.name}`}
        />
      </TabletSecondInfoBanner>
      <MobileSecondInfoBanner homeInfoBanner={homeInfoBanner}>
        <IconWithText
          url={'/' + currentInfo.url}
          key={currentInfo.name}
          textMain={currentInfo.name}
          iconSrc={prop(
            secondInfoTextsIcons,
            secondCurrentInfo && secondCurrentInfo.uniqid,
            null,
          )}
          alt={`ikonka ku ${currentInfo.name}`}
        />
      </MobileSecondInfoBanner>
    </>
  );
}
const DesktopFirstInfoBanner = styled(FlexRowCenterVertical)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.gray08};
  background-color: ${({ theme }) => theme.color.gray04};
  justify-content: space-between;

  ${({ theme }) => theme.mediab.l925`
     display: none;
    
  `}
`;

const DesktopSecondInfoBanner = styled(DesktopFirstInfoBanner)<{
  homeInfoBanner?: boolean;
}>`
  margin: auto;
  ${props => (props.homeInfoBanner ? 'max-width: 1456px;' : '')}
`;

const MobileFirstInfoBanner = styled(DesktopFirstInfoBanner)`
  display: none;
  justify-content: center;
  margin: ${rem(33)} 0 ${rem(40)};
  padding: ${rem(12)} ${rem(16)};

  ${({ theme }) => theme.mediab.m580`
     display: flex;
  `}
`;
const TabletSecondInfoBanner = styled(DesktopSecondInfoBanner)`
  display: none;
  justify-content: center;
  margin: ${rem(33)} 0 0;

  ${({ theme }) => theme.mediab.l925`
     display: flex;
     justify-content: space-around;
  `}
  ${({ theme }) => theme.mediab.m580`
     display: none;
  `};
`;

const MobileSecondInfoBanner = styled(MobileFirstInfoBanner)<{
  homeInfoBanner?: boolean;
}>`
  padding: ${rem(12)} ${rem(16)};
  margin: ${props => (props.homeInfoBanner ? '0' : `${rem(40)} auto 0`)};
  width: 100vw;
`;

export default InfoBanner;
