import * as React from 'react';
import styled, { css } from 'styled-components';

import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel';
import { getImagePath } from '../../../utilities/product';
import { Link } from 'react-router';
import { rem } from 'polished';
import {
  NoItemsWrapper,
  GolfcentrumProductTabDefault as ProductTabDefault,
  GolfCentrumProductTabSmall as ProductTabSmall,
  ArticleTabDefault,
} from 'eshop-defaults';
import { prop } from '../../../utilities';

const BtnBack = styled(ButtonBack)`
  background-image: url('/images/blog_assets/back-arrow.svg');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: white;
  z-index: 500;
  right: 90px;
  top: -56px;
  border: none;
  visibility: ${({ type }) =>
    type === 'product-detail' ? 'hidden' : 'visible'};

  &:disabled {
    background-image: url('/images/blog_assets/arrow_back_gray.svg');
    cursor: unset;
  }

  @media only screen and (max-width: 580px) {
    visibility: hidden;
    z-index: -10;
  }

  @media only screen and (max-width: 520px) {
    visibility: hidden;
    z-index: -10;
  }
`;

const BtnNext = styled(ButtonNext)`
  background-image: url('/images/blog_assets/right-arrow.svg');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: white;
  border: none;
  z-index: 500;
  right: 20px;
  top: -56px;
  visibility: ${({ type }) =>
    type === 'product-detail' ? 'hidden' : 'visible'};

  &:disabled {
    background-image: url('/images/blog_assets/right-arrow-gray.svg');
    cursor: unset;
  }

  @media only screen and (max-width: 580px) {
    visibility: hidden;
    z-index: -10;
  }

  @media only screen and (max-width: 520px) {
    visibility: hidden;
    z-index: -10;
  }
`;

const Carousel = styled(CarouselProvider)`
  margin: auto;
  position: relative;
  width: 100%;
  overflow: visible;
`;

const DotComponent = styled(Dot)`
  width: 12px;
  height: 12px;
  border: none;
  border: 2px solid ${({ theme }) => theme.color.primary};
  background-color: ${({ theme }) => theme.color.primary};
  padding: 0;
  margin: 0 4px;
  border-radius: 50%;

  &:disabled {
    background-color: ${({ theme }) => theme.color.white};
  }
`;

const Dots = styled.div<{ type?: string }>`
  width: 100%;
  margin: auto;

  ${(props: any) => {
    const { theme, type } = props;
    switch (type) {
      case 'hp-banner': {
        return css`
          text-align: center;
          position: relative;
          top: auto;
          right: auto;
          display: none;
          background: ${theme.color.white};
          padding-top: ${rem(24)};

          ${theme.mediab.l925`
              display: block;
          `}
        `;
      }
      case 'article-product':
      case 'article-photogallery': {
        return css`
          text-align: center;
          position: relative;
          top: auto;
          right: auto;
          display: block;
          background: ${theme.color.white};
          padding: ${rem(16)} 0 ${rem(32)};
        `;
      }
      case 'add-to-cart-popup': {
        return css`
          position: absolute;
          top: ${rem(-32)};
          right: 0;
          text-align: right;

          ${theme.mediab.m580`
            text-align: center;
            position: relative;
            top: 0;
          `}
        `;
      }
      case 'product-detail': {
        return css`
          padding: ${rem(24)} 0;
          position: absolute;
          top: ${rem(-32)};
          right 0;
        `;
      }
      case 'hp-product':
      case 'hp-article': {
        return css`
          padding: ${rem(24)} 0;
          position: absolute;
          text-align: right;
          top: ${rem(-80)};
          right 0;
        `;
      }
      default: {
        return css``;
      }
    }
  }};

  @media only screen and (max-width: 380px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-220px' : 'initial')};
  }
  @media only screen and (max-width: 340px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-200px' : 'initial')};
  }
`;

const PhotogalleryItem = styled.div<{ marginRight?: number }>`
  margin-right: ${({ marginRight }) =>
    marginRight || marginRight === 0 ? rem(marginRight) : 'initial'};
`;

const PhotogalleryCover = styled.img<{ marginRight?: number }>``;

const EbookItem = styled(Link)`
  text-decoration: none;
  margin: auto;
  width: 210px;
  height: 305px;
  box-shadow: 1px 3px 15px #dddddd;
  margin-right: 32px;
  line-height: 20px;

  @media only screen and (max-width: 900px) {
    width: 180px;
    height: 255px;
  }

  @media only screen and (max-width: 620px) {
    width: 150px;
    height: 210px;
  }
  @media only screen and (max-width: 520px) {
    width: 180px;
    height: 255px;
  }

  @media only screen and (max-width: 420px) {
    width: 150px;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 120px;
    height: 170px;
  }
`;

const Cover = styled.img`
  margin: auto;
  width: 210px;
  height: 305px;
  /* border: 1px solid #dddddd; */

  @media only screen and (max-width: 900px) {
    width: 180px;
    height: 255px;
  }
  @media only screen and (max-width: 620px) {
    width: 150px;
    height: 210px;
  }
  @media only screen and (max-width: 520px) {
    width: 180px;
    height: 255px;
  }
  @media only screen and (max-width: 420px) {
    width: 150px;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 120px;
    height: 170px;
  }
`;

const ProductItem = styled(Link)`
  text-decoration: none;
  /* margin: auto; */
  width: 228px;
  height: 231px;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1130px) {
    margin: auto;
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
    height: 230px;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 230px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 230px;
  }
`;

const ProductThumb = styled.img`
  margin: auto;
  width: 91px;
  height: 120px;
  max-height: 120px;
  border: 1px solid #dddddd;
`;

const ProductName = styled.p`
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 24px;
  width: 85%;
  color: #414141;
  font-weight: 500;
  line-height: 20px;
`;

const SliderCustom = styled(Slider)`
  max-height: auto;

  ${({ theme }) => theme.mediab.l925`
     max-height: ${rem(386)};
  `}
`;

interface State {
  width: number;
  height: number;
  visibleSlides: number;
  type: string;
  slides: any;
}

export interface Props {
  type: string;
  slides: any;
}

class SliderComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.state = {
      width: 210,
      height: 305,
      visibleSlides: 3,
      type: '',
      slides: [],
    };
  }

  public renderItem(key: number, img: string, item: any) {
    if (this.props.type === 'photogallery') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            alt="Obrázok vo fotogalerií"
            src={getImagePath(img, { width: 400, height: 300 })}
          />
        </PhotogalleryItem>
      );
    } else if (this.props.type === 'ebook') {
      return <ProductTabSmall key={item.product_id} product={item} />;
    } else if (this.props.type === 'product-detail') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            src={getImagePath(item, { width: 800, height: 800 })}
          />
        </PhotogalleryItem>
      );
    } else if (this.props.type === 'hp-banner') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            alt={`Banner č. ${key}`}
            src={getImagePath(item.b_path, { width: 1920, height: 480 })}
            /* srcSet={`
              ${getImagePath(item.b_path, { width: 500, height: 600 })} 500w,
              ${getImagePath(item.b_path, { width: 960, height: 328 })} 960w,
              ${getImagePath(item.b_path, { width: 1920, height: 480 })} 1920w
            `}
             sizes={'100vw'}*/
            // sizes={'(min-width: 750px) 1456px, 100vw'}
          />
        </PhotogalleryItem>
      );
    } else if (this.props.type === 'hp-product') {
      return (
        <ProductTabDefault
          key={item.product_id}
          addToCart={''}
          addToRequest={''}
          product={item}
        />
      );
    } else if (this.props.type === 'hp-article') {
      return (
        <ArticleTabDefault
          key={key}
          article={item}
          anotationAsTitle={false}
          showCategory={true}
        />
      );
    } else if (this.props.type === 'article-product') {
      return (
        <ProductTabDefault
          key={item.product_id}
          addToCart={''}
          addToRequest={''}
          product={item}
          inArticle={true}
        />
      );
    } else if (this.props.type === 'article-photogallery') {
      return (
        <PhotogalleryItem key={key} marginRight={8}>
          <PhotogalleryCover
            alt="Obrázok vo fotogalerií"
            src={getImagePath(
              item.document_path,
              { width: 256, height: 256 },
              false,
              false,
              true,
            )}
          />
        </PhotogalleryItem>
      );
    } else if (this.props.type === 'add-to-cart-popup') {
      return <ProductTabSmall key={item.product_id} product={item} />;
    }
    return null;
  }

  public renderDots(items: any[], slide: number) {
    const table: any[] = [];
    for (let i = 0; i < items.length; i += slide) {
      table.push(<DotComponent slide={i} key={i} />);
    }
    return table;
  }

  public updateState() {
    const win = window.innerWidth;
    if (
      this.props.type === 'photogallery' ||
      this.props.type === 'product-detail'
    ) {
      this.setState({
        width: 480,
        height: 480,
        visibleSlides: 1,
      });
    } else if (this.props.type === 'product') {
      if (win > 1130) {
        this.setState({
          width: 210,
          height: 255,
          visibleSlides: 4,
        });
      } else if (win <= 1130 && win >= 780) {
        this.setState({
          width: 210,
          height: 205,
          visibleSlides: 3,
        });
      } else if (win < 780 && win >= 680) {
        this.setState({
          width: 200,
          height: 165,
          visibleSlides: 2,
        });
      } else if (win < 680 && win >= 580) {
        this.setState({
          width: 200,
          height: 195,
          visibleSlides: 2,
        });
      } else if (win < 580 && win >= 550) {
        this.setState({
          width: 400,
          height: 205,
          visibleSlides: 1,
        });
      } else if (win < 550 && win >= 480) {
        this.setState({
          width: 400,
          height: 215,
          visibleSlides: 1,
        });
      } else if (win < 480 && win >= 440) {
        this.setState({
          width: 400,
          height: 235,
          visibleSlides: 1,
        });
      } else if (win < 440 && win >= 420) {
        this.setState({
          width: 400,
          height: 250,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 350,
          height: 240,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 300,
          height: 260,
          visibleSlides: 1,
        });
      } else {
        this.setState({
          width: 300,
          height: 280,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'ebook') {
      if (win > 750) {
        this.setState({
          width: 600,
          height: 192,
          visibleSlides: 2,
        });
      } else {
        this.setState({
          width: 300,
          height: 172,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'hp-banner') {
      if (win > 750) {
        this.setState({
          height: 360,
          width: 1456,
          visibleSlides: 1,
        });
      } else {
        this.setState({
          height: 386,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'hp-product') {
      if (win > 760) {
        this.setState({
          height: 328,
          visibleSlides: 3,
        });
      } else {
        this.setState({
          height: 328,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'hp-article') {
      if (win > 760) {
        this.setState({
          height: 328,
          visibleSlides: 4,
        });
      } else {
        this.setState({
          height: 328,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'article-product') {
      if (win > 720 || true) {
        this.setState({
          height: 246,
          visibleSlides: 2,
        });
      }
    } else if (this.props.type === 'article-photogallery') {
      if (win > 720 || true) {
        this.setState({
          height: 200,
          visibleSlides: 3,
        });
      }
    } else if (this.props.type === 'add-to-cart-popup') {
      if (win > 800) {
        this.setState({
          height: 80,
          visibleSlides: 2,
        });
      } else if (win < 800 && win > 450) {
        this.setState({
          height: 100,
          visibleSlides: 2,
        });
      } else if (win < 450) {
        this.setState({
          height: 100,
          visibleSlides: 1,
        });
      }
    }
  }

  public componentWillMount() {
    this.updateState();
  }
  public componentDidMount() {
    this.setState({
      type: this.props.type,
      slides: this.props.slides,
    });
    window.addEventListener('resize', this.updateState);
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateState);
  }

  public render() {
    const { slides, type } = this.props;
    const { width, height, visibleSlides } = this.state;
    return (
      <>
        {slides && slides.length > 0 ? (
          <Carousel
            naturalSlideHeight={height}
            naturalSlideWidth={width}
            visibleSlides={visibleSlides}
            step={1}
            totalSlides={slides.length}
          >
            {false && <BtnBack type={type} />}
            <SliderCustom type={type}>
              {slides.map((item, index) => {
                return (
                  <Slide
                    innerClassName="sliderCentered"
                    index={index}
                    key={index}
                  >
                    {this.renderItem(index, item.img ? item.img : '', item)}
                  </Slide>
                );
              })}
            </SliderCustom>
            {false && <BtnNext type={type} />}
            {true && (
              <Dots type={type}>{this.renderDots(slides, visibleSlides)}</Dots>
            )}
          </Carousel>
        ) : (
          <NoItemsWrapper text={'Nenašli sa žiadne produkty'} />
        )}
      </>
    );
  }
}

export default SliderComponent;
