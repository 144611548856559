import * as React from 'react';
import { Helmet } from 'react-helmet';
import { checkMetaTag } from '../../../utilities';
import { connect } from 'react-redux';
import { prop } from 'eshop-defaults/lib';
import { isEmptyObj } from '../../../utilities/metatags';

interface Props {
  tags: any;
  metaTags: null;
  defaultTitle: string;
}

class MetaTags extends React.PureComponent<Props, {}> {
  public render() {
    const { tags, metaTags, defaultTitle } = this.props;
    if (!tags) {
      return null;
    }

    function renderDescr() {
      if (!isEmptyObj(tags.description) && tags.description) {
        return (
          <meta name="description" content={checkMetaTag(tags.description)} />
        );
      }
      if (metaTags && prop(metaTags, 'description')) {
        return (
          <meta
            name="description"
            content={checkMetaTag(prop(metaTags, 'description'))}
          />
        );
      }
      return null;
    }

    function renderKeyWords() {
      if (!isEmptyObj(tags.keywords) && tags.keywords) {
        return <meta name="keywords" content={checkMetaTag(tags.keywords)} />;
      }
      if (metaTags && prop(metaTags, 'keywords')) {
        return (
          <meta
            name="keywords"
            content={checkMetaTag(prop(metaTags, 'keywords'))}
          />
        );
      }
      return null;
    }

    function renderTitle() {
      let title = `${defaultTitle}`;
      if (tags.title) {
        title = `${tags.title} | ${defaultTitle}`;
      }
      return <title>{title}</title>;
    }

    return (
      <React.Fragment>
        {tags !== {} && (
          <Helmet>
            {renderTitle()}

            {renderDescr()}

            {renderKeyWords()}

            {!isEmptyObj(tags.og_title) && tags.og_title && (
              <meta property="og:title" content={checkMetaTag(tags.og_title)} />
            )}
            {!isEmptyObj(tags.og_type) && tags.og_type && (
              <meta property="og:type" content={checkMetaTag(tags.og_type)} />
            )}
            {!isEmptyObj(tags.og_description) && tags.og_description && (
              <meta
                property="og:description"
                content={checkMetaTag(tags.og_description)}
              />
            )}
            {!isEmptyObj(tags.og_url) && tags.og_url && (
              <meta property="og:url" content={checkMetaTag(tags.og_url)} />
            )}
            {tags.og_image && (
              <meta property="og:image" content={checkMetaTag(tags.og_image)} />
            )}
            {tags.og_image && tags.og_image_width && (
              <meta property="og:image:width" content={tags.og_image_width} />
            )}
            {tags.og_image && tags.og_image_height && (
              <meta property="og:image:height" content={tags.og_image_height} />
            )}
            {!isEmptyObj(tags.og_article_author) && tags.og_article_author && (
              <meta
                name="article:author"
                content={checkMetaTag(tags.og_article_author)}
              />
            )}
            {!isEmptyObj(tags.og_article_section) &&
              tags.og_article_section && (
                <meta
                  name="article:section"
                  content={checkMetaTag(tags.og_article_section)}
                />
              )}
            {!isEmptyObj(tags.og_article_tag) && tags.og_article_tag && (
              <meta
                name="article:tag"
                content={checkMetaTag(tags.og_article_tag)}
              />
            )}
          </Helmet>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    metaTags: state.general.metaTags,
    defaultTitle: state.general.title,
  };
};
export default connect(mapStateToProps)(MetaTags);
