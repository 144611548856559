import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  MetaTags,
  GolfcentrumTitle as Title,
  ContactPersonBlock,
  GolfcentrumBlockWithTitle as BlockWithTitle,
  ContactInfoBlock,
  InvoiceAddressBlock,
  CompanyInfo,
  ZemplinButton as Button,
  InfoText,
  ClubUserBlock,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { useInput } from '../../utilities/hooks';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';

interface Props {
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  userId: number;
  lang: string;
}

function reducer(state, { field, value }) {
  return { ...state, [field]: value };
}

function MyInfo({ customerInfo, userId, lang }: Props) {
  const companyRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  const {
    meno,
    priezvisko,
    email,
    ico: currentIco,
    dic: currentDic,
    icdph: currentIcDph,
    firma,
    country: currentCountry,
    cislo,
    mesto,
    psc,
    telefon,
    ulica,
    individualna_zlava,
    card_number,
  } = customerInfo;
  const isNameSet = meno ? meno !== '' : false;
  const isSurnameSet = priezvisko ? priezvisko !== '' : false;
  const initialState = {
    name: meno || '',
    surname: priezvisko || '',
    ico: currentIco || '',
    dic: currentDic || '',
    icdph: currentIcDph || '',
    company: firma || '',
    country: currentCountry || 'sk',
    streetNumber: cislo || '',
    city: mesto || '',
    zip: psc || '',
    phone: telefon || '',
    street: ulica || '',
    isCompany: firma || currentIco || currentDic,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onChange = e => {
    const {
      target: { name, value },
    } = e;
    dispatch({ field: name, value: value });
    if (name === 'isCompany' && value) {
      if (companyRef && companyRef.current) {
        window.scrollTo({
          behavior: 'smooth',
          top: companyRef.current.offsetTop,
        });
      }
    }
  };

  const {
    ico,
    dic,
    icdph,
    company,
    country,
    streetNumber,
    street,
    city,
    zip,
    phone,
    isCompany,
    name,
    surname,
  } = state;

  const updateDetails = async () => {
    setIsSaving(true);
    try {
      await API.updateCustomerDetails(
        userId,
        {},
        {
          ...customerInfo,
          meno: name,
          priezvisko: surname,
          firma: company,
          ico,
          dic,
          icdph,
          ulica: street,
          cislo: streetNumber,
          telefon: phone,
          psc: zip,
          mesto: city,
          country,
        },
      );
    } catch (e) {
      setError(e);
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    setDataUpdated(true);
  };

  return (
    <Wrapper>
      <Title marginTop={0}>{__('Moje údaje')}</Title>
      <DimWrapper dimmed={isSaving}>
        {card_number || individualna_zlava ? (
          <BlockWithTitle title={__('Vernostná karta')}>
            <ClubUserBlock
              cardnumber={card_number}
              zlava={individualna_zlava}
            />
          </BlockWithTitle>
        ) : null}

        <BlockWithTitle title={__('Kontaktná osoba')}>
          <ContactPersonBlock
            onChange={onChange}
            isNameSet={isNameSet}
            isSurnameSet={isSurnameSet}
            name={name}
            surname={surname}
            isCompany={isCompany}
          />
        </BlockWithTitle>
        <BlockWithTitle title={__('Kontaktné údaje')}>
          <ContactInfoBlock email={email} phone={phone} onChange={onChange} />
        </BlockWithTitle>
        <BlockWithTitle title={__('Fakturačná adresa')}>
          <InvoiceAddressBlock
            onChange={onChange}
            street={street}
            streetNumber={streetNumber}
            city={city}
            zip={zip}
            country={country}
            lang={lang}
          />
        </BlockWithTitle>
        <CompanyWrapper visible={isCompany} ref={companyRef}>
          <BlockWithTitle title={__('Údaje o firme')}>
            <CompanyInfo
              onChange={onChange}
              name={company}
              ico={ico}
              dic={dic}
              icdph={icdph}
            />
          </BlockWithTitle>
        </CompanyWrapper>
      </DimWrapper>
      {!error && dataUpdated && (
        <InfoText
          type={InfoType.SUCCESS}
          info={__('Údaje boli úspešne zmenené')}
        />
      )}
      {error && (
        <InfoText
          type={InfoType.ERROR}
          info={__('Pri ukladaní sa vyskytla chyba')}
        />
      )}
      <SaveButton onClick={updateDetails}>
        {isSaving ? __('Ukladám údaje ...') : __('Uložiť')}
      </SaveButton>
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const CompanyWrapper = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: ${({ visible }) => (visible ? 'auto' : 0)};
`;

const DimWrapper = styled(FlexCol)<{ dimmed: boolean }>`
  opacity: ${({ dimmed }) => (dimmed ? 0.4 : 1)};
`;

const SaveButton = styled(Button)`
  height: ${rem(48)};
  padding: ${rem(15)};
  width: 100%;
  max-width: ${rem(100)};
  background-color: ${({ theme }) => theme.color.green};
  color: ${({ theme }) => theme.color.white};
  font-size: ${rem(14)};
  margin: 0 0 ${rem(24)};
  font-family: ${({ theme }) => theme.font.primary};

  &:disabled {
    background-color: #dcd6d6;
  }
`;

export default MyInfo;
