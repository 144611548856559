import styled from 'styled-components';
import { rem } from 'polished';
import { FlexColCenter } from 'eshop-defaults';

export const Loader = styled.div<{
  className?: string;
  small?: boolean;
  margin?: string;
}>`
  width: ${({ small }) => (small ? rem(20) : rem(50))};
  height: ${({ small }) => (small ? rem(20) : rem(50))};
  border: ${({ small }) =>
    small ? `${rem(4)} solid #f9f9f9` : `${rem(10)} solid #f9f9f9`};
  border-top: ${({ small, theme }) =>
    small
      ? `${rem(4)} solid ${theme.blogColors.primary}`
      : `${rem(10)} solid ${theme.blogColors.primary}`};
  margin: ${({ margin }) => (margin ? margin : `${rem(16)} auto`)};
  border-radius: 50%;
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const AbsoluteLoader = styled(Loader)`
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
`;

export const HorizontalCenterLoader = styled(Loader)`
  margin: ${rem(48)} auto;
  width: ${rem(100)};
  height: ${rem(100)};
`;

export const LoaderWrapper = styled(FlexColCenter)`
  flex: 1 1 auto;
`;

export const MiniLoader = styled(Loader)`
  width: ${rem(30)};
  height: ${rem(30)};
  border-width: ${rem(4)};
`;

export const CenterLoader = styled(Loader)`
  margin: auto;
`;
