import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { FlexRow, BreadCrumb } from 'eshop-defaults';
import { __ } from 'react-i18n';
import { breadCrumbSelector, BreadCrumbPath } from './breadCrumbSlice';

interface Props {
  breadCrumb: BreadCrumbPath;
}

function BreadCrumbContainer({ breadCrumb }: Props) {
  const showBreadCrumbs = breadCrumb && breadCrumb.length > 0;
  return (
    <>
      {showBreadCrumbs && (
        <Wrapper>
          <BreadCrumb breadCrumb={breadCrumb} />
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled(FlexRow)`
  margin: 0 auto;
  width: 100%;
  border-bottom: ${({ theme }) => theme.borders.primary};

  ${({ theme }) => theme.mediab.m580`
  `}
`;

const mapStateToProps = state => {
  return {
    breadCrumb: breadCrumbSelector(state),
  };
};

export default connect(mapStateToProps)(BreadCrumbContainer);
