import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import {
  loadHomeNewProducts,
  loadHomeSaleProducts,
  loadHomeBanner,
  loadHomeTopArticle,
  loadProductList,
  loadNewArticles,
} from './actions';
import { langSelector, titleSelector } from '../Category/selectors';
import { loadOtherText } from '../App/actions';
import {
  otherTextSelector,
  sitemapSubtreeDataSelector,
} from '../App/selectors';
import {
  saleProductsSelector,
  newProductsSelector,
  homepageBannersSelector,
  topArticleSelector,
  newArticlesSelector,
  landingPageProductsSelector,
} from './selectors';
import Home from '../../components/Home/Home';
import {
  productCategoriesSelector,
  productCategoriesIsFetchingSelector,
  homeDimmerSelector,
  firstCategoryIdSelector,
} from '../Header/selectors';
import { loadCategoriesTree } from '../Header/actions';
import { addItemToCart } from '../Cart/cartSlice';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  parentProps: {};
  dispatch: any;
  isError: boolean;
  loaded: any;
  defaultTitle: string;
  lang: string;
  modal: any;
  adBanner: any;
  router: any;
  location: any;
  newProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  saleProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  banners?: ThenArg<typeof API.loadBanners>;
  productCategories: ThenArg<typeof API.loadTree>;
  productCategoriesIsFetching: boolean;
  dimmerVisible: boolean;
  firstCategoryId: number;
  topArticles: {
    articles: ThenArg<typeof API.loadArticles>;
    total: number;
  };
  newArticles: {
    articles: ThenArg<typeof API.loadArticles>;
    total: number;
  };
  landingPageProducts: {
    productsData: ThenArg<typeof API.loadProductList>;
    isFetching: false;
  };
  otherText: any;
  sitemapSubtreeData: any;
}

class HomeContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      const firstCategoryId = await dispatch(loadCategoriesTree());
      await Promise.all[
        (await dispatch(loadHomeNewProducts(firstCategoryId)),
        await dispatch(loadHomeSaleProducts(firstCategoryId)),
        await dispatch(loadOtherText('NEWSLETTER_BLOCK')),
        await dispatch(loadProductList('LANDING_PAGE')),
        await dispatch(loadHomeTopArticle()),
        await dispatch(loadHomeBanner()),
        await dispatch(loadNewArticles()))
      ];

      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      productCategories,
      productCategoriesIsFetching,
      saleProducts,
      newProducts,
      dimmerVisible,
      dispatch,
      firstCategoryId,
      banners,
      topArticles,
      newArticles,
      landingPageProducts,
      otherText,
      sitemapSubtreeData,
    } = this.props;

    return (
      <Home
        categories={productCategories}
        addToCart={this.addToCart}
        addToRequest={this.addToRequest}
        productCategoriesIsFetching={productCategoriesIsFetching}
        newProducts={newProducts}
        saleProducts={saleProducts}
        dimmerVisible={dimmerVisible}
        dispatch={dispatch}
        firstCategoryId={firstCategoryId}
        banners={banners}
        topArticles={topArticles}
        landingPageProducts={landingPageProducts}
        otherText={otherText}
        sitemapSubtreeData={sitemapSubtreeData}
        newArticles={newArticles}
      />
    );
  }

  private addToCart = (product: any, count: number = 1) => {
    this.props.dispatch(addItemToCart(product, count));
  };

  private addToRequest = () => {
    console.log('add to request');
  };
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    newProducts: newProductsSelector(state),
    banners: homepageBannersSelector(state),
    saleProducts: saleProductsSelector(state),
    productCategories: productCategoriesSelector(state),
    productCategoriesIsFetching: productCategoriesIsFetchingSelector(state),
    dimmerVisible: homeDimmerSelector(state),
    firstCategoryId: firstCategoryIdSelector(state),
    topArticles: topArticleSelector(state),
    newArticles: newArticlesSelector(state),
    landingPageProducts: landingPageProductsSelector(state),
    otherText: otherTextSelector(state),
    sitemapSubtreeData: sitemapSubtreeDataSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'HomeContainer' })(HomeContainer),
);
