import * as React from 'react';
import { __ } from 'react-i18n';
import { Link, withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import Button from '../_helpers/forms/Button/Button';
import ReactDOM from 'react-dom';
import {
  FlexRowCenterVertical,
  HeaderLogo as HeaderSmallLogo,
  TextP,
  FlexRow,
  FlexColCenter,
  FlexCol,
  IconImg,
  GolfcentrumSearchForm as SearchForm,
  OutsideClick,
  MobileNavMenu,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { Navigation } from './Navigation';
import {
  DesktopWrapper,
  MobileWrapper,
} from '../_helpers/Default/ResponsiveWrappers';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { MobileNavigation } from './MobileNavigation';
import {
  IconType,
  SvgIcon,
} from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import { HeaderSwitch } from './HeaderSwtich';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  handleSearchFocus: any;
  brands: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  cartData: ThenArg<typeof API.getCart>;
}

/*****************/

const StyledMobileWrapper = styled(MobileWrapper)`
  width: 100%;
  margin: 0 auto;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
`;

const HeaderWrapper = styled(OutsideClick)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  align-items: center;

  @media print {
    display: none;
  }
`;

const MobileMenuWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  padding: ${rem(16)};
  height: ${rem(56)};
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const RightWrapper = styled(FlexRowCenterVertical)``;

const LeftWrapper = styled(RightWrapper)``;

function MobileHeader({
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  items,
  partialSearchProducts,
  partialSearchCategories,
  partialSearchProductsIsFetching,
  partialSearchCategoriesIsFetching,
  cartData,
  dispatch,
  brands,
}: Props) {
  const { items: cartItems } = cartData || {};
  const [isSearchVisible, setIsSearchVisible] = React.useState(false);
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);

  const logoSrc = '/images/golfcentrum/logo.svg';
  const searchSrc = '/images/golfcentrum/search.svg';
  const listSrc = '/images/golfcentrum/listMenu.svg';

  const toggleMenu = () => {
    document.body.style.overflow = isMenuVisible ? 'visible' : 'hidden';
    setIsMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    document.body.style.overflow = 'visible';
    setIsMenuVisible(false);
  };

  const onChange = () => {};

  return (
    <StyledMobileWrapper>
      <HeaderWrapper handleFunction={hideSearchResultsViewer}>
        <MobileMenuWrapper>
          <LeftWrapper>
            <SvgIcon
              icon={IconType.listMenu}
              alt="Otvoriť menu"
              cursor={'pointer'}
              marginRight={24}
              width={24}
              height={24}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={toggleMenu}
            />
            <HeaderSmallLogo logo={logoSrc} />
          </LeftWrapper>
          <RightWrapper>
            {/* <HeaderSwitch onChange={onChange}/> */}
            <SvgIcon
              icon={IconType.search}
              width={18}
              height={18}
              alt="Otvoriť vyhľadávanie"
              cursor={'pointer'}
              marginRight={16}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => setIsSearchVisible(!isSearchVisible)}
            />
            <HeaderProfile />
            <HeaderCart
              totalPrice={900.99}
              currency={'EUR'}
              cartData={cartData}
              dispatch={dispatch}
            />
          </RightWrapper>
        </MobileMenuWrapper>
        {isSearchVisible && (
          <SearchWrapper>
            <SearchForm
              searchResults={{
                productsResult: partialSearchProducts,
                categoriesResult: partialSearchCategories,
              }}
              partialSearchProductsIsFetching={partialSearchProductsIsFetching}
              partialSearchCategoriesIsFetching={
                partialSearchCategoriesIsFetching
              }
              redirectToSearchResultPage={redirectToSearchResultsPage}
              searchTerm={searchTerm}
              handleSearchTermChange={handleSearchTermChange}
              handleFocus={handleSearchFocus}
              hideResultViewer={hideSearchResultsViewer}
              resultsDropdownVisible={resultsDropdownVisible}
              isMobile={true}
            />
          </SearchWrapper>
        )}
        {isMenuVisible && (
          <>
            <MobileNavMenu closeMenu={closeMenu}>
              <MobileNavigation closeMenu={closeMenu} items={items} />
            </MobileNavMenu>
            <Dimmer height={100} />
          </>
        )}
      </HeaderWrapper>
    </StyledMobileWrapper>
  );
}

export default MobileHeader;
