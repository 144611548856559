import { CartDeliveryPart, MetaTags } from 'eshop-defaults';
import * as React from 'react';
import { __ } from 'react-i18n';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import API, { ThenArg } from '../../services/API';
import {
  cartDelPayInfoByIdSelector,
  cartDelPayInfoIdsSelector,
  cartDelPayInfoIsFetchingSelector,
  fetchDeliveryPaymentInfo,
  freeDeliveryRemainingAmountSelector,
  updateCart,
} from './cartSlice';
import {
  ga4AddShippingInfo,
  ga4AddPaymentInfo,
} from '@bart.sk-ecommerce/react-online-marketing';
import { getGoogleAdsId } from '../../configureTagManager';
import { prop } from '../../utilities';
import { fetchCustomerDetails } from '../MyAccount/myAccountSlice';
interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  delAndPayInfoIds: string[];
  delAndPayInfoById: Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>>;
  isFetchingDelAndPayInfo: boolean;
  freeDelivery: number;
}

class CartThirdStep extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      await dispatch(fetchDeliveryPaymentInfo());
      await dispatch(fetchCustomerDetails());
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public async componentDidUpdate(prevProps) {
    const { data, dispatch } = this.props;

    const paymentId = prop(data, 'payment.id', null);
    const prevPropsPaymentId = prop(prevProps, 'data.payment.id', null);

    if (paymentId !== prevPropsPaymentId) {
      await dispatch(fetchDeliveryPaymentInfo(false));
    }
  }
  public onChooseZasielkovna = a => {
    if (a) {
      const cart = this.props.data;
      const lang = prop(cart, 'lang', 'sk');
      const deliveryId = lang === 'cz' ? 'ZASIELKOVNA_CZ' : 'ZASIELKOVNA';
      this.props.dispatch(
        updateCart({
          ...this.props.data,
          delivery: { id: deliveryId },
          pickup_id: `${a.id}`,
          pickup_payload: a,
        }),
      );
    }
  };

  public openZasielkovna = () => {
    const cart = this.props.data;
    const country = prop(cart, 'lang', 'sk');
    const lang = country === 'cz' ? 'cs' : 'sk';
    window.Packeta.Widget.pick(
      process.env.REACT_ZASIELKOVNA_API_KEY,
      this.onChooseZasielkovna,
      { country: country, language: lang },
    );
  };

  public render() {
    const {
      data,
      isFetching,
      delAndPayInfoIds,
      delAndPayInfoById,
      isFetchingDelAndPayInfo,
      freeDelivery,
    } = this.props;

    return (
      <>
        <MetaTags
          tags={{ title: __('Košík - Doprava a platba | GOLF CENTRUM.sk') }}
        />
        <CartDeliveryPart
          data={data}
          isFetching={isFetching}
          delAndPayInfoIds={delAndPayInfoIds}
          delAndPayInfoById={delAndPayInfoById}
          isFetchingDelAndPayInfo={isFetchingDelAndPayInfo}
          updateCart={this.updateCart}
          freeDelivery={freeDelivery}
          openZasielkovnaWidget={this.openZasielkovna}
        />
      </>
    );
  }

  private updateCart = data => {
    this.props.dispatch(updateCart(data, true));
    const currency = prop(data, 'currency', 'EUR');
    if (data.delivery && !data.payment) {
      if (window.gtag) {
        if (data) {
          /// @ts-ignore
          let gData = data.items.filter(d => !d.isFromSale);
          ga4AddShippingInfo(
            gData,
            data.delivery.id,
            getGoogleAdsId(),
            currency,
          );
        }
      }
    }
    if (data.payment) {
      if (window.gtag) {
        if (data) {
          /// @ts-ignore
          let gData = data.items.filter(d => !d.isFromSale);
          ga4AddPaymentInfo(gData, data.payment.id, getGoogleAdsId(), currency);
        }
      }
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    delAndPayInfoIds: cartDelPayInfoIdsSelector(state),
    delAndPayInfoById: cartDelPayInfoByIdSelector(state),
    isFetchingDelAndPayInfo: cartDelPayInfoIsFetchingSelector(state),
    freeDelivery: freeDeliveryRemainingAmountSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartThirdStep' })(CartThirdStep),
);
